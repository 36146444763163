export const countryData = [
  {
    "Entity": "Afghanistan",
    "Capital": "Kabul",
    "Num": 13,
    "CC": "AF",
    "alpha3": "AFG",
    "ccTLD": ".AF",
    "IAC": "+93",
    "IDD": "00",
    "TimeZone": "UTC+04:30",
    "Currency": {
      "Currencies": "Afghan afghani (AFN, ؋)"
    },
    "Language(s)": "Pashto, Dari, and some other languages in areas where spoken by majority of population"
  },
  {
    "Entity": "Albania",
    "Capital": "Tirana",
    "Num": 12,
    "CC": "AL",
    "alpha3": "ALB",
    "ccTLD": ".AL",
    "IAC": "+355",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Albanian lek (ALL, L)"
    },
    "Language(s)": "Albanian (based on Tosk dialect)"
  },
  {
    "Entity": "Algeria",
    "Capital": "Algiers",
    "Num": 11,
    "CC": "DZ",
    "alpha3": "DZA",
    "ccTLD": ".DZ",
    "IAC": "+213",
    "IDD": "00",
    "TimeZone": "UTC",
    "Currency": {
      "Currencies": "Algerian dinar (DZD, د.ج)"
    },
    "Language(s)": "Arabic (official and national), Tamazight (national)"
  },

  {
    "Entity": "Andorra",
    "Capital": "Andorra la Vella",
    "Num": 12,
    "CC": "AD",
    "alpha3": "AND",
    "ccTLD": ".AD",
    "IAC": "+376",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "Catalan"
  },
  {
    "Entity": "Antigua and Barbuda",
    "Capital": "St. John's",
    "Num": 12,
    "CC": "AG",
    "alpha3": "ATG",
    "ccTLD": ".AG",
    "IAC": "+1 268",
    "IDD": "011",
    "TimeZone": "UTC-04:00",
    "Currency": {
      "Currencies": "East Caribbean dollar (XCD, $)"
    },
    "Language(s)": "English (de facto official)"
  },
  {
    "Entity": "Argentina",
    "Capital": "Buenos Aires",
    "Num": 10,
    "CC": "AR",
    "alpha3": "ARG",
    "ccTLD": ".AR",
    "IAC": "+54",
    "IDD": "00",
    "TimeZone": "UTC-03:00",
    "Currency": {
      "Currencies": "Argentine peso (ARS, $)"
    },
    "Language(s)": "Spanish (de facto), Guaraní (co-official in Corrientes Province)"
  },
  {
    "Entity": "Armenia",
    "Capital": "Yerevan",
    "Num": 8,
    "CC": "AM",
    "alpha3": "ARM",
    "ccTLD": ".AM",
    "IAC": "+374",
    "IDD": "00",
    "TimeZone": "UTC+04:00",
    "Currency": {
      "Currencies": "Armenian dram (AMD, դր.)"
    },
    "Language(s)": "Armenian, Russian (Minority language)"
  },
  {
    "Entity": "Aruba",
    "Capital": "Oranjestad",
    "Num": 12,
    "CC": "AW",
    "alpha3": "ABW",
    "ccTLD": ".AW",
    "IAC": "+297",
    "IDD": "00",
    "TimeZone": "UTC-04:00",
    "Currency": {
      "Currencies": "Aruban florin (AWG, ƒ)"
    },
    "Language(s)": "Dutch, Papiamento"
  },
  {
    "Entity": "Australia",
    "Capital": "Canberra",
    "Num": 10,
    "CC": "AU",
    "alpha3": "AUS",
    "ccTLD": ".AU",
    "IAC": "+61",
    "IDD": "0011",
    "TimeZone": "UTC+07:00 - UTC+10:00",
    "Currency": {
      "Currencies": "Australian dollar (AUD, $)"
    },
    "Language(s)": "English (de facto)"
  },
  {
    "Entity": "Austria",
    "Capital": "Vienna",
    "Num": 13,
    "CC": "AT",
    "alpha3": "AUT",
    "ccTLD": ".AT",
    "IAC": "+43",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "German (official statewide), and other languages like Groation, Slovene, Czech for some areas."
  },
  {
    "Entity": "Azerbaijan",
    "Capital": "Baku",
    "Num": 10,
    "CC": "AZ",
    "alpha3": "AZE",
    "ccTLD": ".AZ",
    "IAC": "+994",
    "IDD": "00",
    "TimeZone": "UTC+04:00",
    "Currency": {
      "Currencies": "Azerbaijani manat (AZN, )"
    },
    "Language(s)": "Azerbaijani, Armenian (Nagorno-Karabagh)"
  },
  {
    "Entity": "Bahamas",
    "Capital": "Nassau",
    "Num": 11,
    "CC": "BS",
    "alpha3": "BHS",
    "ccTLD": ".BS",
    "IAC": "+1 242",
    "IDD": "011",
    "TimeZone": "UTC-05:00",
    "Currency": {
      "Currencies": "Bahamian dollar (BSD, $)"
    },
    "Language(s)": "English"
  },
  {
    "Entity": "Bahrain",
    "Capital": "Manama",
    "Num": 12,
    "CC": "BH",
    "alpha3": "BHR",
    "ccTLD": ".BH",
    "IAC": "+973",
    "IDD": "00",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Bahraini dinar (BHD, .د.ب)"
    },
    "Language(s)": "Arabic"
  },
  {
    "Entity": "Bangladesh",
    "Capital": "Dhaka",
    "Num": 12,
    "CC": "BD",
    "alpha3": "BGD",
    "ccTLD": ".BD",
    "IAC": "+880",
    "IDD": "00",
    "TimeZone": "UTC+06:00",
    "Currency": {
      "Currencies": "Bangladeshi taka (BDT, ৳)"
    },
    "Language(s)": "Bengali"
  },
  {
    "Entity": "Barbados",
    "Capital": "Bridgetown",
    "Num": 52,
    "CC": "BB",
    "alpha3": "BRB",
    "ccTLD": ".BB",
    "IAC": "+1 246",
    "IDD": "011",
    "TimeZone": "UTC-04:00",
    "Currency": {
      "Currencies": "Barbadian dollar (BBD, $)"
    },
    "Language(s)": "English"
  },
  {
    "Entity": "Belarus",
    "Capital": "Minsk",
    "Num": 12,
    "CC": "BY",
    "alpha3": "BLR",
    "ccTLD": ".BY",
    "IAC": "+375",
    "IDD": "8~10",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Belarusian ruble (BYR, Br)"
    },
    "Language(s)": "Belarusian, Russian"
  },
  {
    "Entity": "Belgium",
    "Capital": "Brussels",
    "Num": 9,
    "CC": "BE",
    "alpha3": "BEL",
    "ccTLD": ".BE",
    "IAC": "+32",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "Dutch, French, German"
  },
  {
    "Entity": "Belize",
    "Capital": "Belmopan",
    "Num": 12,
    "CC": "BZ",
    "alpha3": "BLZ",
    "ccTLD": ".BZ",
    "IAC": "+501",
    "IDD": "00",
    "TimeZone": "UTC-06:00",
    "Currency": {
      "Currencies": "Belize dollar (BZD, $)"
    },
    "Language(s)": "English"
  },
  {
    "Entity": "Benin",
    "Capital": "Porto-Novo",
    "Num": 12,
    "CC": "BJ",
    "alpha3": "BEN",
    "ccTLD": ".BJ",
    "IAC": "+229",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "West African CFA franc (XOF, Fr)"
    },
    "Language(s)": "French"
  },
  {
    "Entity": "Bhutan",
    "Capital": "Thimphu",
    "Num": 12,
    "CC": "BT",
    "alpha3": "BTN",
    "ccTLD": ".BT",
    "IAC": "+975",
    "IDD": "00",
    "TimeZone": "UTC+05:30",
    "Currency": {
      "Currencies": "Bhutanese ngultrum (BTN, Nu.), Indian rupee (INR, ₹)"
    },
    "Language(s)": "Dzongkha"
  },
  {
    "Entity": "Bolivia",
    "Capital": "Sucre",
    "Num": 12,
    "CC": "BO",
    "alpha3": "BOL",
    "ccTLD": ".BO",
    "IAC": "+591",
    "IDD": "00",
    "TimeZone": "UTC-04:00",
    "Currency": {
      "Currencies": "Bolivian boliviano (BOB, Bs.)"
    },
    "Language(s)": "Spanish, Aymara, Quechua"
  },
  {
    "Entity": "Bosnia and Herzegovina",
    "Capital": "Sarajevo",
    "Num": 12,
    "CC": "BA",
    "alpha3": "BIH",
    "ccTLD": ".BA",
    "IAC": "+387",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Bosnia and Herzegovina convertible mark (BAM, KM or КМ)"
    },
    "Language(s)": "Bosnian, Croatian, Serbian"
  },
  {
    "Entity": "Botswana",
    "Capital": "Gaborone",
    "Num": 12,
    "CC": "BW",
    "alpha3": "BWA",
    "ccTLD": ".BW",
    "IAC": "+267",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Botswana pula (BWP, P)"
    },
    "Language(s)": "English, Tswana (national)"
  },
  {
    "Entity": "Brazil",
    "Capital": "Brasília",
    "Num": 11,
    "CC": "BR",
    "alpha3": "BRA",
    "ccTLD": ".BR",
    "IAC": "+55",
    "IDD": "00",
    "TimeZone": "UTC-05:00 - UTC-03:00",
    "Currency": {
      "Currencies": "Brazilian real (BRL, R$)"
    },
    "Language(s)": "Portuguese (national), German, Nheengatu, Baniwa and Tucano in some areas."
  },
  {
    "Entity": "British Indian Ocean Territory",
    "Capital": "Diego Garcia",
    "Num": 12,
    "CC": "IO",
    "alpha3": "IOT",
    "ccTLD": ".IO",
    "IAC": "+246",
    "IDD": "00",
    "TimeZone": "UTC+06",
    "Currency": {
      "Currencies": "United States dollar (USD, $)"
    },
    "Language(s)": "English"
  },
  {
    "Entity": "Brunei",
    "Capital": "Bandar Seri Begawan",
    "Num": 12,
    "CC": "BN",
    "alpha3": "BRN",
    "ccTLD": ".BN",
    "IAC": "+673",
    "IDD": "00",
    "TimeZone": "UTC+08:00",
    "Currency": {
      "Currencies": "Brunei dollar (BND, $), Singapore dollar (SGD, $)"
    },
    "Language(s)": "Malay"
  },
  {
    "Entity": "Bulgaria",
    "Capital": "Sofia",
    "Num": 12,
    "CC": "BG",
    "alpha3": "BGR",
    "ccTLD": ".BG",
    "IAC": "+359",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Bulgarian lev (BGN, лв)"
    },
    "Language(s)": "Bulgarian"
  },
  {
    "Entity": "Burkina Faso",
    "Capital": "Ouagadougou",
    "Num": 12,
    "CC": "BF",
    "alpha3": "BFA",
    "ccTLD": ".BF",
    "IAC": "+226",
    "IDD": "00",
    "TimeZone": "UTC",
    "Currency": {
      "Currencies": "West African CFA franc (XOF, Fr)"
    },
    "Language(s)": "French, Fula, Jula, More"
  },
  {
    "Entity": "Burundi",
    "Capital": "Bujumbura",
    "Num": 12,
    "CC": "BI",
    "alpha3": "BDI",
    "ccTLD": ".BI",
    "IAC": "+257",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Burundian franc (BIF, Fr)"
    },
    "Language(s)": "French, Kirundi"
  },
  {
    "Entity": "Cambodia",
    "Capital": "Phnom Penh",
    "Num": 12,
    "CC": "KH",
    "alpha3": "KHM",
    "ccTLD": ".KH",
    "IAC": "+855",
    "IDD": "001",
    "TimeZone": "UTC+07:00",
    "Currency": {
      "Currencies": "Cambodian riel (KHR, ៛)"
    },
    "Language(s)": "Khmer"
  },
  {
    "Entity": "Cameroon",
    "Capital": "Yaoundé",
    "Num": 12,
    "CC": "CM",
    "alpha3": "CMR",
    "ccTLD": ".CM",
    "IAC": "+237",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Central African CFA franc (XAF, Fr)"
    },
    "Language(s)": "English, French"
  },
  {
    "Entity": "Cape Verde",
    "Capital": "Praia",
    "Num": 12,
    "CC": "CV",
    "alpha3": "CPV",
    "ccTLD": ".CV",
    "IAC": "+238",
    "IDD": "0",
    "TimeZone": "UTC-01:00",
    "Currency": {
      "Currencies": "Cape Verdean escudo (CVE, Esc or $)"
    },
    "Language(s)": "Portuguese (official), Cape Verdean Creole (national)"
  },
  {
    "Entity": "Central African Republic",
    "Capital": "Bangui",
    "Num": 12,
    "CC": "CF",
    "alpha3": "CAF",
    "ccTLD": ".CF",
    "IAC": "+236",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Central African CFA franc (XAF, Fr)"
    },
    "Language(s)": "French, Sango (national)"
  },
  {
    "Entity": "Chad",
    "Capital": "N'Djamena",
    "Num": 12,
    "CC": "TD",
    "alpha3": "TCD",
    "ccTLD": ".TD",
    "IAC": "+235",
    "IDD": "15",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Central African CFA franc (XAF, Fr)"
    },
    "Language(s)": "Arabic, French"
  },
  {
    "Entity": "Chile",
    "Capital": "Santiago",
    "Num": 13,
    "CC": "CL",
    "alpha3": "CHL",
    "ccTLD": ".CL",
    "IAC": "+56",
    "IDD": "00",
    "TimeZone": "UTC-04:00",
    "Currency": {
      "Currencies": "Chilean peso (CLP, $)"
    },
    "Language(s)": "Spanish (de facto)"
  },
  {
    "Entity": "China",
    "Capital": "Beijing",
    "Num": 11,
    "CC": "CN",
    "alpha3": "CHN",
    "ccTLD": ".CN",
    "IAC": "+86",
    "IDD": "00",
    "TimeZone": "UTC+08:00",
    "Currency": {
      "Currencies": "Chinese yuan (CNY, ¥ or ? ?)"
    },
    "Language(s)": "Simplified Chinese(written) Mandarin(spoken), and some other minority languages in some areas."
  },
  {
    "Entity": "Colombia",
    "Capital": "Bogotá",
    "Num": 10,
    "CC": "CO",
    "alpha3": "COL",
    "ccTLD": ".CO",
    "IAC": "+57",
    "IDD": "00",
    "TimeZone": "UTC-05:00",
    "Currency": {
      "Currencies": "Colombian peso (COP, $)"
    },
    "Language(s)": "Spanish (the languages and dialects of ethnic groups are also official in their territories)"
  },
  // {
  //   "Entity": "Comoros",
  //   "Capital": "Moroni",
  //   "Num": 12,
  //   "CC": "KM",
  //   "alpha3": "COM",
  //   "ccTLD": ".KM",
  //   "IAC": "",
  //   "IDD": "",
  //   "TimeZone": "UTC+03:00",
  //   "Currency": {
  //     "Currencies": "Comorian franc (KMF, Fr)"
  //   },
  //   "Language(s)": "Arabic, Comorian, French"
  // },
  {
    "Entity": "Congo, Republic of the",
    "Capital": "Brazzaville",
    "Num": 12,
    "CC": "CG",
    "alpha3": "COG",
    "ccTLD": ".CG",
    "IAC": "+242",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Central African CFA franc (XAF, Fr)"
    },
    "Language(s)": "French, Lingala (national), Munukutuba (national)"
  },
  {
    "Entity": "Congo, the Democratic Republic of the",
    "Capital": "Kinshasa",
    "Num": 12,
    "CC": "CD",
    "alpha3": "COD",
    "ccTLD": ".CD",
    "IAC": "+243",
    "IDD": "00",
    "TimeZone": "UTC+01:00 - UTC+02:00",
    "Currency": {
      "Currencies": "Congolese franc (CDF, Fr)"
    },
    "Language(s)": "French, Lingala/Kikongo/Swahili/Tshiluba (national)"
  },
  {
    "Entity": "Costa Rica",
    "Capital": "San José",
    "Num": 8,
    "CC": "CR",
    "alpha3": "CRI",
    "ccTLD": ".CR",
    "IAC": "+506",
    "IDD": "00",
    "TimeZone": "UTC-06:00",
    "Currency": {
      "Currencies": "Costa Rican colón (CRC, ₡)"
    },
    "Language(s)": "Spanish"
  },
  {
    "Entity": "Côte d'Ivoire",
    "Capital": "Yamoussoukro",
    "Num": 8,
    "CC": "CI",
    "alpha3": "CIV",
    "ccTLD": ".CI",
    "IAC": "+225",
    "IDD": "00",
    "TimeZone": "",
    "Currency": {
      "Currencies": "West African CFA franc (XOF, Fr)"
    },
    "Language(s)": "French"
  },
  {
    "Entity": "Croatia",
    "Capital": "Zagreb",
    "Num": 12,
    "CC": "HR",
    "alpha3": "HRV",
    "ccTLD": ".HR",
    "IAC": "+385",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Croatian kuna (HRK, kn)"
    },
    "Language(s)": "Croatian"
  },
  {
    "Entity": "Cuba",
    "Capital": "Havana",
    "Num": 13,
    "CC": "CU",
    "alpha3": "CUB",
    "ccTLD": ".CU",
    "IAC": "+53",
    "IDD": "119",
    "TimeZone": "UTC-03:00",
    "Currency": {
      "Currencies": "Cuban convertible peso (CUC, $), Cuban peso (CUP, $)"
    },
    "Language(s)": "Spanish"
  },
  {
    "Entity": "Curaçao",
    "Capital": "Willemstad",
    "Num": 13,
    "CC": "CW",
    "alpha3": "CUW",
    "ccTLD": ".CW",
    "IAC": "+599 9",
    "IDD": "00",
    "TimeZone": "",
    "Currency": {
      "Currencies": "Netherlands Antillean guilder (ANG, ƒ)"
    },
    "Language(s)": "Papiamentu, Dutch(official)"
  },
  {
    "Entity": "Cyprus",
    "Capital": "Nicosia",
    "Num": 8,
    "CC": "CY",
    "alpha3": "CYP",
    "ccTLD": ".CY",
    "IAC": "+357",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "Greek, Turkish, Armenian (minority language)"
  },
  {
    "Entity": "Czech Republic",
    "Capital": "Prague",
    "Num": 9,
    "CC": "CZ",
    "alpha3": "CZE",
    "ccTLD": ".CZ",
    "IAC": "+420",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Czech koruna (CZK, Kč)"
    },
    "Language(s)": "Czech"
  },
  {
    "Entity": "Denmark",
    "Capital": "Copenhagen",
    "Num": 8,
    "CC": "DK",
    "alpha3": "DNK",
    "ccTLD": ".DK",
    "IAC": "+45",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Danish krone (DKK, kr)"
    },
    "Language(s)": "Danish, and Faroese, German, Kalaallisut in some areas."
  },
  {
    "Entity": "Djibouti",
    "Capital": "Djibouti",
    "Num": 12,
    "CC": "DJ",
    "alpha3": "DJI",
    "ccTLD": ".DJ",
    "IAC": "+253",
    "IDD": "00",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Djiboutian franc (DJF, Fr)"
    },
    "Language(s)": "Arabic, French"
  },
  {
    "Entity": "Dominica",
    "Capital": "Roseau",
    "Num": 12,
    "CC": "DM",
    "alpha3": "DMA",
    "ccTLD": ".DM",
    "IAC": "+1 767",
    "IDD": "011",
    "TimeZone": "UTC-04:00",
    "Currency": {
      "Currencies": "East Caribbean dollar (XCD, $)"
    },
    "Language(s)": "English"
  },
  {
    "Entity": "Dominican Republic",
    "Capital": "Santo Domingo",
    "Num": 11,
    "CC": "DO",
    "alpha3": "DOM",
    "ccTLD": ".DO",
    "IAC": "+1 809 / 829 / 849",
    "IDD": "011",
    "TimeZone": "UTC-04:00",
    "Currency": {
      "Currencies": "Dominican peso (DOP, $)"
    },
    "Language(s)": "Spanish"
  },
  {
    "Entity": "Ecuador",
    "Capital": "Quito",
    "Num": 12,
    "CC": "EC",
    "alpha3": "ECU",
    "ccTLD": ".EC",
    "IAC": "+593",
    "IDD": "00",
    "TimeZone": "UTC-05:00",
    "Currency": {
      "Currencies": "United States dollar (USD, $)"
    },
    "Language(s)": "Spanish (Quechua and Shuar, ancient languages are official in some territories.)"
  },
  {
    "Entity": "Egypt",
    "Capital": "Cairo",
    "Num": 12,
    "CC": "EG",
    "alpha3": "EGY",
    "ccTLD": ".EG",
    "IAC": "+20",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Egyptian pound (EGP, £ or ج.? )"
    },
    "Language(s)": "Arabic, Coptic (de jure language of the Coptic Church)"
  },
  {
    "Entity": "El Salvador",
    "Capital": "San Salvador",
    "Num": 8,
    "CC": "SV",
    "alpha3": "SLV",
    "ccTLD": ".SV",
    "IAC": "+503",
    "IDD": "00",
    "TimeZone": "UTC-06:00",
    "Currency": {
      "Currencies": "Salvadoran colón (SVC, ₡), United States dollar (USD, $)"
    },
    "Language(s)": "Spanish"
  },
  {
    "Entity": "Equatorial Guinea",
    "Capital": "Malabo",
    "Num": 12,
    "CC": "GQ",
    "alpha3": "GNQ",
    "ccTLD": ".GQ",
    "IAC": "+240",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Central African CFA franc (XAF, Fr)"
    },
    "Language(s)": "Spanish, French"
  },
  {
    "Entity": "Eritrea",
    "Capital": "Asmara",
    "Num": 12,
    "CC": "ER",
    "alpha3": "ERI",
    "ccTLD": ".ER",
    "IAC": "+291",
    "IDD": "00",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Eritrean nakfa (ERN, Nfk)"
    },
    "Language(s)": "Arabic, Tigrinya (working language)"
  },
  {
    "Entity": "Estonia",
    "Capital": "Tallinn",
    "Num": 10,
    "CC": "EE",
    "alpha3": "EST",
    "ccTLD": ".EE",
    "IAC": "+372",
    "IDD": "00",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "Estonian, Russian (de jure minority language)"
  },
  {
    "Entity": "Ethiopia",
    "Capital": "Addis Ababa",
    "Num": 10,
    "CC": "ET",
    "alpha3": "ETH",
    "ccTLD": ".ET",
    "IAC": "+251",
    "IDD": "00",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Ethiopian birr (ETB, Br)"
    },
    "Language(s)": "Amharic (working language)"
  },
  {
    "Entity": "Fiji",
    "Capital": "Suva",
    "Num": 12,
    "CC": "FJ",
    "alpha3": "FJI",
    "ccTLD": ".FJ",
    "IAC": "+679",
    "IDD": "00",
    "TimeZone": "UTC+12:00",
    "Currency": {
      "Currencies": "Fijian dollar (FJD, $)"
    },
    "Language(s)": "English, Fijian, Hindustani"
  },
  {
    "Entity": "Finland",
    "Capital": "Helsinki",
    "Num": 9,
    "CC": "FI",
    "alpha3": "FIN",
    "ccTLD": ".FI",
    "IAC": "+358",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "Finnish, Swedish, Sami"
  },
  {
    "Entity": "France",
    "Capital": "Paris",
    "Num": 9,
    "CC": "FR",
    "alpha3": "FRA",
    "ccTLD": ".FR",
    "IAC": "+33",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "French (statewide), Corsican (in Corsica), Breton (in Brittany)"
  },
  {
    "Entity": "French Guiana",
    "Capital": "Cayenne",
    "Num": 12,
    "CC": "GF",
    "alpha3": "GUF",
    "ccTLD": ".GF",
    "IAC": "+594",
    "IDD": "00",
    "TimeZone": "UTC-01:00",
    "Currency": {
      "Currencies": ""
    },
    "Language(s)": ""
  },
  {
    "Entity": "French Polynesia",
    "Capital": "Papeete",
    "Num": 12,
    "CC": "PF",
    "alpha3": "PYF",
    "ccTLD": ".PF",
    "IAC": "+689",
    "IDD": "00",
    "TimeZone": "UTC-10:00",
    "Currency": {
      "Currencies": "CFP franc (XPF, Fr)"
    },
    "Language(s)": "French"
  },
  {
    "Entity": "Gabon",
    "Capital": "Libreville",
    "Num": 12,
    "CC": "GA",
    "alpha3": "GAB",
    "ccTLD": ".GA",
    "IAC": "+241",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Central African CFA franc (XAF, Fr)"
    },
    "Language(s)": "French"
  },
  {
    "Entity": "Gambia",
    "Capital": "Banjul",
    "Num": 12,
    "CC": "GM",
    "alpha3": "GMB",
    "ccTLD": ".GM",
    "IAC": "+220",
    "IDD": "00",
    "TimeZone": "UTC",
    "Currency": {
      "Currencies": "Gambian dalasi (GMD, D)"
    },
    "Language(s)": "English"
  },
  {
    "Entity": "Georgia",
    "Capital": "Tbilisi",
    "Num": 12,
    "CC": "GE",
    "alpha3": "GEO",
    "ccTLD": ".GE",
    "IAC": "+995",
    "IDD": "8~10",
    "TimeZone": "UTC+04:00",
    "Currency": {
      "Currencies": "Georgian lari (GEL, ლ)"
    },
    "Language(s)": "Georgian (statewide), Abkhaz (in Abkhazia), Ossetic (in South Ossetia), Russian (in Abkhazia and South Ossetia)"
  },
  {
    "Entity": "Germany",
    "Capital": "Berlin",
    "Num": 12,
    "CC": "DE",
    "alpha3": "DEU",
    "ccTLD": ".DE",
    "IAC": "+49",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "German (de facto)"
  },
  {
    "Entity": "Ghana",
    "Capital": "Accra",
    "Num": 12,
    "CC": "GH",
    "alpha3": "GHA",
    "ccTLD": ".GH",
    "IAC": "+233",
    "IDD": "00",
    "TimeZone": "UTC",
    "Currency": {
      "Currencies": "Ghanaian cedi (GHS, ₵)"
    },
    "Language(s)": "English, and some other languages in some territories."
  },
  {
    "Entity": "Greece",
    "Capital": "Athens",
    "Num": 13,
    "CC": "GR",
    "alpha3": "GRC",
    "ccTLD": ".GR",
    "IAC": "+30",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "Greek"
  },
  {
    "Entity": "Grenada",
    "Capital": "St. George's",
    "Num": 13,
    "CC": "GD",
    "alpha3": "GRD",
    "ccTLD": ".GD",
    "IAC": "+1 473",
    "IDD": "011",
    "TimeZone": "UTC-04:00",
    "Currency": {
      "Currencies": "East Caribbean dollar (XCD, $)"
    },
    "Language(s)": "English"
  },
  {
    "Entity": "Guam",
    "Capital": "Hagåtña",
    "Num": 13,
    "CC": "GU",
    "alpha3": "GUM",
    "ccTLD": ".GU",
    "IAC": "+1 671",
    "IDD": "011",
    "TimeZone": "UTC+10:00",
    "Currency": {
      "Currencies": "United States dollar (USD, $)"
    },
    "Language(s)": "English and Chamorro"
  },
  {
    "Entity": "Guatemala",
    "Capital": "Guatemala City",
    "Num": 8,
    "CC": "GT",
    "alpha3": "GTM",
    "ccTLD": ".GT",
    "IAC": "+502",
    "IDD": "00",
    "TimeZone": "UTC-06:00",
    "Currency": {
      "Currencies": "Guatemalan quetzal (GTQ, Q)"
    },
    "Language(s)": "Spanish"
  },
  {
    "Entity": "Guinea",
    "Capital": "Conakry",
    "Num": 8,
    "CC": "GN",
    "alpha3": "GIN",
    "ccTLD": ".GN",
    "IAC": "+224",
    "IDD": "00",
    "TimeZone": "UTC",
    "Currency": {
      "Currencies": "Guinean franc (GNF, Fr)"
    },
    "Language(s)": "French, Fula (national), Maninka (national), Susu (national)"
  },
  {
    "Entity": "Guinea-Bissau",
    "Capital": "Bissau",
    "Num": 8,
    "CC": "GW",
    "alpha3": "GNB",
    "ccTLD": ".GW",
    "IAC": "+245",
    "IDD": "00",
    "TimeZone": "UTC",
    "Currency": {
      "Currencies": "West African CFA franc (XOF, Fr)"
    },
    "Language(s)": "Portuguese"
  },
  {
    "Entity": "Guyana",
    "Capital": "Georgetown",
    "Num": 12,
    "CC": "GY",
    "alpha3": "GUY",
    "ccTLD": ".GY",
    "IAC": "+592",
    "IDD": "001",
    "TimeZone": "UTC-03:00",
    "Currency": {
      "Currencies": "Guyanese dollar (GYD, $)"
    },
    "Language(s)": "English (official), Guyanese Creole (national)"
  },
  {
    "Entity": "Haiti",
    "Capital": "Port-au-Prince",
    "Num": 8,
    "CC": "HT",
    "alpha3": "HTI",
    "ccTLD": ".HT",
    "IAC": "+509",
    "IDD": "00",
    "TimeZone": "UTC-05:00",
    "Currency": {
      "Currencies": "Haitian gourde (HTG, G)"
    },
    "Language(s)": "French, Haitian Creole"
  },
  {
    "Entity": "Honduras",
    "Capital": "Tegucigalpa",
    "Num": 12,
    "CC": "HN",
    "alpha3": "HND",
    "ccTLD": ".HN",
    "IAC": "+504",
    "IDD": "00",
    "TimeZone": "UTC-06:00",
    "Currency": {
      "Currencies": "Honduran lempira (HNL, L)"
    },
    "Language(s)": "Spanish, and Garifuna, English, Miskito in some territories."
  },
  {
    "Entity": "Hong Kong",
    "Capital": "",
    "Num": 8,
    "CC": "HK",
    "alpha3": "HKG",
    "ccTLD": ".HK",
    "IAC": "+852",
    "IDD": "001",
    "TimeZone": "UTC+08:00",
    "Currency": {
      "Currencies": "Hong Kong dollar (HKD, $)"
    },
    "Language(s)": "Cantonese, English"
  },
  {
    "Entity": "Hungary",
    "Capital": "Budapest",
    "Num": 8,
    "CC": "HU",
    "alpha3": "HUN",
    "ccTLD": ".HU",
    "IAC": "+36",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Hungarian forint (HUF, Ft)"
    },
    "Language(s)": "Hungarian"
  },
  {
    "Entity": "Iceland",
    "Capital": "Reykjavík",
    "Num": 7,
    "CC": "IS",
    "alpha3": "ISL",
    "ccTLD": ".IS",
    "IAC": "+354",
    "IDD": "00",
    "TimeZone": "UTC",
    "Currency": {
      "Currencies": "Icelandic króna (ISK, kr)"
    },
    "Language(s)": "Icelandic, Icelandic Sign Language"
  },
  {
    "Entity": "India",
    "Capital": "New Delhi",
    "Num": 10,
    "CC": "IN",
    "alpha3": "IND",
    "ccTLD": ".IN",
    "IAC": "+91",
    "IDD": "00",
    "TimeZone": "UTC+05:30",
    "Currency": {
      "Currencies": "Indian rupee (INR, ₹)"
    },
    "Language(s)": "English (statewide), Hindi, and some other languages in some territories."
  },
  {
    "Entity": "Indonesia",
    "Capital": "Jakarta",
    "Num": 13,
    "CC": "ID",
    "alpha3": "IDN",
    "ccTLD": ".ID",
    "IAC": "+62",
    "IDD": "001, 008",
    "TimeZone": "UTC+07:00 - UTC+09:00",
    "Currency": {
      "Currencies": "Indonesian rupiah (IDR, Rp)"
    },
    "Language(s)": "Indonesian (national and official), and some other languages in some territories."
  },
  {
    "Entity": "Iran",
    "Capital": "Tehran",
    "Num": 10,
    "CC": "IR",
    "alpha3": "IRN",
    "ccTLD": ".IR",
    "IAC": "+98",
    "IDD": "00",
    "TimeZone": "UTC+03:30",
    "Currency": {
      "Currencies": "Iranian rial (IRR, ﷼)"
    },
    "Language(s)": "Persian (statewide), and some other languages in some territories."
  },
  {
    "Entity": "Iraq",
    "Capital": "Baghdad",
    "Num": 12,
    "CC": "IQ",
    "alpha3": "IRQ",
    "ccTLD": ".IQ",
    "IAC": "+964",
    "IDD": "00",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Iraqi dinar (IQD, ع.د)"
    },
    "Language(s)": "Arabic statewide, (Kurdish, Assyrian Neo-Aramaic, Iraqi Turkmen in some areas)"
  },
  {
    "Entity": "Ireland",
    "Capital": "Dublin",
    "Num": 9,
    "CC": "IE",
    "alpha3": "IRL",
    "ccTLD": ".IE",
    "IAC": "+353",
    "IDD": "00",
    "TimeZone": "UTC",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "Irish (national), English (national)"
  },
  {
    "Entity": "Israel",
    "Capital": "Jerusalem",
    "Num": 10,
    "CC": "IL",
    "alpha3": "ISR",
    "ccTLD": ".IL",
    "IAC": "+972",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Israeli new shekel (ILS, ₪)"
    },
    "Language(s)": "Hebrew (de facto official), Arabic (co-official)"
  },
  {
    "Entity": "Italy",
    "Capital": "Rome",
    "Num": 10,
    "CC": "IT",
    "alpha3": "ITA",
    "ccTLD": ".IT",
    "IAC": "+39",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "Italian, and some other languages in some territories."
  },
  {
    "Entity": "Jamaica",
    "Capital": "Kingston",
    "Num": 11,
    "CC": "JM",
    "alpha3": "JAM",
    "ccTLD": ".JM",
    "IAC": "+1 876",
    "IDD": "011",
    "TimeZone": "UTC-05:00",
    "Currency": {
      "Currencies": "Jamaican dollar (JMD, $)"
    },
    "Language(s)": "English, Jamaican patois (national language)"
  },
  {
    "Entity": "Japan",
    "Capital": "Tokyo",
    "Num": 10,
    "CC": "JP",
    "alpha3": "JPN",
    "ccTLD": ".JP",
    "IAC": "+81",
    "IDD": "001",
    "TimeZone": "UTC+09:00",
    "Currency": {
      "Currencies": "Japanese yen (JPY, ¥)"
    },
    "Language(s)": "Japanese (de facto)"
  },
  {
    "Entity": "Jordan",
    "Capital": "Amman",
    "Num": 12,
    "CC": "JO",
    "alpha3": "JOR",
    "ccTLD": ".JO",
    "IAC": "+962",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Jordanian dinar (JOD, د.ا)"
    },
    "Language(s)": "Arabic"
  },
  {
    "Entity": "Kazakhstan",
    "Capital": "Astana",
    "Num": 10,
    "CC": "KZ",
    "alpha3": "KAZ",
    "ccTLD": ".KZ",
    "IAC": "+7 6xx, 7xx",
    "IDD": "8~10",
    "TimeZone": "UTC+06:00",
    "Currency": {
      "Currencies": "Kazakhstani tenge (KZT, ₸)"
    },
    "Language(s)": "Kazakh (national), Russian"
  },
  {
    "Entity": "Kenya",
    "Capital": "Nairobi",
    "Num": 12,
    "CC": "KE",
    "alpha3": "KEN",
    "ccTLD": ".KE",
    "IAC": "+254",
    "IDD": "000",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Kenyan shilling (KES, Sh)"
    },
    "Language(s)": "English, Swahili (national)"
  },
  {
    "Entity": "Kiribati",
    "Capital": "Tarawa",
    "Num": 12,
    "CC": "KI",
    "alpha3": "KIR",
    "ccTLD": ".KI",
    "IAC": "+686",
    "IDD": "00",
    "TimeZone": "UTC+12:00",
    "Currency": {
      "Currencies": "Australian dollar (AUD, $), Kiribati dollar (None, $)"
    },
    "Language(s)": "English, Kiribati (national)"
  },
  {
    "Entity": "Kuwait",
    "Capital": "Kuwait City",
    "Num": 13,
    "CC": "KW",
    "alpha3": "KWT",
    "ccTLD": ".KW",
    "IAC": "+965",
    "IDD": "00",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Kuwaiti dinar (KWD, د.ك)"
    },
    "Language(s)": "Arabic"
  },
  {
    "Entity": "Kyrgyzstan",
    "Capital": "Bishkek",
    "Num": 9,
    "CC": "KG",
    "alpha3": "KGZ",
    "ccTLD": ".KG",
    "IAC": "+996",
    "IDD": "00",
    "TimeZone": "UTC+06:00",
    "Currency": {
      "Currencies": "Kyrgyzstani som (KGS, лв)"
    },
    "Language(s)": "Kirghiz (national), Russian"
  },
  {
    "Entity": "Laos",
    "Capital": "Vientiane",
    "Num": 12,
    "CC": "LA",
    "alpha3": "LAO",
    "ccTLD": ".LA",
    "IAC": "+856",
    "IDD": "00",
    "TimeZone": "UTC+07:00",
    "Currency": {
      "Currencies": "Lao kip (LAK, ₭)"
    },
    "Language(s)": "Lao"
  },
  {
    "Entity": "Latvia",
    "Capital": "Riga",
    "Num": 8,
    "CC": "LV",
    "alpha3": "LVA",
    "ccTLD": ".LV",
    "IAC": "+371",
    "IDD": "00",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Latvian lats (LVL, Ls)"
    },
    "Language(s)": "Latvian, Russian (minority language), Latgalian (regional language in Latgale)"
  },
  {
    "Entity": "Lebanon",
    "Capital": "Beirut",
    "Num": 13,
    "CC": "LB",
    "alpha3": "LBN",
    "ccTLD": ".LB",
    "IAC": "+961",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Lebanese pound (LBP, ل.ل)"
    },
    "Language(s)": "Arabic, French, Armenian (regional in Bourj Hammoud)"
  },
  {
    "Entity": "Lesotho",
    "Capital": "Maseru",
    "Num": 12,
    "CC": "LS",
    "alpha3": "LSO",
    "ccTLD": ".LS",
    "IAC": "+266",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Lesotho loti (LSL, L), South African rand (ZAR, R)"
    },
    "Language(s)": "English, Sotho (national)"
  },
  {
    "Entity": "Liberia",
    "Capital": "Monrovia",
    "Num": 12,
    "CC": "LR",
    "alpha3": "LBR",
    "ccTLD": ".LR",
    "IAC": "+231",
    "IDD": "00",
    "TimeZone": "UTC",
    "Currency": {
      "Currencies": "Liberian dollar (LRD, $)"
    },
    "Language(s)": "English"
  },
  {
    "Entity": "Libya",
    "Capital": "Tripoli",
    "Num": 13,
    "CC": "LY",
    "alpha3": "LBY",
    "ccTLD": ".LY",
    "IAC": "+218",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Libyan dinar (LYD, ل.د)"
    },
    "Language(s)": "Arabic"
  },
  {
    "Entity": "Liechtenstein",
    "Capital": "Vaduz",
    "Num": 13,
    "CC": "LI",
    "alpha3": "LIE",
    "ccTLD": ".LI",
    "IAC": "+423",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Swiss franc (CHF, Fr)"
    },
    "Language(s)": "German"
  },
  {
    "Entity": "Lithuania",
    "Capital": "Vilnius",
    "Num": 13,
    "CC": "LT",
    "alpha3": "LTU",
    "ccTLD": ".LT",
    "IAC": "+370",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Lithuanian litas (LTL, Lt)"
    },
    "Language(s)": "Lithuanian"
  },
  {
    "Entity": "Luxembourg",
    "Capital": "Luxembourg",
    "Num": 13,
    "CC": "LU",
    "alpha3": "LUX",
    "ccTLD": ".LU",
    "IAC": "+352",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "French, German, Luxembourgish (national)"
  },
  {
    "Entity": "Macau",
    "Capital": "",
    "Num": 13,
    "CC": "MO",
    "alpha3": "MAC",
    "ccTLD": ".MO",
    "IAC": "+853",
    "IDD": "00",
    "TimeZone": "UTC+08:00",
    "Currency": {
      "Currencies": "Macanese pataca (MOP, P)"
    },
    "Language(s)": "Cantonese, Portuguese"
  },
  {
    "Entity": "Macedonia",
    "Capital": "Skopje",
    "Num": 13,
    "CC": "MK",
    "alpha3": "MKD",
    "ccTLD": ".MK",
    "IAC": "+389",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Macedonian denar (MKD, ден)"
    },
    "Language(s)": "Macedonian (statewide)"
  },
  {
    "Entity": "Madagascar",
    "Capital": "Antananarivo",
    "Num": 13,
    "CC": "MG",
    "alpha3": "MDG",
    "ccTLD": ".MG",
    "IAC": "+261",
    "IDD": "00",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Malagasy ariary (MGA, Ar)"
    },
    "Language(s)": "French(official), Malagasy (national)"
  },
  {
    "Entity": "Malawi",
    "Capital": "Lilongwe",
    "Num": 454,
    "CC": "MW",
    "alpha3": "MWI",
    "ccTLD": ".MW",
    "IAC": "+265",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Malawian kwacha (MWK, MK)"
    },
    "Language(s)": "Chichewa (national), English(official)"
  },
  {
    "Entity": "Malaysia",
    "Capital": "Kuala Lumpur",
    "Num": 10,
    "CC": "MY",
    "alpha3": "MYS",
    "ccTLD": ".MY",
    "IAC": "+60",
    "IDD": "00",
    "TimeZone": "UTC+08:00",
    "Currency": {
      "Currencies": "Malaysian ringgit (MYR, RM)"
    },
    "Language(s)": "Malay (national), English (official for some purposes)"
  },
  {
    "Entity": "Maldives",
    "Capital": "Malé",
    "Num": 12,
    "CC": "MV",
    "alpha3": "MDV",
    "ccTLD": ".MV",
    "IAC": "+960",
    "IDD": "00",
    "TimeZone": "UTC+05:00",
    "Currency": {
      "Currencies": "Maldivian rufiyaa (MVR, ރ.)"
    },
    "Language(s)": "Dhivehi"
  },
  {
    "Entity": "Mali",
    "Capital": "Bamako",
    "Num": 12,
    "CC": "ML",
    "alpha3": "MLI",
    "ccTLD": ".ML",
    "IAC": "+223",
    "IDD": "00",
    "TimeZone": "UTC",
    "Currency": {
      "Currencies": "West African CFA franc (XOF, Fr)"
    },
    "Language(s)": "French"
  },
  {
    "Entity": "Malta",
    "Capital": "Valletta",
    "Num": 12,
    "CC": "MT",
    "alpha3": "MLT",
    "ccTLD": ".MT",
    "IAC": "+356",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "Maltese (national), English"
  },
  {
    "Entity": "Marshall Islands",
    "Capital": "Majuro",
    "Num": 12,
    "CC": "MH",
    "alpha3": "MHL",
    "ccTLD": ".MH",
    "IAC": "+692",
    "IDD": "011",
    "TimeZone": "UTC+10:00",
    "Currency": {
      "Currencies": "United States dollar (USD, $)"
    },
    "Language(s)": "English, Marshallese (national)"
  },
  {
    "Entity": "Martinique",
    "Capital": "",
    "Num": 12,
    "CC": "MQ",
    "alpha3": "MTQ",
    "ccTLD": ".MQ",
    "IAC": "+596",
    "IDD": "00",
    "TimeZone": "UTC-04:00",
    "Currency": {
      "Currencies": ""
    },
    "Language(s)": ""
  },
  {
    "Entity": "Mauritania",
    "Capital": "Nouakchott",
    "Num": 12,
    "CC": "MR",
    "alpha3": "MRT",
    "ccTLD": ".MR",
    "IAC": "+222",
    "IDD": "00",
    "TimeZone": "UTC",
    "Currency": {
      "Currencies": "Mauritanian ouguiya (MRO, UM)"
    },
    "Language(s)": "Arabic (national), French, Fula (national), Soninke (national), Wolof (national)"
  },
  {
    "Entity": "Mauritius",
    "Capital": "Port Louis",
    "Num": 12,
    "CC": "MU",
    "alpha3": "MUS",
    "ccTLD": ".MU",
    "IAC": "+230",
    "IDD": "00",
    "TimeZone": "UTC+04:00",
    "Currency": {
      "Currencies": "Mauritian rupee (MUR, ₨)"
    },
    "Language(s)": "English"
  },
  {
    "Entity": "Mexico",
    "Capital": "Mexico City",
    "Num": 10,
    "CC": "MX",
    "alpha3": "MEX",
    "ccTLD": ".MX",
    "IAC": "+52",
    "IDD": "00",
    "TimeZone": "UTC-08:00 - UTC-06:00",
    "Currency": {
      "Currencies": "Mexican peso (MXN, $)"
    },
    "Language(s)": "Spanish de facto"
  },
  {
    "Entity": "Micronesia, Federated States of",
    "Capital": "Palikir",
    "Num": 12,
    "CC": "FM",
    "alpha3": "FSM",
    "ccTLD": ".FM",
    "IAC": "+691",
    "IDD": "011",
    "TimeZone": "UTC+10:00",
    "Currency": {
      "Currencies": "Micronesian dollar (None, $), United States dollar (USD, $)"
    },
    "Language(s)": "English, Chuukese (in Chuuk), Kosraean (in Kosrae), Pohnpeian (in Pohnpei), Ulithian (in Yap), Yapese (in Yap)"
  },
  {
    "Entity": "Moldova, Republic of",
    "Capital": "Chisinau",
    "Num": 8,
    "CC": "MD",
    "alpha3": "MDA",
    "ccTLD": ".MD",
    "IAC": "+373",
    "IDD": "00",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Moldovan leu (MDL, L)"
    },
    "Language(s)": "Romanian, and Gagauz/Russian/Ukrainian are regional languages"
  },
  {
    "Entity": "Monaco",
    "Capital": "Monaco",
    "Num": 12,
    "CC": "MC",
    "alpha3": "MCO",
    "ccTLD": ".MC",
    "IAC": "+377",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "French"
  },
  {
    "Entity": "Mongolia",
    "Capital": "Ulaanbaatar",
    "Num": 12,
    "CC": "MN",
    "alpha3": "MNG",
    "ccTLD": ".MN",
    "IAC": "+976",
    "IDD": "001",
    "TimeZone": "UTC+08:00",
    "Currency": {
      "Currencies": "Mongolian tögrög (MNT, ₮)"
    },
    "Language(s)": "Mongolian"
  },
  {
    "Entity": "Montenegro",
    "Capital": "Podgorica",
    "Num": 12,
    "CC": "ME",
    "alpha3": "MNE",
    "ccTLD": ".ME",
    "IAC": "+382",
    "IDD": "",
    "TimeZone": "UTC+01",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "Montenegrin (national), Albanian/Bosnian/Croatian/Serbian are regional languages."
  },
  {
    "Entity": "Morocco",
    "Capital": "Rabat",
    "Num": 12,
    "CC": "MA",
    "alpha3": "MAR",
    "ccTLD": ".MA",
    "IAC": "+212",
    "IDD": "00",
    "TimeZone": "UTC",
    "Currency": {
      "Currencies": "Moroccan dirham (MAD, د.? .)"
    },
    "Language(s)": "Amazigh, Arabic"
  },
  {
    "Entity": "Mozambique",
    "Capital": "Maputo",
    "Num": 12,
    "CC": "MZ",
    "alpha3": "MOZ",
    "ccTLD": ".MZ",
    "IAC": "+258",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Mozambican metical (MZN, MTn)"
    },
    "Language(s)": "Portuguese"
  },
  {
    "Entity": "Myanmar (Burma)",
    "Capital": "Naypyidaw",
    "Num": 13,
    "CC": "MM",
    "alpha3": "MMR",
    "ccTLD": ".MM",
    "IAC": "+95",
    "IDD": "00",
    "TimeZone": "UTC+06:30",
    "Currency": {
      "Currencies": "Myanma kyat (MMK, K)"
    },
    "Language(s)": "Burmese"
  },
  {
    "Entity": "Namibia",
    "Capital": "Windhoek",
    "Num": 13,
    "CC": "NA",
    "alpha3": "NAM",
    "ccTLD": ".NA",
    "IAC": "+264",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Namibian dollar (NAD, $), South African rand (ZAR, R)"
    },
    "Language(s)": "English, Afrikaans (national), German(regional), Oshiwambo (regional)"
  },
  {
    "Entity": "Nauru",
    "Capital": "Yaren",
    "Num": 13,
    "CC": "NR",
    "alpha3": "NRU",
    "ccTLD": ".NR",
    "IAC": "+674",
    "IDD": "00",
    "TimeZone": "UTC+12:00",
    "Currency": {
      "Currencies": "Australian dollar (AUD, $), Nauruan dollar (None, $)"
    },
    "Language(s)": "English, Nauruan"
  },
  {
    "Entity": "Nepal",
    "Capital": "Kathmandu",
    "Num": 13,
    "CC": "NP",
    "alpha3": "NPL",
    "ccTLD": ".NP",
    "IAC": "+977",
    "IDD": "00",
    "TimeZone": "UTC+05:30",
    "Currency": {
      "Currencies": "Nepalese rupee (NPR, ₨)"
    },
    "Language(s)": "Nepali"
  },
  {
    "Entity": "Netherlands",
    "Capital": "Amsterdam",
    "Num": 10,
    "CC": "NL",
    "alpha3": "NLD",
    "ccTLD": ".NL",
    "IAC": "+31",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "Dutch (statewide), and some other languages in some territories."
  },
  {
    "Entity": "New Caledonia",
    "Capital": "Nouméa",
    "Num": 12,
    "CC": "NC",
    "alpha3": "NCL",
    "ccTLD": ".NC",
    "IAC": "+687",
    "IDD": "00",
    "TimeZone": "UTC+11:00",
    "Currency": {
      "Currencies": "CFP franc (XPF, Fr)"
    },
    "Language(s)": "French"
  },
  {
    "Entity": "New Zealand",
    "Capital": "Wellington",
    "Num": 10,
    "CC": "NZ",
    "alpha3": "NZL",
    "ccTLD": ".NZ",
    "IAC": "+64",
    "IDD": "00",
    "TimeZone": "UTC+12:00",
    "Currency": {
      "Currencies": "New Zealand dollar (NZD, $)"
    },
    "Language(s)": "English(de facto)"
  },
  {
    "Entity": "Nicaragua",
    "Capital": "Managua",
    "Num": 10,
    "CC": "NI",
    "alpha3": "NIC",
    "ccTLD": ".NI",
    "IAC": "+505",
    "IDD": "00",
    "TimeZone": "UTC-06:00",
    "Currency": {
      "Currencies": "Nicaraguan córdoba (NIO, C$)"
    },
    "Language(s)": "Spanish"
  },
  {
    "Entity": "Niger",
    "Capital": "Niamey",
    "Num": 12,
    "CC": "NE",
    "alpha3": "NER",
    "ccTLD": ".NE",
    "IAC": "+227",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "West African CFA franc (XOF, Fr)"
    },
    "Language(s)": "French, (Hausa/Fulfulde/Gulmancema etc. are national languages)"
  },
  {
    "Entity": "Nigeria",
    "Capital": "Abuja",
    "Num": 12,
    "CC": "NG",
    "alpha3": "NGA",
    "ccTLD": ".NG",
    "IAC": "+234",
    "IDD": "009",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Nigerian naira (NGN, ₦)"
    },
    "Language(s)": "English"
  },
  {
    "Entity": "North Korea",
    "Capital": "Pyongyang",
    "Num": 12,
    "CC": "KP",
    "alpha3": "PRK",
    "ccTLD": ".KP",
    "IAC": "+850",
    "IDD": "00",
    "TimeZone": "UTC+09:00",
    "Currency": {
      "Currencies": "North Korean won (KPW, ₩)"
    },
    "Language(s)": "Korean"
  },
  {
    "Entity": "Norway",
    "Capital": "Oslo",
    "Num": 8,
    "CC": "NO",
    "alpha3": "NOR",
    "ccTLD": ".NO",
    "IAC": "+47",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Norwegian krone (NOK, kr)"
    },
    "Language(s)": "Norwegian, Sami, Kven, Romani, Scandoromani"
  },
  {
    "Entity": "Oman",
    "Capital": "Muscat",
    "Num": 12,
    "CC": "OM",
    "alpha3": "OMN",
    "ccTLD": ".OM",
    "IAC": "+968",
    "IDD": "00",
    "TimeZone": "UTC+04:00",
    "Currency": {
      "Currencies": "Omani rial (OMR, ر.ع.)"
    },
    "Language(s)": "Arabic"
  },
  {
    "Entity": "Pakistan",
    "Capital": "Islamabad",
    "Num": 10,
    "CC": "PK",
    "alpha3": "PAK",
    "ccTLD": ".PK",
    "IAC": "+92",
    "IDD": "00",
    "TimeZone": "UTC+05:00",
    "Currency": {
      "Currencies": "Pakistani rupee (PKR, ₨)"
    },
    "Language(s)": "Urdu (national), English (official), Sindhi (co-official in Sindh province)"
  },
  {
    "Entity": "Palau",
    "Capital": "Ngerulmud",
    "Num": 12,
    "CC": "PW",
    "alpha3": "PLW",
    "ccTLD": ".PW",
    "IAC": "+680",
    "IDD": "011",
    "TimeZone": "UTC+09:00",
    "Currency": {
      "Currencies": "Palauan dollar (None, $), United States dollar (USD, $)"
    },
    "Language(s)": "English, Palauan, Sonsorolese (in Sonsorol), Tobian (in Hatohobei), Japanese (in Angaur)"
  },
  {
    "Entity": "Panama",
    "Capital": "Panama City",
    "Num": 12,
    "CC": "PA",
    "alpha3": "PAN",
    "ccTLD": ".PA",
    "IAC": "+507",
    "IDD": "00",
    "TimeZone": "UTC-05:00",
    "Currency": {
      "Currencies": "Panamanian balboa (PAB, B/.), United States dollar (USD, $)"
    },
    "Language(s)": "Spanish"
  },
  {
    "Entity": "Papua New Guinea",
    "Capital": "Port Moresby",
    "Num": 12,
    "CC": "PG",
    "alpha3": "PNG",
    "ccTLD": ".PG",
    "IAC": "+675",
    "IDD": "05",
    "TimeZone": "UTC+10:00",
    "Currency": {
      "Currencies": "Papua New Guinean kina (PGK, K)"
    },
    "Language(s)": "English, Hiri Motu, Tok Pisin"
  },
  {
    "Entity": "Paraguay",
    "Capital": "Asunción",
    "Num": 13,
    "CC": "PY",
    "alpha3": "PRY",
    "ccTLD": ".PY",
    "IAC": "+595",
    "IDD": "002",
    "TimeZone": "UTC-04:00",
    "Currency": {
      "Currencies": "Paraguayan guaraní (PYG, ₲)"
    },
    "Language(s)": "Spanish, Guaraní"
  },
  {
    "Entity": "Peru",
    "Capital": "Lima",
    "Num": 13,
    "CC": "PE",
    "alpha3": "PER",
    "ccTLD": ".PE",
    "IAC": "+51",
    "IDD": "00",
    "TimeZone": "UTC-05:00",
    "Currency": {
      "Currencies": "Peruvian nuevo sol (PEN, S/.)"
    },
    "Language(s)": "Spanish, Aymara, Quechua, All native languages in areas where they are spoken by the majority of people"
  },
  {
    "Entity": "Philippines",
    "Capital": "Manila",
    "Num": 11,
    "CC": "PH",
    "alpha3": "PHL",
    "ccTLD": ".PH",
    "IAC": "+63",
    "IDD": "00",
    "TimeZone": "UTC+08:00",
    "Currency": {
      "Currencies": "Philippine peso (PHP, ₱)"
    },
    "Language(s)": "English, Filipino(national), and some other languages in some territories."
  },
  {
    "Entity": "Poland",
    "Capital": "Warsaw",
    "Num": 9,
    "CC": "PL",
    "alpha3": "POL",
    "ccTLD": ".PL",
    "IAC": "+48",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Polish złoty (PLN, zł)"
    },
    "Language(s)": "Polish (sole official language of state)"
  },
  {
    "Entity": "Portugal",
    "Capital": "Lisbon",
    "Num": 12,
    "CC": "PT",
    "alpha3": "PRT",
    "ccTLD": ".PT",
    "IAC": "+351",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "Portuguese (official), Mirandese (regional)"
  },
  {
    "Entity": "Puerto Rico",
    "Capital": "San Juan",
    "Num": 10,
    "CC": "PR",
    "alpha3": "PRI",
    "ccTLD": ".PR",
    "IAC": "+1 787 / 939",
    "IDD": "011",
    "TimeZone": "UTC-04:00",
    "Currency": {
      "Currencies": "United States dollar (USD, $)"
    },
    "Language(s)": "Spanish and English"
  },
  {
    "Entity": "Qatar",
    "Capital": "Doha",
    "Num": 12,
    "CC": "QA",
    "alpha3": "QAT",
    "ccTLD": ".QA",
    "IAC": "+974",
    "IDD": "00",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Qatari riyal (QAR, ر.ق)"
    },
    "Language(s)": "Arabic"
  },
  {
    "Entity": "Réunion",
    "Capital": "",
    "Num": 12,
    "CC": "RE",
    "alpha3": "REU",
    "ccTLD": ".RE",
    "IAC": "+262",
    "IDD": "00",
    "TimeZone": "UTC+04:00",
    "Currency": {
      "Currencies": ""
    },
    "Language(s)": ""
  },
  {
    "Entity": "Romania",
    "Capital": "Bucharest",
    "Num": 13,
    "CC": "RO",
    "alpha3": "ROU",
    "ccTLD": ".RO",
    "IAC": "+40",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Romanian leu (RON, L)"
    },
    "Language(s)": "Romanian (statewide), and other minority languages."
  },
  {
    "Entity": "Russia",
    "Capital": "Moscow",
    "Num": 7,
    "CC": "RU",
    "alpha3": "RUS",
    "ccTLD": ".RU",
    "IAC": "+7",
    "IDD": "8~10",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Russian ruble (RUB, р.)"
    },
    "Language(s)": "Russian (federal; official), and some other languages in some territories."
  },
  {
    "Entity": "Rwanda",
    "Capital": "Kigali",
    "Num": 12,
    "CC": "RW",
    "alpha3": "RWA",
    "ccTLD": ".RW",
    "IAC": "+250",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Rwandan franc (RWF, Fr)"
    },
    "Language(s)": "English, French, Kinyarwanda"
  },
  {
    "Entity": "Saint Kitts and Nevis",
    "Capital": "Basseterre",
    "Num": 12,
    "CC": "KN",
    "alpha3": "KNA",
    "ccTLD": ".KN",
    "IAC": "+1 869",
    "IDD": "011",
    "TimeZone": "UTC-04:00",
    "Currency": {
      "Currencies": "East Caribbean dollar (XCD, $)"
    },
    "Language(s)": "English"
  },
  {
    "Entity": "Saint Lucia",
    "Capital": "Castries",
    "Num": 12,
    "CC": "LC",
    "alpha3": "LCA",
    "ccTLD": ".LC",
    "IAC": "+1 758",
    "IDD": "011",
    "TimeZone": "UTC-04:00",
    "Currency": {
      "Currencies": "East Caribbean dollar (XCD, $)"
    },
    "Language(s)": "English"
  },
  {
    "Entity": "Saint Vincent and the Grenadines",
    "Capital": "Kingstown",
    "Num": 12,
    "CC": "VC",
    "alpha3": "VCT",
    "ccTLD": ".VC",
    "IAC": "+1 784",
    "IDD": "011",
    "TimeZone": "UTC-04",
    "Currency": {
      "Currencies": "East Caribbean dollar (XCD, $)"
    },
    "Language(s)": "English"
  },
  {
    "Entity": "Samoa",
    "Capital": "Apia",
    "Num": 12,
    "CC": "WS",
    "alpha3": "WSM",
    "ccTLD": ".WS",
    "IAC": "+685",
    "IDD": "00",
    "TimeZone": "UTC-11:00",
    "Currency": {
      "Currencies": "Samoan tala (WST, T)"
    },
    "Language(s)": "English (official), Samoan (national)"
  },
  {
    "Entity": "San Marino",
    "Capital": "San Marino",
    "Num": 12,
    "CC": "SM",
    "alpha3": "SMR",
    "ccTLD": ".SM",
    "IAC": "+378",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "Italian"
  },
  {
    "Entity": "São Tomé and Príncipe",
    "Capital": "São Tomé",
    "Num": 12,
    "CC": "ST",
    "alpha3": "STP",
    "ccTLD": ".ST",
    "IAC": "+239",
    "IDD": "00",
    "TimeZone": "UTC",
    "Currency": {
      "Currencies": "São Tomé and Príncipe dobra (STD, Db)"
    },
    "Language(s)": "Portuguese"
  },
  {
    "Entity": "Saudi Arabia",
    "Capital": "Riyadh",
    "Num": 12,
    "CC": "SA",
    "alpha3": "SAU",
    "ccTLD": ".SA",
    "IAC": "+966",
    "IDD": "00",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Saudi riyal (SAR, ر.س)"
    },
    "Language(s)": "Arabic"
  },
  {
    "Entity": "Senegal",
    "Capital": "Dakar",
    "Num": 12,
    "CC": "SN",
    "alpha3": "SEN",
    "ccTLD": ".SN",
    "IAC": "+221",
    "IDD": "00",
    "TimeZone": "UTC",
    "Currency": {
      "Currencies": "West African CFA franc (XOF, Fr)"
    },
    "Language(s)": "French, Jola-Fogny (national), Mandinka (national), Pulaar (national), Serer-Sine (national), Wolof (national)"
  },
  {
    "Entity": "Serbia",
    "Capital": "Belgrade",
    "Num": 12,
    "CC": "RS",
    "alpha3": "SRB",
    "ccTLD": ".RS",
    "IAC": "+381",
    "IDD": "99",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Serbian dinar (RSD, дин. or din.)"
    },
    "Language(s)": "Serbian (statewide), and some other languages in some territories."
  },
  {
    "Entity": "Seychelles",
    "Capital": "Victoria",
    "Num": 12,
    "CC": "SC",
    "alpha3": "SYC",
    "ccTLD": ".SC",
    "IAC": "+248",
    "IDD": "00",
    "TimeZone": "UTC+04:00",
    "Currency": {
      "Currencies": "Seychellois rupee (SCR, ₨)"
    },
    "Language(s)": "English, French, Seychellois Creole"
  },
  {
    "Entity": "Sierra Leone",
    "Capital": "Freetown",
    "Num": 12,
    "CC": "SL",
    "alpha3": "SLE",
    "ccTLD": ".SL",
    "IAC": "+232",
    "IDD": "00",
    "TimeZone": "UTC",
    "Currency": {
      "Currencies": "Sierra Leonean leone (SLL, Le)"
    },
    "Language(s)": "English"
  },
  {
    "Entity": "Singapore",
    "Capital": "Singapore",
    "Num": 8,
    "CC": "SG",
    "alpha3": "SGP",
    "ccTLD": ".SG",
    "IAC": "+65",
    "IDD": "001",
    "TimeZone": "UTC+08:00",
    "Currency": {
      "Currencies": "Brunei dollar (BND, $), Singapore dollar (SGD, $)"
    },
    "Language(s)": "English, Malay (national), Chinese, Tamil"
  },
  {
    "Entity": "Slovakia",
    "Capital": "Bratislava",
    "Num": 12,
    "CC": "SK",
    "alpha3": "SVK",
    "ccTLD": ".SK",
    "IAC": "+421",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "Slovak"
  },
  {
    "Entity": "Slovenia",
    "Capital": "Ljubljana",
    "Num": 12,
    "CC": "SI",
    "alpha3": "SVN",
    "ccTLD": ".SI",
    "IAC": "+386",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "Slovene (statewide), Hungarian/Italian/Serbo-Croatian in some territories."
  },
  {
    "Entity": "Solomon Islands",
    "Capital": "Honiara",
    "Num": 12,
    "CC": "SB",
    "alpha3": "SLB",
    "ccTLD": ".SB",
    "IAC": "+677",
    "IDD": "00",
    "TimeZone": "UTC+11:00",
    "Currency": {
      "Currencies": "Solomon Islands dollar (SBD, $)"
    },
    "Language(s)": "English"
  },
  {
    "Entity": "Somalia",
    "Capital": "Mogadishu",
    "Num": 12,
    "CC": "SO",
    "alpha3": "SOM",
    "ccTLD": ".SO",
    "IAC": "+252",
    "IDD": "00",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Somali shilling (SOS, Sh)"
    },
    "Language(s)": "Somali (national)., Arabic"
  },
  {
    "Entity": "South Africa",
    "Capital": "Pretoria",
    "Num": 13,
    "CC": "ZA",
    "alpha3": "ZAF",
    "ccTLD": ".ZA",
    "IAC": "+27",
    "IDD": "09",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "South African rand (ZAR, R)"
    },
    "Language(s)": "Afrikaans, English, Southern Ndebele, Northern Sotho, Sotho, Swazi, Tsonga, Tswana, Venda, Xhosa, Zulu"
  },
  {
    "Entity": "South Korea",
    "Capital": "Seoul",
    "Num": 12,
    "CC": "KR",
    "alpha3": "KOR",
    "ccTLD": ".KR",
    "IAC": "+82",
    "IDD": "001",
    "TimeZone": "UTC+09:00",
    "Currency": {
      "Currencies": "South Korean won (KRW, ₩)"
    },
    "Language(s)": "Korean"
  },
  {
    "Entity": "Spain",
    "Capital": "Madrid",
    "Num": 9,
    "CC": "ES",
    "alpha3": "ESP",
    "ccTLD": ".ES",
    "IAC": "+34",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "Spanish (statewide), and some other languages in some territories."
  },
  {
    "Entity": "Sri Lanka",
    "Capital": "Sri Jayawardenapura Kotte",
    "Num": 13,
    "CC": "LK",
    "alpha3": "LKA",
    "ccTLD": ".LK",
    "IAC": "+94",
    "IDD": "00",
    "TimeZone": "UTC+05:30",
    "Currency": {
      "Currencies": "Sri Lankan rupee (LKR, Rs)"
    },
    "Language(s)": "Sinhala (national), Tamil"
  },
  {
    "Entity": "Sudan",
    "Capital": "Khartoum",
    "Num": 13,
    "CC": "SD",
    "alpha3": "SDN",
    "ccTLD": ".SD",
    "IAC": "+249",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Sudanese pound (SDG, £)"
    },
    "Language(s)": "Arabic, English"
  },
  {
    "Entity": "Suriname",
    "Capital": "Paramaribo",
    "Num": 13,
    "CC": "SR",
    "alpha3": "SUR",
    "ccTLD": ".SR",
    "IAC": "+597",
    "IDD": "00",
    "TimeZone": "UTC-03:30",
    "Currency": {
      "Currencies": "Surinamese dollar (SRD, $)"
    },
    "Language(s)": "Dutch"
  },
  {
    "Entity": "Swaziland",
    "Capital": "Mbabane",
    "Num": 13,
    "CC": "SZ",
    "alpha3": "SWZ",
    "ccTLD": ".SZ",
    "IAC": "+268",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Swazi lilangeni (SZL, L)"
    },
    "Language(s)": "English, Swazi"
  },
  {
    "Entity": "Sweden",
    "Capital": "Stockholm",
    "Num": 9,
    "CC": "SE",
    "alpha3": "SWE",
    "ccTLD": ".SE",
    "IAC": "+46",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Swedish krona (SEK, kr)"
    },
    "Language(s)": "Swedish, and some other languages like Finnish, Meänkieli, Romani in some territories."
  },
  {
    "Entity": "Switzerland",
    "Capital": "Bern",
    "Num": 9,
    "CC": "CH",
    "alpha3": "CHE",
    "ccTLD": ".CH",
    "IAC": "+41",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Swiss franc (CHF, Fr)"
    },
    "Language(s)": "German, French, Italian, Romansh"
  },
  {
    "Entity": "Syrian Arab Republic",
    "Capital": "Damascus",
    "Num": 12,
    "CC": "SY",
    "alpha3": "SYR",
    "ccTLD": ".SY",
    "IAC": "+963",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Syrian pound (SYP, £ or ل.س)"
    },
    "Language(s)": "Arabic"
  },
  {
    "Entity": "Taiwan",
    "Capital": "Taipei",
    "Num": 12,
    "CC": "TW",
    "alpha3": "TWN",
    "ccTLD": ".TW",
    "IAC": "+886",
    "IDD": "002",
    "TimeZone": "UTC+08:00",
    "Currency": {
      "Currencies": "New Taiwan dollar (TWD, $)"
    },
    "Language(s)": "Mandarin Chinese, Taiwanese (regional)"
  },
  {
    "Entity": "Tajikistan",
    "Capital": "Dushanbe",
    "Num": 12,
    "CC": "TJ",
    "alpha3": "TJK",
    "ccTLD": ".TJ",
    "IAC": "+992",
    "IDD": "8~10",
    "TimeZone": "UTC+06:00",
    "Currency": {
      "Currencies": "Tajikistani somoni (TJS, ? М)"
    },
    "Language(s)": "Tajik (national), Russian (for interethnic communication)"
  },
  {
    "Entity": "Tanzania",
    "Capital": "Dodoma",
    "Num": 12,
    "CC": "TZ",
    "alpha3": "TZA",
    "ccTLD": ".TZ",
    "IAC": "+255",
    "IDD": "00",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Tanzanian shilling (TZS, Sh)"
    },
    "Language(s)": "Swahili (national), English"
  },
  {
    "Entity": "Thailand",
    "Capital": "Bangkok",
    "Num": 13,
    "CC": "TH",
    "alpha3": "THA",
    "ccTLD": ".TH",
    "IAC": "+66",
    "IDD": "001",
    "TimeZone": "UTC+07:00",
    "Currency": {
      "Currencies": "Thai baht (THB, ฿)"
    },
    "Language(s)": "Thai"
  },
  {
    "Entity": "Togo",
    "Capital": "Lomé",
    "Num": 13,
    "CC": "TG",
    "alpha3": "TGO",
    "ccTLD": ".TG",
    "IAC": "+228",
    "IDD": "00",
    "TimeZone": "UTC",
    "Currency": {
      "Currencies": "West African CFA franc (XOF, Fr)"
    },
    "Language(s)": "French"
  },
  {
    "Entity": "Trinidad and Tobago",
    "Capital": "Port of Spain",
    "Num": 13,
    "CC": "TT",
    "alpha3": "TTO",
    "ccTLD": ".TT",
    "IAC": "+1 868",
    "IDD": "011",
    "TimeZone": "UTC-04:00",
    "Currency": {
      "Currencies": "Trinidad and Tobago dollar (TTD, $)"
    },
    "Language(s)": "English"
  },
  {
    "Entity": "Tunisia",
    "Capital": "Tunis",
    "Num": 13,
    "CC": "TN",
    "alpha3": "TUN",
    "ccTLD": ".TN",
    "IAC": "+216",
    "IDD": "00",
    "TimeZone": "UTC+01:00",
    "Currency": {
      "Currencies": "Tunisian dinar (TND, د.ت)"
    },
    "Language(s)": "Arabic (national), French"
  },
  {
    "Entity": "Turkey",
    "Capital": "Ankara",
    "Num": 10,
    "CC": "TR",
    "alpha3": "TUR",
    "ccTLD": ".TR",
    "IAC": "+90",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Turkish lira (TRY, TL)"
    },
    "Language(s)": "Turkish"
  },
  {
    "Entity": "Turkmenistan",
    "Capital": "Ashgabat",
    "Num": 12,
    "CC": "TM",
    "alpha3": "TKM",
    "ccTLD": ".TM",
    "IAC": "+993",
    "IDD": "8~10",
    "TimeZone": "UTC+05:00",
    "Currency": {
      "Currencies": "Turkmenistani manat (TMT, m)"
    },
    "Language(s)": "Turkmen (national), Russian (for interethnic communication)"
  },
  {
    "Entity": "Tuvalu",
    "Capital": "Funafuti",
    "Num": 12,
    "CC": "TV",
    "alpha3": "TUV",
    "ccTLD": ".TV",
    "IAC": "+688",
    "IDD": "00",
    "TimeZone": "UTC+12:00",
    "Currency": {
      "Currencies": "Australian dollar (AUD, $), Tuvaluan dollar (None, $)"
    },
    "Language(s)": "English, Tuvaluan (national)"
  },
  {
    "Entity": "Uganda",
    "Capital": "Kampala",
    "Num": 12,
    "CC": "UG",
    "alpha3": "UGA",
    "ccTLD": ".UG",
    "IAC": "+256",
    "IDD": "000",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Ugandan shilling (UGX, Sh)"
    },
    "Language(s)": "English, Swahili"
  },
  {
    "Entity": "Ukraine",
    "Capital": "Kiev",
    "Num": 9,
    "CC": "UA",
    "alpha3": "UKR",
    "ccTLD": ".UA",
    "IAC": "+380",
    "IDD": "8~10",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Ukrainian hryvnia (UAH, ₴)"
    },
    "Language(s)": "Ukrainian, Russian (regional), Crimean Tatar (regional)"
  },
  {
    "Entity": "United Arab Emirates",
    "Capital": "Abu Dhabi",
    "Num": 13,
    "CC": "AE",
    "alpha3": "ARE",
    "ccTLD": ".AE",
    "IAC": "+971",
    "IDD": "00",
    "TimeZone": "UTC+04:00",
    "Currency": {
      "Currencies": "United Arab Emirates dirham (AED, د.إ)"
    },
    "Language(s)": "Arabic"
  },
  {
    "Entity": "United Kingdom",
    "Capital": "London",
    "Num": 10,
    "CC": "GB",
    "alpha3": "GBR",
    "ccTLD": ".GB\n.UK",
    "IAC": "+44",
    "IDD": "00",
    "TimeZone": "UTC",
    "Currency": {
      "Currencies": "British pound (GBP, £)"
    },
    "Language(s)": "English, and some other languages in some territories."
  },
  {
    "Entity": "United States",
    "Capital": "Washington, D.C.",
    "Num": 10,
    "CC": "US",
    "alpha3": "USA",
    "ccTLD": ".US",
    "IAC": "+1",
    "IDD": "011",
    "TimeZone": "UTC-11:00 - UTC-05:00",
    "Currency": {
      "Currencies": "United States dollar (USD, $)"
    },
    "Language(s)": "English (de facto)"
  },
  {
    "Entity": "Uruguay",
    "Capital": "Montevideo",
    "Num": 12,
    "CC": "UY",
    "alpha3": "URY",
    "ccTLD": ".UY",
    "IAC": "+598",
    "IDD": "00",
    "TimeZone": "UTC-03:00",
    "Currency": {
      "Currencies": "Uruguayan peso (UYU, $)"
    },
    "Language(s)": "Spanish"
  },
  {
    "Entity": "Uzbekistan",
    "Capital": "Tashkent",
    "Num": 9,
    "CC": "UZ",
    "alpha3": "UZB",
    "ccTLD": ".UZ",
    "IAC": "+998",
    "IDD": "8~10",
    "TimeZone": "UTC+06:00",
    "Currency": {
      "Currencies": "Uzbekistani som (UZS, лв)"
    },
    "Language(s)": "Uzbek (national), Russian (for interethnic communication)"
  },
  {
    "Entity": "Vanuatu",
    "Capital": "Port Vila",
    "Num": 12,
    "CC": "VU",
    "alpha3": "VUT",
    "ccTLD": ".VU",
    "IAC": "+678",
    "IDD": "00",
    "TimeZone": "UTC+11:00",
    "Currency": {
      "Currencies": "Vanuatu vatu (VUV, Vt)"
    },
    "Language(s)": "Bislama (national), English, French"
  },
  {
    "Entity": "Vatican City",
    "Capital": "Vatican City",
    "Num": 10,
    "CC": "VA",
    "alpha3": "VAT",
    "ccTLD": ".VA",
    "IAC": "+39 06 698",
    "IDD": "00",
    "TimeZone": "UTC+01",
    "Currency": {
      "Currencies": "Euro (EUR, €)"
    },
    "Language(s)": "Italian (de facto)."
  },
  {
    "Entity": "Venezuela",
    "Capital": "Caracas",
    "Num": 10,
    "CC": "VE",
    "alpha3": "VEN",
    "ccTLD": ".VE",
    "IAC": "+58",
    "IDD": "00",
    "TimeZone": "UTC-04:00",
    "Currency": {
      "Currencies": "Venezuelan bolívar (VEF, Bs F)"
    },
    "Language(s)": "Spanish"
  },
  {
    "Entity": "Vietnam",
    "Capital": "Hanoi",
    "Num": 13,
    "CC": "VN",
    "alpha3": "VNM",
    "ccTLD": ".VN",
    "IAC": "+84",
    "IDD": "00",
    "TimeZone": "UTC+07:00",
    "Currency": {
      "Currencies": "Vietnamese đồng (VND, ₫)"
    },
    "Language(s)": "Vietnamese"
  },

  {
    "Entity": "Yemen",
    "Capital": "Sanaá",
    "Num": 13,
    "CC": "YE",
    "alpha3": "YEM",
    "ccTLD": ".YE",
    "IAC": "+967",
    "IDD": "00",
    "TimeZone": "UTC+03:00",
    "Currency": {
      "Currencies": "Yemeni rial (YER, ﷼)"
    },
    "Language(s)": "Arabic"
  },
  {
    "Entity": "Zambia",
    "Capital": "Lusaka",
    "Num": 13,
    "CC": "ZM",
    "alpha3": "ZMB",
    "ccTLD": ".ZM",
    "IAC": "+260",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Zambian kwacha (ZMK, ZK)"
    },
    "Language(s)": "English"
  },
  {
    "Entity": "Zimbabwe",
    "Capital": "Harare",
    "Num": 13,
    "CC": "ZW",
    "alpha3": "ZWE",
    "ccTLD": ".ZW",
    "IAC": "+263",
    "IDD": "00",
    "TimeZone": "UTC+02:00",
    "Currency": {
      "Currencies": "Botswana pula (BWP, P), British pound (GBP, £), Euro (EUR, €), South African rand (ZAR, R), United States dollar (USD, $), Zimbabwean dollar (ZWL, $)"
    },
    "Language(s)": "English, Shona, Northern Ndebele"
  }
];