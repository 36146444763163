import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CrmService } from 'src/app/core/service/crm.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { CHANNELS_LIST } from 'src/app/core/data/channels_json';
import { ClientsService } from 'src/app/core/service/clients.service';
import { countryData } from 'src/app/core/data/countryData';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { currencyJson } from 'src/app/core/data/currencyData';
import { onOnlyNumbers } from 'src/app/core/common/common-functions';
import { time_zone_json } from 'src/app/core/data/mobile_json';
import { ContactType } from 'src/app/core/data/crm_modal_json';

@Component({
  selector: 'app-custom-filters',
  templateUrl: './custom-filters.component.html',
  styleUrls: ['./custom-filters.component.scss']
})
export class CustomFiltersComponent implements OnInit {
  columnFilters!: FormGroup;
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public bankFilterCurrencyCtrl: FormControl = new FormControl();
  public filteredCurrencyBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public bankFilterTimezoneCtrl: FormControl = new FormControl();
  public filteredTimezoneBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onCurrencyDestroy = new Subject<void>();
  protected _onDestroy = new Subject<void>();
  protected _onTimezoneDestroy = new Subject<void>();
  private keydownListeners: { [key: number]: (event: KeyboardEvent) => void } = {};
  // customFiltersArray: any = [];
  currencyjson = currencyJson;
  staffMembers: any[] = [];
  contactListArray: any = [];
  companyListArray: any = [];
  product_categories_array: any = [];
  products_array: any = [];
  listOfTags: any = [];
  departmentsList: any = [];
  channels = CHANNELS_LIST;
  countryjson = countryData;
  submitted = false;
  clientArray: any = [];
  @Input() is_filter_for!: string;
  @Input() sortByOptions!: any;
  @Input() filters_custm: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() customFiltersArray!: any;
  @Input() filterType!: string;
  @Input() selectedSortOptions!: any;
  categoriesArray: any = [];
  appointmentsArray: any = [];
  timezonejson = time_zone_json;
  startingDate!: any;
  betweenFilterDates: any[] = [];
  endingDate!: any;
  customFieldsOptions: any[] = [];
  contact_type = ContactType;
  constructor(
    private service: CrmService,
    private settingsService: SettingsService,
    private setup_service: SetUpService,
    public ngbActiveModal: NgbActiveModal,
    private clientService: ClientsService,
    private fb: FormBuilder,
    private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.filteredBanks.next(this.countryjson.slice());
    this.filteredCurrencyBanks.next(this.currencyjson.slice());
    this.getFilters(this.is_filter_for);
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    this.filteredCurrencyBanks.next(this.currencyjson.slice());
    this.bankFilterCurrencyCtrl.valueChanges
      .pipe(takeUntil(this._onCurrencyDestroy))
      .subscribe(() => {
        this.filterCurrencyBanks();
      });
    this.filteredTimezoneBanks.next(this.timezonejson.slice());
    this.bankFilterTimezoneCtrl.valueChanges
      .pipe(takeUntil(this._onTimezoneDestroy))
      .subscribe(() => {
        this.filterTimezoneBanks();
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._onCurrencyDestroy.next();
    this._onCurrencyDestroy.complete();
  }

  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }
  protected filterCurrencyBanks() {
    if (!this.currencyjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCurrencyCtrl.value;
    if (!search) {
      this.filteredCurrencyBanks.next(this.currencyjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCurrencyBanks.next(
      this.currencyjson.filter(bank => (bank.currency.toLowerCase().indexOf(search) > -1) ||
        bank.abbreviation.toLowerCase().indexOf(search) > -1)
    );
  }
  protected filterTimezoneBanks() {
    if (!this.timezonejson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterTimezoneCtrl.value;
    if (!search) {
      this.filteredTimezoneBanks.next(this.timezonejson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    const filteredTimezones: any = this.timezonejson.map((element: any) => {
      const filteredCountries = element.countries.filter((bank: any) => bank.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
      return { ...element, countries: filteredCountries };
    }).filter((element: any) => element.countries.length > 0);
    // filter the banks
    this.filteredTimezoneBanks.next(filteredTimezones);
  }

  getFilters(filter_type: string) {
    this.betweenFilterDates = [];
    this.initilizeFiltersForm();
    if (this.filters_custm && Object.keys(this.filters_custm).length > 0) {
      this.filters_custm.map((ele: any, index: any) => {
        this.filtersJsonForm(ele);
        if (ele.operation === 'is_null') {
          this.updateFormValidations(index, 'disable');
        }
        // if (ele.is_data_type === 'DATE_RANGE' && ele.custom_field) {
        //   for (let i = 0; i <= index; i++) {
        //     this.betweenFilterDates.push({
        //       startDate: new Date(this.columnFilters.value.filter[index].data.split('&')[0]),
        //       endDate: new Date(this.columnFilters.value.filter[index].data.split('&')[1])
        //     });
        //   }
        // }
        // if (ele.operation === 'between') {
        //   for (let i = 0; i <= index; i++) {
        //     this.betweenFilterDates.push({
        //       startDate: new Date(this.columnFilters.value.filter[index].data.split(',')[0]),
        //       endDate: new Date(this.columnFilters.value.filter[index].data.split(',')[1])
        //     });
        //   }
        // }
        const obj: any = {};
        obj['id'] = ele.column;
        if (ele.custom_field) {
          const filteredList = this.customFiltersArray.find((elem: any) => elem.id === ele.column);
          filteredList && (ele['options'] = filteredList.options);
        }
        this.forCustomFieldStatus(ele, index, 'update');
        if (ele.operation === 'is_empty') {
          this.updateFormValidations(index, 'disable');
        }
      });
      if (this.filters_custm && this.filters_custm.length > 0) {
        for (let i = 0; i < this.filters_custm.length; i++) {
          const conditions = this.filters_custm[i].operation === 'between' || (this.filters_custm[i].is_data_type === 'DATE_RANGE' && this.filters_custm[i].custom_field);
          if ((this.filters_custm[i].is_data_type === 'DATE_RANGE' && this.filters_custm[i].custom_field)) {
            this.betweenFilterDates.push({
              startDate: conditions ? new Date(this.columnFilters.value.filter[i].data.split('&')[0]) : '',
              endDate: conditions ? new Date(this.columnFilters.value.filter[i].data.split('&')[1]) : ''
            });
          } else {
            this.betweenFilterDates.push({
              startDate: conditions ? new Date(this.columnFilters.value.filter[i].data.split(',')[0]) : '',
              endDate: conditions ? new Date(this.columnFilters.value.filter[i].data.split(',')[1]) : ''
            });
          }
        }
      }
    } else {
      if (this.filterType === 'filters') {
        this.filtersJsonForm();
      }
    }
    if (this.selectedSortOptions) this.columnFilters.get('sorting')?.patchValue(this.selectedSortOptions);
  }
  updateFormValidations(index: number, type: string) {
    if (type === 'disable') {
      this.filter_of_column.controls[index].get('data')?.patchValue('');
      this.filter_of_column.controls[index].get('data')?.clearValidators();
      this.filter_of_column.controls[index].get('data')?.markAsUntouched();
      this.filter_of_column.controls[index].get('data')?.updateValueAndValidity();
    } else {
      if (!(this.filter_of_column.controls[index].get('data')?.hasValidator(Validators.required))) {
        this.filter_of_column.controls[index].get('data')?.patchValue('');
        this.filter_of_column.controls[index].get('data')?.addValidators([Validators.required]);
        this.filter_of_column.controls[index].get('data')?.updateValueAndValidity();
      }
    }
  }

  initilizeFiltersForm() {
    this.columnFilters = this.fb.group({
      filter: new FormArray([]),
      sorting: this.fb.group({
        sort_by: new FormControl(''),
        sort_order: new FormControl('desc')  // Assuming a default value for sort_order
      })
    });
  }

  get filter_of_column() {
    return this.columnFilters.controls["filter"] as FormArray;
  }

  filtersJsonForm(data?: any) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const filter_form: any = new FormGroup({
      column: new FormControl(data?.column ? data?.column : '', Validators.required),
      data: new FormControl(data?.data ? data?.data : '', Validators.required),
      operation: new FormControl(data?.operation ? data?.operation : '', Validators.required),
      custom_field: new FormControl(data?.custom_field ? data?.custom_field : '', Validators.required),
      search: new FormControl(''),
      is_mandatory: new FormControl(data?.is_mandatory ? data.is_mandatory : ''),
      is_data_type: new FormControl(data?.is_data_type ? data.is_data_type : ''),
      timezone: new FormControl(timeZone)
    });
    if (this.filter_of_column.length < 3) {
      this.filter_of_column.push(filter_form);
    }
  }

  get f() {
    return this.columnFilters.controls;
  }

  deleteFilter(index: number) {
    this.betweenFilterDates.splice(index, 1);
    this.filter_of_column.removeAt(index);
  }

  filterFormSubmit() {
    this.submitted = true;
    if (this.columnFilters.valid) {
      if (this.filterType === 'sort') {
        this.passEntry.emit({ data: this.columnFilters.value, type: 'save', from: 'sort' });
        this.ngbActiveModal.dismiss();
      } else {
        this.passEntry.emit({ data: this.columnFilters.value, type: 'save', from: 'filters' });
        this.ngbActiveModal.dismiss();
      }
    }
  }

  forCustomFieldStatus(field: any, index: number, type?: string) {
    let column = '';
    if (type) {
      column = field.column;
    } else {
      column = field.id;
    }
    console.log(field);
    if ((field.data_type === 'NUMBER' || field.is_data_type === 'NUMBER')) {
      let allowed = false;
      this.attachingKeyDownEventToInput(index, 'remove');
      if (field.id === 'ccode') {
        allowed = true;
      }
      this.attachingKeyDownEventToInput(index, 'add', allowed);
    } else {
      this.attachingKeyDownEventToInput(index, 'remove');
    }

    this.filter_of_column.controls[index].patchValue({
      custom_field: field?.column ? field?.custom_field : field?.custom_input,
      is_mandatory: field?.is_mandatory
    });
    if (field.data_type || field?.is_data_type) {
      const element = this.customFieldsOptions[index];
      console.log(element);
      if (element) {
        this.customFieldsOptions[index] = field?.options;
      } else {
        this.customFieldsOptions.push(field?.options);
      }
      this.filter_of_column.controls[index].patchValue({
        is_data_type: field.data_type || field?.is_data_type
      });
    } else {
      this.filter_of_column.controls[index].patchValue({
        is_data_type: ''
      });
    }
    if (column === 'owner' || column === 'assignee' || column === 'staff_ids') {
      this.organisationMembersList();
    } else if (column === 'product' || column === 'product_name' || column === 'product_category') {
      this.getStoreId(column);
    } else if (column === 'tags') {
      if (this.is_filter_for === 'client' || this.is_filter_for === 'project') {
        this.getClientTags();
      } else {
        this.getTags();
      }
    } else if (column === 'assignee_department') {
      this.getDepartmentsList();
    } else if (column === 'scheduler' || column === 'scheduler_category') {
      this.getAppointmentData(column);
    } else if (column === 'contact') {
      this.getContactsDropdownList();
    } else if (column === 'company_id' || column === 'company') {
      this.getCompaniesDropdownList();
    } else if (column === 'contact_type') {
      this.getContactTypes();
    }
  }

  attachingKeyDownEventToInput(index: number, type: string, allowed = false) {
    const inputElement = this.elementRef.nativeElement.querySelector(`#customInput${index}`);

    if (type === 'add') {
      if (!this.keydownListeners[index]) {
        this.keydownListeners[index] = (event: KeyboardEvent) => {
          (!onOnlyNumbers(event, allowed)) && event.preventDefault();
        };
      }
      if (inputElement) {
        inputElement.addEventListener('keydown', this.keydownListeners[index]);
      }
    } else {
      if (inputElement && this.keydownListeners[index]) {
        inputElement.removeEventListener('keydown', this.keydownListeners[index]);
        delete this.keydownListeners[index]; // Optional: Clean up the listener reference
      }
    }
  }

  onChangeDropDown(index: number) {
    this.filter_of_column.controls[index].patchValue({ data: '', operation: '' });
    this.filter_of_column.controls[index].get('data')?.markAsUntouched();
    this.filter_of_column.controls[index].get('data')?.updateValueAndValidity();
    this.filter_of_column.controls[index].get('operation')?.markAsUntouched();
    this.filter_of_column.controls[index].get('operation')?.updateValueAndValidity();
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  onDateChange(event: any, type: string, index: number) {
    if (type === 'start') {
      this.startingDate = this.updateDateFormat(event, index, '');
    } else if (type === 'end') {
      this.endingDate = this.updateDateFormat(event, index, '');
    }
    if (this.startingDate && this.endingDate) {
      let dateRangeString;
      if (this.filter_of_column.controls[index].get('is_data_type')?.value === 'DATE_RANGE' && (this.filter_of_column.controls[index].get('custom_field')?.value === true)) {
        dateRangeString = `${this.startingDate}&${this.endingDate}`;
      } else {
        dateRangeString = `${this.startingDate},${this.endingDate}`;
      }
      this.filter_of_column.controls[index].patchValue({ data: dateRangeString });
    }
  }

  getStoreId(filter_type: any) {
    // this.setup_service.getStore().subscribe((resp: any) => {
    //   if (resp?.data?.id) {
    if (filter_type === 'product_category') {
      this.setup_service.getProductCategories("PRODUCT", filter_type).subscribe({
        next: (catgry_resp: any) => {
          this.product_categories_array = catgry_resp.data;
        }
      });
    } else {
      this.setup_service.getProduct("PRODUCT", filter_type).subscribe({
        next: (product_resp: any) => {
          this.products_array = product_resp.data;
        }
      });
    }
    //   }
    // });
  }

  getAppointmentData(filter_type: any) {
    if (filter_type === 'scheduler_category') {
      this.setup_service.getAllCategories().subscribe({
        next: (resp: any) => {
          this.categoriesArray = resp.data;
        }
      });
    } else if (filter_type === 'scheduler') {
      this.setup_service.getAllConsulations().subscribe({
        next: (resp: any) => {
          this.appointmentsArray = resp.data;
        }
      });

    }
  }
  getContactsDropdownList() {
    this.service.getContactsDropdownList().subscribe((resp: any) => {
      this.contactListArray = resp?.data;
    });
  }

  getContactById(id: string) {
    this.service.getContactById(id).subscribe((resp: any) => {
      this.contactListArray = resp?.data;
    });
  }

  getTags() {
    this.settingsService.getTags(this.is_filter_for).subscribe({
      next: (resp: any) => {
        this.listOfTags = resp.data;
      }
    });
  }

  getClientTags() {
    this.settingsService.getClientTags(this.is_filter_for).subscribe({
      next: (resp: any) => {
        this.listOfTags = resp.data;
      }
    });
  }

  getContactTypes() {
    this.service.getContactTypes().subscribe((resp: any) => {
      this.contact_type = resp.data;
    });
  }

  getDepartmentsList() {
    this.settingsService.getTeams(false).subscribe((resp: any) => {
      this.departmentsList = resp.data;
    });

  }

  searchTriggerConatct(change: any) {
    if (change.term.length >= 3) {
      this.service.getContactsBySearch(change.term).subscribe({
        next: (resp: any) => {
          this.contactListArray = resp.data;
        }
      });
    } else if (change.term.length < 1) {
      this.getContactsDropdownList();
    }
  }

  getCompaniesDropdownList() {
    this.service.getCompaniesDropdownList().subscribe({
      next: (resp: any) => {
        this.companyListArray = resp.data;
      }
    });
  }

  searchTriggerCompany(change: any) {
    if (change.term.length >= 3) {
      this.service.getCompaniesBySearch(change.term).subscribe({
        next: (resp: any) => {
          this.companyListArray = resp.data;
        }
      });
    } else if (change.term.length < 1) {
      this.getCompaniesDropdownList();
    }
  }

  searchTriggerClient(change: any) {
    if (change.term.length >= 3) {
      this.clientService.getClientsBySearch(change.term).subscribe({
        next: (resp: any) => {
          this.clientArray = resp.data;
        }
      });
    }
  }
  updateDateFormat(event: any, index: number, type?: string) {
    const inputDate = new Date(event.value);
    const year = inputDate.getFullYear();
    const month = ('0' + (inputDate.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ('0' + inputDate.getDate()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    this.filter_of_column.controls[index].patchValue({ data: formattedDate });
    if (type === 'date') {
      this.filter_of_column.controls[index].patchValue({ data: formattedDate });
      return;
    } else {
      return formattedDate;
    }
  }

  resetFilters() {
    if (this.filterType === 'sort') {
      this.columnFilters.get('sorting')?.reset();
      this.columnFilters.get('sorting')?.patchValue({ sort_by: '', sort_order: 'desc' });
      if (this.selectedSortOptions) this.passEntry.emit({ data: this.columnFilters.value, type: 'reset', from: 'sort' });
      this.selectedSortOptions = null;
    } else {
      const filterArray = this.columnFilters.get('filter') as FormArray;
      filterArray.clear();
      if (this.filters_custm) this.passEntry.emit({ data: this.columnFilters.value, type: 'reset', from: "filters" });
      this.filtersJsonForm();
      this.filters_custm = null;
    }

  }

}
