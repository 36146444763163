import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ComponentRef, ViewChild, ViewContainerRef } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { CONTACTSJSON } from 'src/app/core/data/contacts_json';
import { AuthService } from 'src/app/core/service/auth.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { CustomFiltersComponent } from '../../shared/custom-filters/custom-filters.component';
import { filterConstant } from 'src/app/core/data/filter-storing-data';
import { CustomFieldsComponent } from '../../settings/custom-fields/custom-fields.component';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss']
})
export class ContactsListComponent {
  public selection = new SelectionModel<any>(true, []);

  filtersLables = [
    {
      position: 1,
      lableName: 'Name',
      checked: true
    },
    {
      position: 2,
      lableName: 'Email',
      checked: true
    },
    {
      position: 3,
      lableName: 'Phone Number',
      checked: true
    },
    {
      position: 4,
      lableName: 'Company Name',
      checked: true
    },
    {
      position: 5,
      lableName: 'Owner',
      checked: true
    },
    {
      position: 6,
      lableName: 'Source',
      checked: true
    },
    {
      position: 7,
      lableName: 'Created On',
      checked: true
    }
    // {
    //   position: 8,
    //   lableName: 'Delete',
    //   checked: true
    // }
  ];
  currentPage = 1;
  page_size = 20;
  contactsObj: any = {};
  contactListArray: any[] = [];
  group_id: any;
  group_user: any;
  display_no_data = false;
  search_global = '';
  searchSubject: Subject<string> = new Subject<string>();
  tableColumnResponse: any;
  tableColumns: any;
  originalTableColumnLists: any;
  columnFilterSearch: any;
  tableColumnNoResFound = false;
  columnFieldSearch: any[] = [];
  featuresData: any;
  operation = 'CREATE';
  enableImportBtn = false;
  selectedFile: any;
  stepper = 1;
  imported_id: any;
  grouplistArray: any;
  bulkimport_addtoList = true;
  contactsJson = CONTACTSJSON[0];
  filters_modal: any;
  column_filters: any[] = [];
  selectedSortOptions: any;
  sortByDropDown = [
    {
      label: 'Contact Name',
      key: 'first_name'

    },
    {
      label: 'Company Name',
      key: 'company'

    },
    {
      label: 'Email',
      key: 'email'

    },
    {
      label: 'Created On',
      key: 'created_at'

    }
  ];
  customFiltersArray: any[]=[];
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef, static: true }) container!: ViewContainerRef;
  private customFieldsComponentRef!: ComponentRef<CustomFieldsComponent>;
  loggedInUser: any;

  constructor(private service: CrmService, private route: ActivatedRoute, private authService: AuthService,
    private router: Router, private modalService: NgbModal) {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.route.queryParams.subscribe((params) => {
      this.group_id = params['groupid'];
      this.group_user = params['user'];
      this.getContactsBasedonGroupId();
      this.getColoumsList();
      this.getAllContactFields();
      this.getFeatues();
    });
    this.searchSubject.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((searchTerm: string) => {
      if (this.selectedSortOptions || this.column_filters.length > 0) {
        this.selectedSortOptions = null;
        this.column_filters = [];
      }
      this.getContactsBasedonGroupId(searchTerm);
    });
  }

  getContactsBasedonGroupId(searchTerm?: any) {
    this.service.getContactsBasedonGroupId(this.group_id, this.currentPage, this.page_size, searchTerm).subscribe((result: any) => {
      this.contactsObj = result;
      this.contactListArray = result.data;
      if (this.contactListArray.length === 0) {
        this.display_no_data = true;
      } else {
        this.display_no_data = false;
      }
    });
  }

  page(e: PageEvent) {
    if (e.pageIndex > e.previousPageIndex!) {
      this.currentPage = e.pageIndex + 1;
    } else if (e.pageIndex < e.previousPageIndex!) {
      this.currentPage -= 1;
    } else {
      this.currentPage = 1;
    }
    this.getContactsBasedonGroupId();
  }

  getColoumsList() {
    this.service.getTableColoums('contact-list',this.group_id).subscribe((resp: any) => {
      this.tableColumnResponse = resp.data;
      this.tableColumns = resp.data.customized_fields;
      this.originalTableColumnLists = JSON.parse(JSON.stringify(this.tableColumns));
    });
  }

  onColumnFilterSearch(event: any) {
    if (this.columnFilterSearch.length > 1) {
      const searchResult: any[] = [];
      this.tableColumns = this.originalTableColumnLists.filter((item: any) => (item.field_name.split('_').join(" ").toLocaleLowerCase()).includes(this.columnFilterSearch.toLocaleLowerCase()));
      if (this.tableColumns && this.tableColumns.length > 0) {
        this.tableColumnNoResFound = false;
        this.columnFieldSearch = [];

        searchResult.forEach((elem: any) => {
          Object.assign(this.columnFieldSearch, elem);
        });
      } else {
        this.tableColumnNoResFound = true;
        this.columnFieldSearch = [];
        this.tableColumns = [];
      }
      // Object.assign(this.tableColumns,this.columnFieldSearch);
      // this.tableColumns = JSON.parse(JSON.stringify(this.columnFieldSearch));
    } else {
      this.tableColumns = JSON.parse(JSON.stringify(this.originalTableColumnLists));
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.tableColumns, event.previousIndex, event.currentIndex);
    moveItemInArray(this.originalTableColumnLists, event.previousIndex, event.currentIndex);
  }

  onUpdateTableColumns() {
    const payload = JSON.parse(JSON.stringify(this.tableColumnResponse));
    Object.assign(payload.customized_fields, this.originalTableColumnLists);
    this.service.updateTableColumn(payload).subscribe();
  }

  selectFilterLable(event: any, item: any, index: number) {
    const obj = JSON.parse(JSON.stringify(item));
    obj.status = event.checked;
    this.tableColumns[index] = obj;
    this.originalTableColumnLists[index] = obj;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.contactListArray.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.contactListArray.forEach((row: any) => {
      this.selection.select(row);
    });
  }

  deleteFromList() {
    const selectedContacts: any[] = [];
    this.selection.selected.filter((item: any) => {
      selectedContacts.push(item.id);
    });
    this.deleteContact(selectedContacts);
  }

  deleteContact(selectedContacts?: any) {
    const body = {
      group_id: this.group_id,
      contacts: selectedContacts
    };
    this.service.deleteContactFromGroup(body).subscribe(() => {
      this.getContactsBasedonGroupId();
    });
  }

  toggleVisibility(e: any) {
    if (e.target.checked === true) {
      this.bulkimport_addtoList = true;
    } else {
      this.bulkimport_addtoList = false;
    }
  }

  getList() {
    this.service.getGroupList().subscribe((resp: any) => {
      this.grouplistArray = resp.data;
    });
  }

  downloadSampleFile() {
    this.service.downloadSample('Contacts');
  }

  onFileChange(event: any) {
    this.enableImportBtn = true;
    this.selectedFile = event.target.files[0];
  }

  importContacts() {
    const formData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);
    // formData.append('group_name', this.group_user || "");
    this.service.postCSVFile(formData).subscribe((resp: any) => {
      this.stepper = 2;
      this.imported_id = resp.data.id;
    });
  }

  fieldsMapping() {
    this.service.updateCSVFile({ id: this.imported_id, operation: this.operation, type: 'contact', is_active: true }).subscribe(() => {
      this.modalService.dismissAll('cross click');
      this.router.navigate(['/crm/import'], { queryParams: { module: 'contact', id: this.imported_id, group_id: this.group_id,group_user: this.group_user } });
    });
  }

  importEexportModalDialog(importExportModel: any) {
    this.modalService.open(importExportModel, { size: 'md', backdrop: 'static', scrollable: true });
  }

  closeImportModal() {
    this.modalService.dismissAll('cross click');
    this.stepper = 1;
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
    });
  }

  getRolePermissions(item: any, permission: any) {
    return this.authService.rolefeatureShow(item, permission);
  }

  getAllContactFields() {
    this.customFiltersArray = [];
    this.service.getContactsFileds().subscribe({
      next: (resp: any) => {
        for (const [key, value] of Object.entries(resp.data)) {
          let formatted_obj: any = {};
          let replaced_text: any;
          if (key === 'custom_contact_templates') {
            const custom_fields_list: any = value;
            custom_fields_list.forEach((element: any) => {
              formatted_obj = {};
              formatted_obj['id'] = element.id;
              formatted_obj['display_label'] = element.label;
              formatted_obj['custom_input'] = true;
              formatted_obj['data_type'] = element.data_type;
              formatted_obj['is_mandatory'] = element.is_mandatory;
              formatted_obj['options'] = element.options;
              if (element?.on_form) {
                this.customFiltersArray.push(formatted_obj);
              }
            });
          } else if (key === 'id' || key === 'organisation' || key === 'contact_fields_order' || key === 'display_picture') {
            console.log("");

          } else {
            const system_fields_value: any = value;
            if (key.includes('_')) {
              replaced_text = key.replace('_', " ");
            } else {
              replaced_text = key;
            }
            formatted_obj['id'] = key;
            formatted_obj['display_label'] = replaced_text;
            formatted_obj['custom_input'] = false;
            formatted_obj['data_type'] = system_fields_value.data_type;
            formatted_obj['is_mandatory'] = system_fields_value.is_mandatory;
            if (system_fields_value?.on_form) {
              this.customFiltersArray.push(formatted_obj);
            }
          }
        }
        const created_at = {
          id: 'created_at',
          is_mandatory: true,
          display_label: 'Created on',
          custom_input: false,
          data_type: 'DATE_TIME'
        };
        const updated_at = {
          id: 'updated_at',
          is_mandatory: true,
          display_label: 'Updated on',
          custom_input: false,
          data_type: 'DATE_TIME'
        };
        this.customFiltersArray.push(created_at);
        this.customFiltersArray.push(updated_at);
      }
    });
  }

  openFiltersModal(type: string, apiCall?: any) {

    if (type === 'sort') {
      this.filters_modal = this.modalService.open(CustomFiltersComponent, { size: 'md', centered: true });
    } else {
      this.filters_modal = this.modalService.open(CustomFiltersComponent, { size: 'lg', scrollable: true, centered: true });
    }
    this.filters_modal.componentInstance.filters_custm = this.column_filters;
    this.filters_modal.componentInstance.filterType = type;
    if (this.selectedSortOptions) {
      this.filters_modal.componentInstance.selectedSortOptions = this.selectedSortOptions;
    }
    this.filters_modal.componentInstance.sortByOptions = this.sortByDropDown;
    this.filters_modal.componentInstance.customFiltersArray = this.customFiltersArray;
    this.filters_modal.componentInstance.is_filter_for = 'contact';
    this.filters_modal.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry) {
        this.search_global = '';
        if (receivedEntry.from === 'sort') {
          if (receivedEntry.type === 'save') {
            this.selectedSortOptions = receivedEntry.data.sorting;
          } else {
            this.selectedSortOptions = null;
          }
        } else {
          if (receivedEntry.type === 'save') {
            this.column_filters = receivedEntry.data.filter;
          } else {
            this.column_filters = [];
          }
        }
        this.updateFilterState();
        const payload = {
          filter: receivedEntry.data.filter,
          sort_by: receivedEntry.data?.sorting?.sort_by,
          sort_order: receivedEntry.data?.sorting?.sort_order,
          group_id: this.group_id
        };
        // this.pageIndex = 0;
        this.currentPage = 1;
        this.filterFormSubmit(payload, 1);
      }
    });
  }

  updateFilterState() {
    filterConstant.filterInfoData = {
      mainFilter: this.column_filters,
      sortValue: this.selectedSortOptions,
      searchKey: this.search_global,
      page_name: 'contact',
      currentPageIndex: this.currentPage
    };
  }

  filterFormSubmit(filters_data: any, page: number) {
    this.service.getContactCustomFilteredData(filters_data, page ? page : 1, 'True').subscribe({
      next: (resp: any) => {
        this.contactsObj = resp;
        this.contactListArray = resp.data;
        // this.usersAccess(this.contactListArray);
        this.masterToggle();
        if (this.contactListArray.length === 0) {
          this.display_no_data = true;
        } else {
          this.display_no_data = false;
        }
      },
      error: () => {
        this.selectedSortOptions = null;
        this.column_filters = [];
        this.search_global = '';
        this.getContactsBasedonGroupId();
      }
    });
  }

  clearCustomFilter() {
    this.column_filters = [];
    this.selectedSortOptions = null;
    this.updateFilterState();
    this.getContactsBasedonGroupId();
  }

  openCustomFieldModal() {
    // Dynamically create the YComponent
    if(this.container) this.container.clear();
    const yComponentFactory = this.container.createComponent(CustomFieldsComponent);
    this.customFieldsComponentRef = yComponentFactory;

    // Call the modal open function in YComponent
    const body ={
      type: 'Contact',
      status: false
    };
    setTimeout(() => {
      this.customFieldsComponentRef.instance.openCustomFieldModal(body);
    }, 1000);

    this.customFieldsComponentRef.instance.closeModalEvent.subscribe(() => {
      this.getColoumsList();
    });
  }
}
