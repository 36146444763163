import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';
import * as XLSX from 'xlsx';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrmService {

  public create_pipline = new BehaviorSubject<any>('');
  create_new_pipline = this.create_pipline.asObservable();
  getCompaniesList = new BehaviorSubject<any>(null);
  registeredEmailId = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) { }
  //leads
  getAllLeads(page: number, lead_type: any, filter_param?: string, filter?: any) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    return this.http.get(`${environment.urlPrefix}${constant.leads_crm}?type=${lead_type}&page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}&offset=20`);
  }
  getLeadsBycontactId(contact_id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.leads_crm}?contact=${contact_id}&type=PIPELINE&status=false`);
  }
  getFilesById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.files}${id}/`);
  }

  getAllLeadswithoutPagination(lead_type: any) {
    return this.http.get(`${environment.urlPrefix}${constant.leads_crm}?type=${lead_type}`);
  }
  getAllLeadForExport() {
    return this.http.get(`${environment.urlPrefix}${constant.exportLeads}`);
  }
  getLeadById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.leads_crm}?id=${id}`);
  }
  createLead(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.leads_crm}`, body);
  }

  duplicateContactList(payload: any) {
    return this.http.post(`${environment.urlPrefix}${constant.duplicate_contact_list}`, payload);
  }
  updateLead(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.leads_crm}`, body);
  }
  deleteLead(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.leads_crm}?id=${id}`);
  }
  getCustomLeadFields() {
    return this.http.get(`${environment.urlPrefix}${constant.custom_lead_fields_crm}`);
  }
  getLeadsCustomFilteredData(body: any, page: number, lead_type: string) {
    return this.http.post(`${environment.urlPrefix}${constant.custom_filters_leads}?page=${page}&type=${lead_type}&offset=20`, body);
  }
  getLeadsBySearch(name: string, page?: number, lead_type?: string) {
    if (page) {
      return this.http.get(`${environment.urlPrefix}${constant.leads_crm}?search=${name}&type=${lead_type}&page=${page}&offset=20`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.leads_crm}?search=${name}`);
    }
  }
  //contacts
  getAllContacts(page: number, filter_param?: string, filter?: string, company_id?: string, page_size?: number, is_active?: any) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    company_id = company_id ? `&company_id=${company_id}` : '';
    is_active = (is_active !== undefined && is_active !== null) ? `${is_active}` : null;
    return this.http.get(`${environment.urlPrefix}${constant.contacts_crm}?page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}${company_id ? company_id : ''}&is_active=${is_active !== null ? is_active : 'True'}&offset=${page_size || 20}`);
  }

  getInactiveContacts(page: number, page_size: number, company_id?: string) {
    company_id = company_id ? `&company_id=${company_id}` : '';
    return this.http.get(`${environment.urlPrefix}${constant.contacts_crm}?page=${page}&offset=${page_size || 20}&is_active=False${company_id ? company_id : ''}`);
  }

  getContactById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.contacts_crm}?id=${id}`);
  }
  getContactsDropdownList() {
    return this.http.get(`${environment.urlPrefix}${constant.contacts_crm}`);
  }
  getAllContactsForExport() {
    return this.http.get(`${environment.urlPrefix}${constant.exportContacts}`);
  }
  createContact(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.contacts_crm}`, body);
  }
  updateContact(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.contacts_crm}`, body);
  }
  deleteContact(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.contacts_crm}?id=${id}`);
  }
  getCustomContactFields() {
    return this.http.get(`${environment.urlPrefix}${constant.custom_contact_fields_crm}`);
  }
  getContactsBySearch(name: string, page?: number, is_active?: any) {
    if (page) {
      return this.http.get(`${environment.urlPrefix}${constant.contacts_crm}?search=${name}&is_active=${is_active || 'True'}&page=${page}&offset=20`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.contacts_crm}?search=${name}`);
    }
  }
  getContactsBySearchDropDown(type?: string, data?: string) {
    if (data) {
      let search = '';
      if (type === 'name') {
        search = `name=${data}`;
      } else if (type === 'email') {
        search = `email=${data}`;
      } else if (type === 'phone_number') {
        search = `phone_number=${data}`;
      }
      return this.http.get(`${environment.urlPrefix}${constant.contact_search}?${search}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.contact_search}`);
    }
  }
  getContactsFileds() {
    return this.http.get(`${environment.urlPrefix}${constant.contact_fileds}`);
  }
  getContactCustomFilteredData(body: any, page: number, is_active: any) {
    return this.http.post(`${environment.urlPrefix}${constant.custom_filters_contacts}?page=${page}&is_active=${is_active}&offset=20`, body);
  }
  InactivatetheContact(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.contact_bulk_inactive}`, body);
  }
  getContactTypes(){
    return this.http.get(`${environment.urlPrefix}${constant.contact_type}`);
  }
  getChatBotVariables(contact_id: any) {
    return this.http.get(`${environment.messageUrl}${constant.chat_bot_variables}${contact_id}/`);
  }
  getTableColoums(type: string,id?: string) {
    let filter: any='';
    if(id) filter = `&source_id=${id}`;
    return this.http.get(`${environment.urlPrefix}${constant.table_coloums}?type=${type}${filter}`);
  }

  updateTableColumn(paylaod: any) {
    return this.http.put(`${environment.urlPrefix}${constant.table_coloums}`, paylaod);
  }

  getContactlistBasedOnContactId(contact_id: string){
    return this.http.get(`${environment.urlPrefix}${constant.contact_list}?contact_id=${contact_id}`);
  }

  getContactOrders(contact_id: string){
    return this.http.get(`${environment.urlPrefix}${constant.contact_orders}${contact_id}/`);
  }

  getAptsForContactId(contact_id: string){
    return this.http.get(`${environment.urlPrefix}${constant.contact_apts}?contact_id=${contact_id}`);
  }

  getCardsForContactId(contact_id: string){
    return this.http.get(`${environment.urlPrefix}${constant.contact_pipeline_cards}?contact_id=${contact_id}`);
  }

  getCalendarMettingsForContactId(contact_id: string){
    return this.http.get(`${environment.urlPrefix}${constant.contact_calendars_bookings}?contact_id=${contact_id}`);
  }

  //companies
  getAllCompanies(page: number, filter_param?: string, filter?: string) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    return this.http.get(`${environment.urlPrefix}${constant.companies_crm}?page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}&offset=20`);
  }
  getCompanyById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.companies_crm}?id=${id}`);
  }
  getCompaniesDropdownList() {
    return this.http.get(`${environment.urlPrefix}${constant.companies_crm}`);
  }
  // getCompaniesDropdownList() {
  //   if (this.getCompaniesList.getValue() !== null) {  // (&& refreshType !== "force")
  //     return this.getCompaniesList.asObservable();
  //   } else {
  //     this.http.get(`${environment.urlPrefix}${constant.companies_crm}`).subscribe((data) => {
  //       this.getCompaniesList.next(data);
  //     }, err => {
  //       this.getCompaniesList.next(err);
  //     });
  //     return this.getCompaniesList.asObservable();
  //   }
  // }
  createCompany(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.companies_crm}`, body);
  }
  updateCompany(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.companies_crm}`, body);
  }
  deleteCompany(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.companies_crm}?id=${id}`);
  }
  getCompaniesBySearch(name: string, page?: number) {
    if (page) {
      return this.http.get(`${environment.urlPrefix}${constant.companies_crm}?search=${name}&page=${page}&offset=20`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.companies_crm}?search=${name}`);
    }

  }
  getAllCompaniesForExport() {
    return this.http.get(`${environment.urlPrefix}${constant.exportCompanies}`);
  }
  getCompanyCustomFilteredData(body: any, page: number) {
    return this.http.post(`${environment.urlPrefix}${constant.custom_filters_company}?page=${page}&offset=20`, body);
  }
  //pipelines
  createdNewPipelines(type: string) {
    this.create_pipline.next(type);
  }

  getPipelineCustomFilteredData(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.custom_filters_pipeline}`, body);
  }

  getPipelines(type?: string) {
    let filter ='';
    if(type) filter = `?flow_type=${type}`;//get only order pipelines
    return this.http.get(`${environment.urlPrefix}${constant.pipelines}${filter}`);
  }

  getPiplineById(pipelineId: string) {
    return this.http.get(`${environment.urlPrefix}${constant.pipelines}?id=${pipelineId}`);
  }

  postPipelines(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.pipelines}`, body);
  }

  putPipelines(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.pipelines}`, body);
  }

  deletePipeline(pipelineId: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.pipelines}?id=${pipelineId}`);
  }

  duplicatePipeline(body: string) {
    return this.http.post(`${environment.urlPrefix}${constant.duplicate_pipelines}`, body);
  }

  getExcludeOrderPipleines(){
    return this.http.get(`${environment.urlPrefix}${constant.pipelines}?exclude_order=ORDER`);
  }

  getStaffInfoOfOderPipeline(stageId: string){
    return this.http.get(`${environment.urlPrefix}${constant.order_pipeline_staff_info}?stage=${stageId}`);
  }

  getStageByPipelineId(pipelineId: any) {
    return this.http.get(`${environment.urlPrefix}${constant.stages}?pipeline=${pipelineId}`);
  }

  postSingleStage(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.stages}`, body);
  }

  putSingleStage(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.stages}`, body);
  }

  deleteStage(stageId: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.stages}?id=${stageId}`);
  }

  getStageById(stageId: string) {
    return this.http.get(`${environment.urlPrefix}${constant.stages}?id=${stageId}`);
  }

  blukStageCreation(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.bulkstages}`, body);
  }

  getCards(stageId: string,page?: number,filter?: any) {
    return this.http.get(`${environment.urlPrefix}${constant.cards}?stage=${stageId}&page=${page || 1}&offset=20${filter ? `&${filter}` : ''}`);
  }

  getCardsById(cardId: string,newlabel?: boolean) {
    const queryParams = newlabel ? `&is_new=${newlabel ? 'False' : 'False'}` : '';
    return this.http.get(`${environment.urlPrefix}${constant.cards}?id=${cardId}${queryParams}`);
  }

  getCardsByPipelineId(pipelineId: string,page: number,filter?: any) {
    return this.http.get(`${environment.urlPrefix}${constant.cards_basedon_piplineId}?pipeline=${pipelineId}&page=${page || 1}&offset=20${filter ? `&${filter}` : ''}`);
  }

  updateCard(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.cards}`, body);
  }

  deleteCard(cardId: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.cards}?id=${cardId}`);
  }

  createBulkCard(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.bulkCards}`, body);
  }

  PutTemplateCard(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.templatesCard}`, body);
  }

  getTemplateCard(pipelineId: string) {
    return this.http.get(`${environment.urlPrefix}${constant.templatesCard}?pipeline=${pipelineId}`);
  }

  customFieldCreation(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.templatesFields}`, body);
  }

  customFieldUpdate(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.templatesFields}`, body);
  }

  getFieldsByTemaplateCardId(templateId: string) {
    return this.http.get(`${environment.urlPrefix}${constant.templatesFields}?template_card=${templateId}`);
  }

  deleteCustomField(fieldId: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.templatesFields}?id=${fieldId}`);
  }

  getFieldsbyCardId(cardId: string) {
    return this.http.get(`${environment.urlPrefix}${constant.card_fields}?pipeline_card=${cardId}`);
  }

  UpdateField(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.card_fields}`, body);
  }

  pipelineSettings(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.pipeline_settings}`, body);
  }

  getStaffInfoBasedOnIds(staff_ids: any){
    const user_details = JSON.parse(sessionStorage.getItem('currentUser')!);
    return this.http.post(`${environment.urlPrefix}${constant.staff_info_ids}`,{staffs: staff_ids,organisation: user_details.data.organisation});
  }

  getOrdersForOrderPIpelins(store_order_id: string){
    return this.http.get(`${environment.urlPrefix}${constant.orders_bys}${store_order_id}`);
  }

  //templates
  getTemplates() {
    return this.http.get(`${environment.urlPrefix}${constant.templates}`);
  }

  getTemplatesDetailsById(templateId: string) {
    return this.http.get(`${environment.urlPrefix}${constant.templates}?id=${templateId}`);
  }

  createPipelineWithTemplate(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.template}`, body);
  }

  importCompanies(formData: FormData) {
    return this.http.post(`${environment.urlPrefix}${constant.importCompanies}`, formData);
  }

  jsonToCsv(json: any, fileName: string) {
    const sheet = XLSX.utils.json_to_sheet(json);
    const book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, sheet, 'Sheet1');
    XLSX.writeFile(book, `${fileName}.csv`);
  }

  exportCRMservice(payload: any) {
    return this.http.post(`${environment.urlPrefix}${constant.CSV_CRM}`, payload);
  }
  getCRMCSV(csv_type: string, current_page?: number) {
    return this.http.get(`${environment.urlPrefix}${constant.CSV_CRM}?csv_type=${csv_type}&page=${current_page || 1}`);
  }
  exportCLIENTservice(payload: any) {
    return this.http.post(`${environment.urlPrefix}${constant.CSV_CLIENT}`, payload);
  }
  getCLIENTCSV(csv_type: string, current_page?: number) {
    return this.http.get(`${environment.urlPrefix}${constant.CSV_CLIENT}?csv_type=${csv_type}&page=${current_page || 1}`);
  }
  getCRMCSVRefresh(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.CSV_CRM_REFRESH}${id}/`);
  }
  getCLIENTCSVRefresh(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.CSV_CRM_REFRESH}${id}`);
  }

  downloadSample(module: string) {
    let fileUrl = "";

    if (/^(companies)$/.test(module.toLowerCase())) {//these files are exist in assets/files/
      // fileUrl = environment.preSendUrl + 'brexa_assets/6950523e-6d2e-4bf3-9626-7e3fc6faf1f4/79ab7776-04b5-447d-80da-a5a51cede0c9/0e0dc6c1-56f7-4f0b-ac7f-215db4ce27d7-1718954585.287069.csv';
      fileUrl = 'https://cdn.brexa.ai/CompaniesSampleUploadData.csv';
    }
    if (/^(contacts)$/.test(module.toLowerCase())) {
      // fileUrl = environment.preSendUrl + 'brexa_assets/6950523e-6d2e-4bf3-9626-7e3fc6faf1f4/79ab7776-04b5-447d-80da-a5a51cede0c9/9350120f-9d6b-49c8-9e8c-0a2ac9403016-1718954135.8512027.csv';
      fileUrl = 'https://cdn.brexa.ai/Contacts_Sample_Data.csv';
    } else if ((/^(leads)$/.test(module.toLowerCase())) || (/^(deal)$/.test(module.toLowerCase()))) {
      // fileUrl = environment.preSendUrl + 'brexa_assets/6950523e-6d2e-4bf3-9626-7e3fc6faf1f4/79ab7776-04b5-447d-80da-a5a51cede0c9/401e59b4-68cc-44c2-9217-100bffa990a2-1718954674.6231725.csv';
      fileUrl = 'https://cdn.brexa.ai/leads_Sample_Data.csv';
    }

    const fileName = `${module}_Sample_Data.csv`;

    this.http.get(fileUrl, { responseType: 'blob' })
      .subscribe((blob: Blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
      });
  }

  //Add contcats to a group
  getGroupList() {
    return this.http.get(`${environment.urlPrefix}${constant.contact_group_list}`);
  }

  postGroup(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.contact_list_from_group_name}`, body);
  }

  updateContactsToGroup(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.contact_list_from_group}`, body);
  }

  deleteGroup(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.contact_list_from_group_details}${id}/`);
  }

  getContactsBasedonGroupId(group_id: string, page: number, offset: number, search?: any) {
    if (search) {
      return this.http.get(`${environment.urlPrefix}${constant.get_contacts_list_under_groupId}${group_id}/?page=${page}&offset=${offset}&search=${search}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.get_contacts_list_under_groupId}${group_id}/?page=${page}&offset=${offset}`);
    }
  }

  updateGroup(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.contact_list_from_group_details}${body.id}/`, body);
  }

  deleteContactFromGroup(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.delete_contacts_from_group}`, body);
  }

  getStaffForSource(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.staff_in_task}`, body);
  }

  //Google mail thread 
  getMails(body: any, type?: string) {
    if (type) {
      return this.http.get(`${environment.urlPrefix}${constant.googler_mails}?type=${type}&source_id=${body.source_id}&source_type=${body.source_type}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.googler_mails}?source_id=${body.source_id}&source_type=${body.source_type}`);
    }
  }

  getProjectDropdownList() {
    return this.http.get(`${environment.urlPrefix}${constant.client_projects}`);
  }

  sendMail(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.send_googler_mails}`, body);
  }

  //Communications Mails
  getCommunicationsMails(body: any) {
    return this.http.get(`${environment.urlPrefix}${constant.communication_creations}?source_id=${body.source_id}&source_type=${body.source_type}`);
  }

  sendCommunicationsMail(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.communication_creations}`, body);
  }
  deleteDraftMail(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.send_googler_mails}`, body);
  }

  aiGenerateMails(body: any) {
    return this.http.post(`${environment.aiassit}${constant.ai_email_generator}`, body);
  }

  //CSV file upload

  postCSVFile(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.csv_upload_file}`, body);
  }

  updateCSVFile(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.csv_upload_file}`, body);
  }

  getCSVFile(file_id: any) {
    return this.http.get(`${environment.urlPrefix}${constant.csv_upload_file}?id=${file_id}`);
  }

  mappingCSVFile(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.csv_file_mapping}`, body);
  }

  getCSVFiles(type?: any, current_page?: number, searchTerm?: string) {
    const search = searchTerm ? `&search=${searchTerm}` : '';
    if (type && searchTerm) {
      return this.http.get(`${environment.urlPrefix}${constant.csv_file_mapping}?type=${type}${search}`);
    } else if (type && !searchTerm) {
      return this.http.get(`${environment.urlPrefix}${constant.csv_file_mapping}?type=${type}&page=${current_page}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.csv_file_mapping}`);
    }
  }

  getConversationUnreadCount(id: string) {
    return this.http.get(`${environment.messageUrl}${constant.conversation_unread_count}${id}/unread`);
  }

  getLinkedInByCompanyName(company_name: string){
    return this.http.get(`${environment.urlPrefix}${constant.linkedin}?name=${company_name}`);
  }

  getFormData(id: string) { // formbuilder
    return this.http.get(`${environment.urlPrefix}${constant.form}${id}/`);
  }
}
