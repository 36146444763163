import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { InvoiceService } from 'src/app/core/service/invoice.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent {
  table_cols = [
    'Payment ID',
    'Invoice ID',
    'Contact Name',
    'Amount',
    // 'Created on',
    'Mode',
    'Status',
    'Source',
    'Related To',
    // 'Type',
    // 'Total Amount',
    // 'Payment Status',
    'Created On'
    // 'Actions'
  ];
  total_count!: number;
  paymentArray: any = [];
  currentPage = 0;
  pageEvent!: PageEvent;
  display_no_data = false;
  search_global: any;
  pageIndex = 0;
  searchSubject: Subject<string> = new Subject<string>();
  tableSkeletonLoader = true;
  constructor(private service: InvoiceService) {
    this.searchSubject.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((searchTerm: string) => {
      if (searchTerm.length >= 3) {
        this.searchPaymentsList();
      } else if (searchTerm.length < 1) {
        this.getPaymentTransactions(1);
      }
    });
  }

  ngOnInit(): void {
    this.getPaymentTransactions(1);
  }

  getPaymentTransactions(page: number) {
    if (page === 1) {
      this.currentPage = 1;
      this.pageIndex = 0;
    }
    this.tableSkeletonLoader = true;
    this.service.getPaymentTransactions(page).subscribe({
      next: (resp: any) => {
        // console.log(resp);
        this.tableSkeletonLoader = false;
        this.total_count = resp.count;
        this.paymentArray = resp.data;
        if (this.paymentArray.length === 0) {
          this.display_no_data = true;
        } else {
          this.display_no_data = false;
        }
      }
    });
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
    } else if (this.pageEvent.pageIndex === 0) {
      this.currentPage = 1;
      this.pageIndex = 0;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
    }
    if (this.search_global?.length >= 3) {
      this.searchPaymentsList();
    } else {
      this.getPaymentTransactions(this.currentPage);
    }

  }

  searchPaymentsList() {
    this.tableSkeletonLoader = true;
    this.service.getPaymentTransactionsOnSearch(this.search_global, this.currentPage).subscribe({
      next: (resp: any) => {
        this.tableSkeletonLoader = false;
        this.total_count = resp.count;
        this.paymentArray = resp.data;
        if (this.paymentArray.length === 0) {
          this.display_no_data = true;
        } else {
          this.display_no_data = false;
        }
      }
    });
  }

  search(name: any) {
    if (name.length >= 3) {
      this.search_global = name;
      this.searchPaymentsList();
    } else if (name.length < 1) {
      this.getPaymentTransactions(1);
    }
  }

}
