import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';

interface ItemValue {
  routing_key?: string;
  ccode?: string;
  id?: number;
  [key: string]: any; // Allow additional properties
}

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})

export class GlobalSearchComponent {
  searchSubject: Subject<string> = new Subject<string>();
  globalSearchData: { [key: string]: ItemValue[] } = {};
  search_global = '';

  constructor(public modalService: NgbModal, private authservice: AuthService, private router: Router) {
    this.searchSubject.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((searchTerm: string) => {
      if (searchTerm.length >= 3) {
        this.getSearchData(searchTerm);
      } else if(searchTerm.length === 0) {
        this.globalSearchData = {};
      }
    });
  }

  getSearchData(searchText: any) {
    this.authservice.getGlobalSearchData(searchText).subscribe((resp: any) => {
      this.globalSearchData = resp.data;
      console.log(this.globalSearchData, "global search resp");

    },()=>{
      this.globalSearchData = {};
    });
  }

  navigation(routeLink: any) {
    console.log('link', routeLink);
    if (routeLink) {
      this.modalService.dismissAll();
      this.router.navigateByUrl(routeLink);
    }
  }
  isGlobalSearchDataEmpty(): boolean {
    return Object.keys(this.globalSearchData).length === 0;
  }
}
