import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ErrorLoadImage } from 'src/app/core/common/common-functions';
import { currencyJson } from 'src/app/core/data/currencyData';
import { SETTINGSFORM } from 'src/app/core/data/form_builder_json';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings-model',
  templateUrl: './settings-model.component.html',
  styleUrls: ['./settings-model.component.scss']
})
export class SettingsModelComponent implements OnInit {
  options = [
    {
      type: 'Text box',
      value: 'TEXT'
    },
    {
      type: 'Checkboxes',
      value: 'CHECK_BOX'
    },
    {
      type: 'Radio',
      value: 'RADIO_BUTTON'
    },
    {
      type: 'DropDown',
      value: 'SELECT_DROPDOWN'
    },
    {
      type: 'Upload',
      value: 'FILE'
    },
    {
      type: 'Text Area',
      value: 'TEXT_AREA'
    }
  ];
  showError = false;
  fieldoption!: FormGroup;
  checkboxesDataList: any = [];
  @Input() formItem: any;
  @Input() variableformFields: any;
  @Input() API_LIST: any;
  @Input() type: any;
  JSON: any = JSON;
  selectedFieldType!: string;
  serviceData = [
    {
      id: 1,
      name: 'Service'
    },
    {
      id: 2,
      name: 'Service2'
    },
    {
      id: 3,
      name: 'Service3'
    },
    {
      id: 4,
      name: 'Service4'
    }
  ];
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  settingsformJson = SETTINGSFORM[0];
  contactFields: any[] = [];
  selectedVariable: any;
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  presignedurl = environment.preSendUrl;
  currencyjson = currencyJson;
  multipleFiles: any[] = [];
  submitted = false;
  pipelineList: any;
  selected_pipeline_stages: any;

  constructor(public modelService: NgbModal, private crmService: CrmService,
    private awsUtilService: AwsUtilsService, private toastrService: ToastrService) { }

  ngOnInit() {
    this.fieldoption = new FormGroup({
      label: new FormControl('', Validators.required),
      place_holder: new FormControl(''),
      regex_validation: new FormControl(''),
      type: new FormControl('', Validators.required),
      original_label: new FormControl(''),
      placeholder: new FormControl(''),
      is_mandatory: new FormControl(),
      is_default: new FormControl(),
      options: new FormArray([]),
      variables: new FormArray([]),
      select_options: new FormControl(),
      variables_data: new FormControl(),
      data_validations: new FormGroup({
        min: new FormControl(),
        max: new FormControl(),
        disable_future: new FormControl(),
        disable_past: new FormControl(),
        type: new FormControl(),
        size: new FormControl()
      }),
      button_action: new FormArray([])
    });
    if (this.type === 'dynamic_app') {
      this.fieldoption.addControl('form_value', new FormControl(this.formItem ? this.formItem?.form_value : ''));
      this.fieldoption.addControl('show_on_card', new FormControl(this.formItem ? this.formItem?.show_on_card : ''));
      if (this.formItem.data_type === 'DATE_RANGE' || this.formItem.data_type === 'NUMBER_RANGE') {
        const split_value = this.formItem.data_type === 'DATE_RANGE' ? '&' : '-';
        this.fieldoption.addControl('start', new FormControl(this.formItem ? this.formItem?.form_value?.split(split_value)[0] : ""));
        this.fieldoption.addControl('end', new FormControl(this.formItem ? this.formItem?.form_value?.split(split_value)[1] : ""));
      } else if (this.formItem.data_type === 'PRICE') {
        this.fieldoption.addControl('currency', new FormControl(this.formItem ? this.formItem?.form_value.split(' ')[0] : ""));
        this.fieldoption.addControl('price', new FormControl(this.formItem ? this.formItem?.form_value.split(' ')[1] : ""));
      } else if (this.formItem.data_type === 'MULTIPLE_FILE') this.multipleFiles = this.formItem?.form_value || [];
      else if (this.formItem.data_type === 'BUTTON') {
        const body: any = new FormGroup({
          btn_type: new FormControl(this.formItem?.button_action.length > 0 ? this.formItem?.button_action[0]?.btn_type : ''),
          pipeline_id: new FormControl(this.formItem?.button_action.length > 0 ? this.formItem?.button_action[0]?.pipeline_id : ''),
          stage_id: new FormControl(this.formItem?.button_action.length > 0 ? this.formItem?.button_action[0]?.stage_id : ''),
          quantity: new FormControl(this.formItem?.button_action.length > 0 ? this.formItem?.button_action[0]?.quantity : false),
          min_quantity: new FormControl(this.formItem?.button_action.length > 0 ? this.formItem?.button_action[0]?.min_quantity : ''),
          max_quantity: new FormControl(this.formItem?.button_action.length > 0 ? this.formItem?.button_action[0]?.max_quantity : ''),
          billing_address: new FormControl(this.formItem?.button_action.length > 0 ? this.formItem?.button_action[0]?.billing_address : false),
          shipping_address: new FormControl(this.formItem?.button_action.length > 0 ? this.formItem?.button_action[0]?.shipping_address : false),
          payment: new FormControl(this.formItem?.button_action.length > 0 ? this.formItem?.button_action[0]?.payment : false)
        });
        this.btn_actions.push(body);
        if (this.formItem?.button_action[0]?.pipeline_id) {
          this.getPipelines(); this.getStagesBasedonPipeline();
        }
      }
      this.ckconfigQue = {
        height: 200,
        toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
        removeButtons: 'Subscript,Superscript'
      };
      // if (this.formItem.data_type !== 'HEADING' && this.formItem.data_type !== 'SUB_HEADING' && this.formItem.data_type !== 'BUTTON') {
      //   this.fieldoption.get('form_value')?.addValidators([Validators.required]);
      //   this.fieldoption.get('form_value')?.updateValueAndValidity();
      // }
    } else if (this.type === 'custom_store') {
      this.fieldoption.addControl('form_value', new FormControl(this.formItem ? this.formItem?.form_value : ''));
      this.fieldoption.addControl('show_on_card', new FormControl(this.formItem ? this.formItem?.show_on_card : ''));
      if (this.formItem.type === 'DATE_RANGE' || this.formItem.type === 'NUMBER_RANGE') {
        const split_value = this.formItem.type === 'DATE_RANGE' ? '&' : '-';
        this.fieldoption.addControl('start', new FormControl(this.formItem ? this.formItem?.form_value.split(split_value)[0] : ""));
        this.fieldoption.addControl('end', new FormControl(this.formItem ? this.formItem?.form_value.split(split_value)[1] : ""));
      } else if (this.formItem.type === 'PRICE') {
        this.fieldoption.addControl('currency', new FormControl(this.formItem ? this.formItem?.form_value.split(' ')[0] : ""));
        this.fieldoption.addControl('price', new FormControl(this.formItem ? this.formItem?.form_value.split(' ')[1] : ""));
      } else if (this.formItem.type === 'MULTIPLE_FILE') this.multipleFiles = this.formItem?.form_value || [];
      this.ckconfigQue = {
        height: 200,
        toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
        removeButtons: 'Subscript,Superscript'
      };
    }
    if (Array.isArray(this.formItem?.options)) this.formItem?.options?.forEach((element: any) => {
      this.addNew();
    });
    if (Array.isArray(this.formItem?.variables)) this.formItem?.variables?.forEach((element: any) => {
      this.addVariable(element);
    });

    this.fieldoption.patchValue({
      label: this.formItem.label, place_holder: this.formItem.place_holder, regex_validation: this.formItem.regex_validation, type: this.type === 'dynamic_app' ? this.formItem.data_type : this.formItem.type, original_label: this.formItem.original_label,
      is_mandatory: this.formItem.is_mandatory, is_default: this.formItem.is_default, select_options: this.formItem.options, options: this.formItem.options, variables_data: this.formItem.variables_data
    });
    this.selectedFieldType = this.formItem.key;
    const dataValidationsGroup = this.fieldoption.get('data_validations') as FormGroup;
    if (dataValidationsGroup) dataValidationsGroup.patchValue(this.formItem.data_validations);
    if (this.formItem.is_form_field) this.getContactsfileds();
  }

  get FormOpt() {
    return this.fieldoption.get('options') as FormArray;
  }

  get varables() {
    return this.fieldoption.get('variables') as FormArray;
  }
  get btn_actions() {
    return this.fieldoption.get('button_action') as FormArray;
  }

  newVariable(data?: any): FormGroup {
    return new FormGroup({
      field_type: new FormControl(data ? data.field_type : ''),
      module: new FormControl('contact'),
      original_label: new FormControl(data ? data.original_label : ''),
      label: new FormControl(data ? data.label : ''),
      type: new FormControl(data ? data.data_type : ''),
      key: new FormControl(data ? data.label : ''),
      options: new FormControl(data ? data.options : ''),
      custom_field_id: new FormControl(data ? data.id : null)
    });
  }

  addVariable(data?: any) {
    this.varables.push(this.newVariable(data));
  }

  addNew(isNew = false) {
    const arr = this.FormOpt.value;
    const lastElement = arr[arr.length - 1];
    if (isNew && lastElement?.trim() === "") {
      return;
    }
    this.FormOpt.push(new FormControl('', Validators.required));
    this.checkboxesDataList = this.FormOpt.value;
  }

  deleteFormOption(item: any, index_option: number) {
    this.FormOpt.removeAt(index_option);
    this.checkboxesDataList = this.FormOpt.value;
  }
  onBtnTypeSelect(ev: any) {
    console.log(ev, "event");
    // this.btn_actions.clear();
    if (ev.value !== "next_page") {
      this.btn_actions.controls.forEach((control) => {
        if (control instanceof FormGroup) {
          control.get('pipeline_id')?.addValidators([Validators.required]);
          control.get('pipeline_id')?.updateValueAndValidity();
          control.get('stage_id')?.addValidators([Validators.required]);
          control.get('stage_id')?.updateValueAndValidity();
        }
      });
      // const body: any = new FormGroup({
      //   btn_type: new FormControl(ev.value),
      //   pipeline_id: new FormControl(''),
      //   stage_id: new FormControl('')
      // });
      // this.btn_actions.push(body);
      this.getPipelines();
    } else {
      this.btn_actions.controls.forEach((control) => {
        if (control instanceof FormGroup) {
          control.get('pipeline_id')?.clearValidators();
          control.get('pipeline_id')?.updateValueAndValidity();
          control.get('stage_id')?.clearValidators();
          control.get('stage_id')?.updateValueAndValidity();
        }
      });
    }
  }
  getPipelines() {
    this.crmService.getPipelines('ORDER').subscribe({
      next: (resp: any) => {
        this.pipelineList = resp.data;
      }
    });
  }

  getStagesBasedonPipeline() {
    this.crmService.getStageByPipelineId(this.btn_actions.controls[0].value.pipeline_id).subscribe({
      next: (resp: any) => {
        this.selected_pipeline_stages = resp.data;
      }
    });
  }

  passBack() {
    const arr = this.FormOpt.value;
    const lastElement = arr[arr.length - 1];
    if (lastElement?.trim() === "") {
      this.showError = true;
      return;
    }
    this.submitted = true;
    if (this.fieldoption.value.type === 'MULTIPLE_FILE') this.fieldoption.get('form_value')?.setValue(this.multipleFiles);
    console.log(this.multipleFiles, this.fieldoption.value);
    if (!this.fieldoption.valid && (this.fieldoption.value.type !== 'DATE_RANGE' && this.fieldoption.value.type !== 'PRICE' && this.fieldoption.value.type !== 'NUMBER_RANGE')) {
      return;
    } else if (!this.fieldoption.valid && (this.fieldoption.value.type === 'DATE_RANGE' || this.fieldoption.value.type === 'PRICE' || this.fieldoption.value.type === 'NUMBER_RANGE')) {
      if ((this.fieldoption.value.type === 'DATE_RANGE' || this.fieldoption.value.type === 'NUMBER_RANGE') && !this.fieldoption.value.start && !this.fieldoption.value.end) {
        return;
      } else if (this.fieldoption.value.type === 'PRICE' && !this.fieldoption.value.currency && !this.fieldoption.value.price) {
        return;
      }
    }

    const varible = this.contactFields.filter((item: any) => item.original_label === this.selectedVariable);
    this.fieldoption.value.variables = varible;
    this.passEntry.emit(this.fieldoption.value);
    this.modelService.dismissAll();

  }
  onInputChange() {
    const arr = this.FormOpt.value;
    const lastElement = arr[arr.length - 1];
    if (lastElement !== "") {
      this.showError = false;
    }
  }

  onLabelChange(event: any) {
    if (this.formItem?.variables_data) return;
    this.fieldoption.patchValue({
      variables_data: this.convertToLowerCaseWithUnderscore(event.target.value)
    });
  }

  convertToLowerCaseWithUnderscore(str: string) {
    const noSpacesStr = str.replace(/\s+/g, '_');
    const lowerCaseStr = noSpacesStr.toLowerCase();
    const result = lowerCaseStr.replace(/\s+/g, '_');
    return result;
  }

  getContactsfileds() {
    this.contactFields = [];
    this.crmService.getContactsFileds().subscribe((resp: any) => {
      // let template_fields: any;
      console.log(this.selectedFieldType, "selectedFieldType");

      resp.data.custom_contact_templates.forEach((element: any) => {
        if (element.data_type === this.selectedFieldType && (element.on_form || element.is_inner_screen)) {
          // this.addVariable({ ...element, field_type: 'CUSTOM_FIELD' });
          this.contactFields.push({
            field_type: 'CUSTOM_FIELD',
            module: "contact",
            original_label: element.original_label,
            label: element.label,
            type: element.data_type,
            key: element.data_type,
            options: element.options,
            custom_field_id: element.id
          });
        }
      });
      const sys_fields = Object.entries(resp.data);
      sys_fields.map((field: any, i) => {

        if (field[1].data_type === this.selectedFieldType && (field[1].on_form || field[1].is_inner_screen) && i >= 11 && field[1] !== 'RADIO_BUTTON' && field[0] !== 'country' && field[0] !== 'time_zone' && field[0] !== 'contact_fields_order') {
          const body = {
            field_type: 'SYSTEM_FIELD',
            module: "contact",
            original_label: field[0],
            label: field[0],
            type: field[1].data_type,
            key: field[1].data_type,
            options: {},
            custom_field_id: null
          };
          // this.addVariable(body);
          this.contactFields.push(body);
        }
      });
      const result = this.variableformFields.filter((o: any) => this.contactFields.some(({ original_label }) => o.original_label === original_label));
      if (result) {
        this.contactFields.forEach((element: any) => {
          result.filter((item: any) => {
            if (element.original_label === item.original_label) {
              element.disable = true;
            }
          });
        });
      }
    });
    console.log(this.contactFields);

  }

  onVaribleChange(ev: any) {
    this.selectedVariable = ev.value;
  }

  onSelect(event: any) {
    if (event.target.files[0].size > 15 * 1048576) {
      this.toastrService.error("File size is too large, please upload a file size less than 15MB");
      return;
    }
    this.awsUtilService.getUploadFileLink(event.target.files[0].type, event.target.files[0].name.split('.')[1]).subscribe((resp) => {
      this.fieldoption.get('form_value')?.setValue(this.presignedurl + resp.data.file_name);
      this.awsUtilService.uploadFile(event.target.files[0], resp.data.url).subscribe(() => {//@typescript-eslint/no-empty-function
      });
    });
  }

  onMultipleSelect(event: any) {
    const filesArray = [...event.target.files];
    filesArray.forEach((element: any) => {
      this.awsUtilService.getUploadFileLink(element.type, element.name.split('.')[1]).subscribe((resp) => {
        this.multipleFiles.push(this.presignedurl + resp.data.file_name);
        this.awsUtilService.uploadFile(element, resp.data.url).subscribe(() => {//@typescript-eslint/no-empty-function
        });
      });
    });
  }

  deleteSelectedImage(i: number) {
    this.multipleFiles.splice(i, 1);
  }

  getSafeImageUrl(): string {
    return this.fieldoption.value.form_value || '';
  }

  handleImageError(event: any) {
    const imgElement = event.target;
    imgElement.src = this.getSafeImageUrl();
  }

  onErrorLoadImage(event: any, file: string) {
    event.target.src = ErrorLoadImage('', file);
  }
}
