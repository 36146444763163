<div class="modal-container h-100">
    <div class="modal-header">
        <h5 class="modal-title font-size-18 fw-800 text-primary">{{settingsformJson.heading}}</h5>
        <!-- <button type="button" class="btn-close" aria-label="Close"></button> -->
    </div>

    <div class="modal-body h-75 overflow-auto">
        <!-- <div class="row">
            <mat-label class="mb-2 font-size-14 fw-600">Feild Properties</mat-label>
            <mat-form-field appearance="outline" class="w-65">
                <mat-select placeholder="Select type" [(ngModel)]="selectedFieldType">
                    <mat-option *ngFor="let opt of options" value="{{opt.value}}">{{opt.type}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
        <form [formGroup]="fieldoption" *ngIf="type !== 'dynamic_app' && type !== 'custom_store'">
            <div class="row mt-3">
                <div class="col-4 m-auto text-end">
                    <label> {{settingsformJson.label_name}} </label>
                </div>
                <div class="col-8 m-auto">
                    <div>
                        <mat-form-field appearance="outline" class="mat-hint-none">
                            <input class="field mb-3" style="outline: none" matInput
                                placeholder="{{settingsformJson.name}}" type="text" formControlName="label">
                        </mat-form-field>
                    </div>
                    <div *ngIf="fieldoption.value.type === 'CHECK_BOX'" formArrayName="options">
                        <div class="d-inline-flex align-items-center" *ngFor="let item of FormOpt.controls;let i =index"
                            style="margin: 5px 0px;">
                            <!-- <input class="field propertyfeilds" type="checkbox">&nbsp; -->
                            <mat-form-field appearance="outline" class="mat-hint-none mb-2">
                                <input class="checkbox" [formControlName]="i" type="text" style="outline: none" matInput
                                    placeholder="{{settingsformJson.option_name}}" (input)="onInputChange()">
                            </mat-form-field>&nbsp;
                            <mat-icon (click)="deleteFormOption(item,i)" matSuffix
                                class="text-light">{{settingsformJson.icon_close}}</mat-icon>
                        </div>
                        <br>
                        <div class="d-inline-flex" *ngIf="checkboxesDataList.length<=500">
                            <input class="field m-auto" type="checkbox" style="width: 13px;height: 15px;">&nbsp;
                            <button class="font-size-14 btn btn-light"
                                (click)="addNew(true)">{{settingsformJson.add_option}}</button>
                        </div>
                    </div>
                    <div *ngIf="fieldoption.value.type === 'RADIO_BUTTON'" formArrayName="options">
                        <div class="d-inline-flex align-items-center" *ngFor="let item of FormOpt.controls;let i =index"
                            style="margin: 5px 0px;">
                            <!-- <input class="field" type="radio" style="width: 17px;height: 17px;">&nbsp; -->
                            <mat-form-field appearance="outline" class="mat-hint-none mb-2">
                                <input class="checkbox" [formControlName]="i" type="text" style="outline: none" matInput
                                    placeholder="{{settingsformJson.option_name}}" (input)="onInputChange()">
                            </mat-form-field>&nbsp;
                            <mat-icon (click)="deleteFormOption(item,i)" matSuffix
                                class="text-light">{{settingsformJson.icon_close}}</mat-icon>
                        </div>
                        <br>
                        <div class="d-inline-flex" *ngIf="checkboxesDataList.length<=500">
                            <input class="field m-auto" type="radio" style="width: 17px;height: 17px;">&nbsp;
                            <button class="font-size-14 btn btn-light"
                                (click)="addNew(true)">{{settingsformJson.add_option}}</button>
                        </div>
                    </div>
                    <div *ngIf="fieldoption.value.type === 'SELECT_DROPDOWN' && fieldoption.value.label !== 'Service' && fieldoption.value.original_label !== 'company' && fieldoption.value.original_label !== 'product_category' &&fieldoption.value.original_label !== 'product' 
                        && fieldoption.value.original_label !== 'scheduler_category' && fieldoption.value.original_label !== 'scheduler' && fieldoption.value.original_label !== 'service' && fieldoption.value.original_label !== 'service_category' && fieldoption.value.original_label !== 'priority' && fieldoption.value.original_label !== 'currency' && fieldoption.value.original_label !== 'country'"
                        formArrayName="options">
                        <ol class="mb-0 p-0">
                            <li *ngFor="let item of FormOpt.controls;let i =index" class="d-flex mb-2">
                                <mat-form-field appearance="outline" class="mat-hint-none mb-2">
                                    <input class="checkbox" [formControlName]="i" type="text" style="outline: none"
                                        matInput placeholder="{{settingsformJson.option_name}}"
                                        (input)="onInputChange()">
                                </mat-form-field>&nbsp;
                                <mat-icon (click)="deleteFormOption(item,i)" matSuffix
                                    class="text-light">{{settingsformJson.icon_close}}</mat-icon>

                            </li>
                        </ol>
                        <div style="display: inline-flex" *ngIf="checkboxesDataList.length<=500">
                            <button class="font-size-14 btn btn-light"
                                (click)="addNew(true)">{{settingsformJson.add_option}}</button>
                        </div>
                    </div>
                    <div *ngIf="fieldoption.value.type === 'MULTI_SELECT_DROPDOWN' && fieldoption.value.label !== 'Service' && fieldoption.value.original_label !== 'company' && fieldoption.value.original_label !== 'product_category' &&fieldoption.value.original_label !== 'product' 
                        && fieldoption.value.original_label !== 'scheduler_category' && fieldoption.value.original_label !== 'scheduler' && fieldoption.value.original_label !== 'service' && fieldoption.value.original_label !== 'service_category' && fieldoption.value.original_label !== 'priority' && fieldoption.value.original_label !== 'currency' && fieldoption.value.original_label !== 'country'"
                        formArrayName="options">
                        <ol class="mb-0 p-0">
                            <li *ngFor="let item of FormOpt.controls;let i =index" class="d-flex mb-2">
                                <mat-form-field appearance="outline" class="mat-hint-none mb-2">
                                    <input class="checkbox" [formControlName]="i" type="text" style="outline: none"
                                        matInput placeholder="{{settingsformJson.option_name}}"
                                        (input)="onInputChange()">
                                </mat-form-field>&nbsp;
                            </li>
                        </ol>
                        <div style="display: inline-flex" *ngIf="checkboxesDataList.length<=500">
                            <button class="font-size-14 btn btn-light"
                                (click)="addNew(true)">{{settingsformJson.add_option}}</button>
                        </div>
                    </div>
                    <!-- <div *ngIf="fieldoption.value.type === 'SELECT_DROPDOWN' && fieldoption.value.label === 'Service'">
                        <mat-form-field appearance="outline" class="w-65">
                            <mat-select placeholder="{{settingsformJson.placeholder_select}}" multiple>
                                <mat-option *ngFor="let item of serviceData"
                                    value="{{item.id}}">{{item.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div style="display: inline-flex">
                            <button class="font-size-14 btn btn-light">{{settingsformJson.create_service}}</button>
                        </div>
                    </div> -->

                    <div
                        *ngIf="formItem.key === 'APPOINTMENT_CATEGORIES' || formItem.key === 'APPOINTMENT' || formItem.key === 'PRODUCT_CATEGORIES' || formItem.key === 'PRODUCT' || formItem.key === 'SERVICE' || formItem.key === 'SERVICE_CATEGORIES'">
                        <ng-select bindLabel="mail" bindValue="mail" formControlName="select_options" [multiple]="true"
                            placeholder="Select Options">
                            <ng-option *ngFor="let item of API_LIST"
                                value='{"id":"{{item.id}}","name":"{{item.name}}"}'>{{item.name}}</ng-option>
                        </ng-select>

                    </div>

                </div>
            </div>
            <div class="row mt-3">
                <div class="col-4 m-auto text-end">
                    <label> {{settingsformJson.placeholder_name}} </label>
                </div>
                <div class="col-8 m-auto">
                    <mat-form-field appearance="outline" class="mat-hint-none">
                        <input class="field mb-3" style="outline: none" matInput placeholder="Enter Placeholder"
                            type="text" formControlName="place_holder">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-2" *ngIf="!fieldoption.value.is_default">
                <div class="col-md-5 text-end">
                    <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                        formControlName="is_mandatory" />
                    <span class="ms-1 mb-2">
                        Required <mat-icon class="font-size-16">info</mat-icon>
                    </span>
                </div>
            </div>
            <div class="row mt-4"
                *ngIf="formItem.is_form_field && (fieldoption.value.type === 'TEXT' || fieldoption.value.type === 'DATETIME' || fieldoption.value.type === 'DATE_TIME' || fieldoption.value.type === 'DATE' || fieldoption.value.type === 'TEXT_AREA' || fieldoption.value.type === 'NUMBER')"
                formArrayName="variables">
                <div class="col-4 m-auto text-end">
                    <label> Contact Variables </label>
                </div>
                <div class="col-md-8 m-auto">
                    <mat-form-field appearance="outline" formGroupName="0" class="w-80 mat-hint-none">
                        <mat-select placeholder="Select Variable" (selectionChange)="onVaribleChange($event)"
                            formControlName="original_label">
                            <mat-option *ngIf="contactFields.length === 0">No variable</mat-option>
                            <mat-option *ngFor="let opt of contactFields;let i =index" [disabled]="opt?.disable"
                                value="{{opt.original_label}}">{{opt.label.replace("_"," ")}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <p *ngIf="showError" class="text-center mt-3 text-danger bold">
            {{settingsformJson.please_fill_all_fields}}
        </p>
        <form [formGroup]="fieldoption" *ngIf="type === 'dynamic_app'">
            <div class="row mt-3">
                <div class="col-4 m-auto text-end">
                    <label> {{settingsformJson.label_name}} </label>
                </div>
                <div class="col-8 m-auto">
                    <mat-form-field appearance="outline">
                        <input class="field mb-3" style="outline: none" matInput placeholder="{{settingsformJson.name}}"
                            type="text" formControlName="label" (input)="onLabelChange($event)">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-3" *ngIf="!formItem?.variables_data">
                <div class="col-4 m-auto text-end">
                    <label> Variable </label>
                </div>
                <div class="col-8 m-auto">
                    <mat-form-field appearance="outline">
                        <input class="field mb-3" style="outline: none" matInput placeholder="Variable" type="text"
                            formControlName="variables_data">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-3" *ngIf="fieldoption.value.type !== 'BUTTON'">
                <div class="col-4 m-auto text-end">
                    <label> {{settingsformJson.placeholder_name}} </label>
                </div>
                <div class="col-8 m-auto">
                    <mat-form-field appearance="outline" class="mat-hint-none">
                        <input class="field mb-3" style="outline: none" matInput placeholder="Enter Placeholder"
                            type="text" formControlName="place_holder">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-3"
                *ngIf="fieldoption.value.type === 'URL' || fieldoption.value.type === 'TEXT_AREA' || fieldoption.value.type === 'TEXT' || fieldoption.value.type === 'NUMBER'">
                <div class="col-4 m-auto text-end">
                    <label> Regex Validation </label>
                </div>
                <div class="col-8 m-auto">
                    <mat-form-field appearance="outline" class="mat-hint-none">
                        <input class="field mb-3" style="outline: none" matInput placeholder="Enter regex validation"
                            type="text" formControlName="regex_validation">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-2" *ngIf="fieldoption.value.type !== 'BUTTON'">
                <div class="col-md-5 text-end">
                    <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                        formControlName="is_mandatory" />
                    <span class="ms-1 mb-2">
                        Required <mat-icon class="font-size-16">info</mat-icon>
                    </span>
                </div>
            </div>
            <div class="row mt-2"
                *ngIf="fieldoption.value.type === 'AUDIO' || fieldoption.value.type === 'MULTIPLE_FILE' || fieldoption.value.type === 'VIDEO' || fieldoption.value.type === 'IMAGE' 
                || fieldoption.value.type === 'DATE_RANGE' || fieldoption.value.type === 'DATE' || fieldoption.value.type === 'TIME' || fieldoption.value.type === 'NUMBER_RANGE'"
                formGroupName="data_validations">
                <div class="text-end">
                    <!--FILE-->
                    <div class=""
                        *ngIf="fieldoption.value.type === 'FILE' || fieldoption.value.type === 'MULTIPLE_FILE' 
                    || fieldoption.value.type === 'VIDEO' || fieldoption.value.type === 'IMAGE' || fieldoption.value.type === 'AUDIO'">
                        <div class="row mb-2">
                            <div class="col-4 m-auto text-end">
                                <label> Types </label>
                            </div>
                            <div class="col-8"
                                *ngIf="fieldoption.value.type === 'FILE' || fieldoption.value.type === 'MULTIPLE_FILE'">
                                <mat-form-field appearance="outline" class="mat-hint-none w-100">
                                    <mat-select placeholder="Select types" class="" multiple formControlName="type">
                                        <mat-option value=".pdf">PDF</mat-option>
                                        <mat-option value=".doc">DOC</mat-option>
                                        <mat-option value=".docx">DOCX</mat-option>
                                        <mat-option value=".xls">XLS</mat-option>
                                        <mat-option value=".xlsx">XLSX</mat-option>
                                        <mat-option value=".ppt">PPT</mat-option>
                                        <mat-option value=".pptx">PPTX</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-8" *ngIf="fieldoption.value.type === 'AUDIO'">
                                <mat-form-field appearance="outline" class="mat-hint-none w-100">
                                    <mat-select placeholder="Select types" class="" multiple formControlName="type">
                                        <mat-option value="audio/wav">WAV</mat-option>
                                        <mat-option value="audio/mp3">MP3</mat-option>
                                        <mat-option value="audio/aac">AAC</mat-option>
                                        <mat-option value="audio/flac">FLAC</mat-option>
                                        <mat-option value="audio/ogg">OGG</mat-option>
                                        <mat-option value="audio/m4a">M4A</mat-option>
                                        <mat-option value="audio/dsd">DSD</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-8" *ngIf="fieldoption.value.type === 'VIDEO'">
                                <mat-form-field appearance="outline" class="mat-hint-none w-100">
                                    <mat-select placeholder="Select types" class="" multiple formControlName="type">
                                        <mat-option value=".mp4">MP4</mat-option>
                                        <mat-option value=".avi">AVI</mat-option>
                                        <mat-option value=".mkv">MKV</mat-option>
                                        <mat-option value=".mov">MOV</mat-option>
                                        <mat-option value=".wmv">WMV</mat-option>
                                        <mat-option value=".flv">FLV</mat-option>
                                        <mat-option value=".webm">WEBM</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-8" *ngIf="fieldoption.value.type === 'IMAGE'">
                                <mat-form-field appearance="outline" class="mat-hint-none w-100">
                                    <mat-select placeholder="Select types" class="" multiple formControlName="type">
                                        <mat-option value="image/jpeg">JPEG</mat-option>
                                        <mat-option value="image/jpg">JPG</mat-option>
                                        <mat-option value="image/png">PNG</mat-option>
                                        <mat-option value="image/gif">GIF</mat-option>
                                        <mat-option value="image/tiff">TIFF</mat-option>
                                        <mat-option value="image/svg">SVG</mat-option>
                                        <mat-option value="image/heif">HEIF</mat-option>
                                        <mat-option value="image/webp">WEBP</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-4 m-auto text-end">
                                <label> Size </label>
                            </div>
                            <div class="col-8">
                                <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                                    <input style="outline: none" matInput placeholder="Enter size"
                                        formControlName="size" />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <!--DATE Range, DATE-->
                    <div class=""
                        *ngIf="fieldoption.value.type === 'DATE_RANGE' || fieldoption.value.type === 'DATE' || fieldoption.value.type === 'DATE_TIME'">
                        <div class="row mb-2">
                            <div class="col-4 m-auto text-end">
                                <label> Min Date </label>
                            </div>
                            <div class="col-8">
                                <input class="field w-90" style="outline: none" matInput placeholder="Enter Min Date"
                                    type="date" formControlName="min">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-4 m-auto text-end">
                                <label> Max Date </label>
                            </div>
                            <div class="col-8">
                                <input class="field w-90" style="outline: none" matInput placeholder="Enter Max Date"
                                    type="date" formControlName="max">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-4 m-auto text-end">
                                <label> Disable Future </label>
                            </div>
                            <div class="col-8">
                                <label class="switch">
                                    <input type="checkbox" value="false" formControlName="disable_future" />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-4 m-auto text-end">
                                <label> Disable Past </label>
                            </div>
                            <div class="col-8">
                                <label class="switch">
                                    <input type="checkbox" value="false" formControlName="disable_past" />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <!--TIME-->
                    <div class="" *ngIf="fieldoption.value.type === 'TIME'">
                        <div class="row mb-2">
                            <div class="col-4 m-auto text-end">
                                <label> Min Time </label>
                            </div>
                            <div class="col-8">
                                <input class="field w-90" style="outline: none" matInput placeholder="Enter Min Time"
                                    type="time" formControlName="min">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-4 m-auto text-end">
                                <label> Max Time </label>
                            </div>
                            <div class="col-8">
                                <input class="field w-90" style="outline: none" matInput placeholder="Enter Max Time"
                                    type="time" formControlName="max">
                            </div>
                        </div>
                    </div>
                    <!--NUMBER RANGE-->
                    <div class="" *ngIf="fieldoption.value.type === 'NUMBER_RANGE'">
                        <div class="row mb-2">
                            <div class="col-4 m-auto text-end">
                                <label> Min </label>
                            </div>
                            <div class="col-8">
                                <input class="field w-90" style="outline: none" matInput placeholder="Enter Min Value"
                                    type="number" formControlName="min">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-4 m-auto text-end">
                                <label> Max </label>
                            </div>
                            <div class="col-8">
                                <input class="field w-90" style="outline: none" matInput placeholder="Enter Max Value"
                                    type="number" formControlName="max">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row mt-3" *ngIf="fieldoption.value.type !== 'BUTTON'">
                <div class="col-4 m-auto text-end">
                    <label> Show on card </label>
                </div>
                <div class="col-8 m-auto">
                    <label class="switch me-3">
                        <input type="checkbox" formControlName="show_on_card">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div> -->
            <div class="row mt-3">
                <div class="col-12">
                    <div class="" *ngIf="fieldoption.value.type === 'BUTTON'" formArrayName="button_action">
                        <div class="" [formGroupName]="0">
                            <mat-form-field appearance="outline" class="w-65">
                                <mat-select placeholder="Select type" formControlName="btn_type"
                                    (selectionChange)="onBtnTypeSelect($event)">
                                    <mat-option *ngFor="let opt of formItem?.options"
                                        [value]="opt">{{opt.split("_").join("
                                        ")}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class=""
                                *ngIf="btn_actions.value[0].btn_type && btn_actions.value[0].btn_type !== 'next_page'">
                                <div class="d-flex mb-2">
                                    <mat-form-field appearance="outline" style="max-width: 31%;" class="me-3">
                                        <mat-select formControlName="pipeline_id"
                                            (selectionChange)="getStagesBasedonPipeline()"
                                            placeholder="Select Pipeline">
                                            <mat-option *ngFor="let options of pipelineList" value={{options.id}}>
                                                {{options.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" style="max-width: 31%;">
                                        <mat-select placeholder="Select Stage" formControlName="stage_id">
                                            <mat-option *ngFor="let options of selected_pipeline_stages"
                                                value={{options.id}}>
                                                {{options.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="" *ngIf="btn_actions.value[0].btn_type !== 'move_to_pipeline'">
                                    <div class="d-flex">
                                        <input type="checkbox" id="quantity" formControlName="quantity"
                                            data-md-icheck />
                                        <label class="ms-1 text-secondary" for="quantity">
                                            Quantity
                                        </label>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3"
                                        *ngIf="btn_actions.value[0].quantity">
                                        <div class="">
                                            <label class="mb-2">Min Quantity</label>
                                            <mat-form-field appearance="outline" class="w-100">
                                                <input style="outline: none" matInput placeholder="Minimum quantity"
                                                    formControlName="min_quantity" />
                                            </mat-form-field>
                                        </div>
                                        <div class="">
                                            <label class="mb-2">Max Quantity</label>
                                            <mat-form-field appearance="outline" class="w-100">
                                                <input style="outline: none" matInput placeholder="Maximum quantity"
                                                    formControlName="max_quantity" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <input type="checkbox" id="billing_address" formControlName="billing_address"
                                            data-md-icheck />
                                        <label class="ms-1 text-secondary" for="billing_address">
                                            Billing Address
                                        </label>
                                    </div>
                                    <div class="d-flex">
                                        <input type="checkbox" id="shipping_address" formControlName="shipping_address"
                                            data-md-icheck />
                                        <label class="ms-1 text-secondary" for="shipping_address">
                                            Shipping Address
                                        </label>
                                    </div>
                                    <div class="d-flex">
                                        <input type="checkbox" id="payment" formControlName="payment" data-md-icheck />
                                        <label class="ms-1 text-secondary" for="payment">
                                            Payment
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <label class="fw-600 mb-2"
                        *ngIf="fieldoption.value.type === 'MULTI_SELECT' || fieldoption.value.type === 'SELECT_DROPDOWN' || fieldoption.value.type === 'RADIO_BUTTON'">Options</label><br>
                    <!--Select DropDown-->
                    <div *ngIf="fieldoption.value.type === 'SELECT_DROPDOWN' || fieldoption.value.type === 'MULTI_SELECT'"
                        formArrayName="options">
                        <ol class="mb-0 p-0">
                            <li *ngFor="let item of FormOpt.controls;let i =index" class="d-flex mb-2">
                                <mat-form-field appearance="outline" class="mat-hint-none mb-2">
                                    <input class="checkbox" [formControlName]="i" type="text" style="outline: none"
                                        matInput placeholder="{{settingsformJson.option_name}}"
                                        (input)="onInputChange()">
                                </mat-form-field>&nbsp;
                                <mat-icon (click)="deleteFormOption(item,i)" matSuffix
                                    class="text-light">{{settingsformJson.icon_close}}</mat-icon>

                            </li>
                        </ol>
                        <div style="display: inline-flex" *ngIf="checkboxesDataList.length<=500">
                            <button class="font-size-14 btn btn-light"
                                (click)="addNew(true)">{{settingsformJson.add_option}}</button>
                        </div>
                    </div>
                    <!--Radio button-->
                    <div *ngIf="fieldoption.value.type === 'RADIO_BUTTON'" formArrayName="options">
                        <div class="d-inline-flex align-items-center" *ngFor="let item of FormOpt.controls;let i =index"
                            style="margin: 5px 0px;">
                            <mat-form-field appearance="outline" class="mat-hint-none mb-2">
                                <input class="checkbox" [formControlName]="i" type="text" style="outline: none" matInput
                                    placeholder="{{settingsformJson.option_name}}" (input)="onInputChange()">
                            </mat-form-field>&nbsp;
                            <mat-icon (click)="deleteFormOption(item,i)" matSuffix
                                class="text-light">{{settingsformJson.icon_close}}</mat-icon>
                        </div>
                        <br>
                        <div class="d-inline-flex" *ngIf="checkboxesDataList.length<=500">
                            <input class="field m-auto" type="radio" style="width: 17px;height: 17px;">&nbsp;
                            <button class="font-size-14 btn btn-light"
                                (click)="addNew(true)">{{settingsformJson.add_option}}</button>
                        </div>
                    </div>
                    <div *ngIf="fieldoption.value.type === 'FILTER'">
                        <ng-select class="form-control" bindLabel="label" [items]="formItem.options"
                            formControlName="form_value" [multiple]="true" placeholder="Options"
                            [maxSelectedItems]="10"></ng-select>
                    </div>
                    <!--error Message-->
                    <!-- <div class="error d-flex mt-2" *ngIf="submitted && !fieldoption.value.form_value">
                        <mat-icon class="font-size-12 me-1 mt-1 text-center">info</mat-icon>
                        <span>Description is required</span>
                    </div> -->
                </div>
            </div>
        </form>
        <form [formGroup]="fieldoption" *ngIf="type === 'custom_store'">
            <div class="row mt-3">
                <div class="col-4 m-auto text-end">
                    <label> {{settingsformJson.label_name}} </label>
                </div>
                <div class="col-8 m-auto">
                    <mat-form-field appearance="outline">
                        <input class="field mb-3" style="outline: none" matInput placeholder="{{settingsformJson.name}}"
                            type="text" formControlName="label">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-4 m-auto text-end">
                    <label> Show on card </label>
                </div>
                <div class="col-8 m-auto">
                    <label class="switch me-3">
                        <input type="checkbox" formControlName="show_on_card">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <label class="fw-600 mb-2"
                        *ngIf="fieldoption.value.type !== 'HEADING' && fieldoption.value.type !== 'SUB_HEADING' && fieldoption.value.type !== 'BUTTON'">Description</label><br>
                    <!--TEXT_AREA, BODY_TEXT-->
                    <ckeditor [editor]="Editor" placeholder="Description" formControlName="form_value"
                        [config]="ckconfigQue" class="ckeditor-height" #editorComponent
                        *ngIf="fieldoption.value.type === 'TEXT_AREA' || fieldoption.value.type === 'BODY_TEXT'">
                    </ckeditor>
                    <!--NUMBER-->
                    <input class="field mb-3" style="outline: none" matInput type="number" formControlName="form_value"
                        *ngIf="fieldoption.value.type === 'NUMBER'">
                    <!--Number Range-->
                    <div class="d-flex align-items-center" *ngIf="fieldoption.value.type === 'NUMBER_RANGE'">
                        <input class="field me-3 w-90" style="outline: none" matInput type="number"
                            formControlName="start" placeholder="Start Number">
                        <input class="field me-3 w-90" style="outline: none" matInput type="number"
                            formControlName="end" placeholder="End Number">
                    </div>
                    <!--PRICE-->
                    <div class="d-flex align-items-center" *ngIf="fieldoption.value.type === 'PRICE'">
                        <mat-form-field appearance="outline" class="w-20 me-2">
                            <mat-select formControlName="currency">
                                <mat-option *ngFor="let currency of currencyjson" value='{{currency.symbol}}'>
                                    {{currency.currency + " (" + currency.abbreviation + "," + currency.symbol + ")"}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-80">
                            <input class="field mb-3" style="outline: none" matInput type="number"
                                formControlName="price">
                        </mat-form-field>
                    </div>
                    <!--TEXT-->
                    <input class="field mb-3" style="outline: none" matInput placeholder="Description" type="text"
                        formControlName="form_value" *ngIf="fieldoption.value.type === 'TEXT'">
                    <!--FILE-->
                    <div class="upload-avatar" *ngIf="fieldoption.value.type === 'FILE'">
                        <div *ngIf="fieldoption.value.form_value">
                            <img [src]="getSafeImageUrl()" (error)="handleImageError($event)" alt="" width="150px"
                                style="height: 150px;">
                        </div>
                        <button type="button" class="btn btn-primary font-size-14 mb-3 m-auto"
                            (click)="fileInput.click()">Choose File</button>
                        <input type="file" #fileInput style="display: none;" (change)="onSelect($event)">
                    </div>
                    <!--MULTIPLE_FILE-->
                    <div class="" *ngIf="fieldoption.value.type === 'MULTIPLE_FILE'">
                        <div>
                            <div *ngIf="multipleFiles.length > 0"
                                class="mb-3 mt-1 d-flex align-items-center flex-wrap upload-avatar">
                                <div class="on-delete-img-hover" (click)="deleteSelectedImage(i)"
                                    *ngFor="let img of multipleFiles; let i = index">
                                    <img src="{{img}}" alt="" width="150px" (error)="onErrorLoadImage($event,img)">
                                    <mat-icon [id]="img" class="visiblity-on-hover-delete text-danger cursor"
                                        style="top:0px;right:0px;">
                                        delete
                                    </mat-icon>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between mt-2">
                            <button type="button" class="btn btn-primary font-size-14 mb-3 m-auto"
                                (click)="fileInput.click()">Choose File</button>
                            <input type="file" #fileInput style="display: none;" multiple
                                (change)="onMultipleSelect($event)">
                        </div>
                    </div>
                    <!--DATE-->
                    <input class="field mb-3 w-70" style="outline: none" matInput placeholder="Description" type="date"
                        formControlName="form_value" *ngIf="fieldoption.value.type === 'DATE'">
                    <!--DATE_TIME-->
                    <input class="field mb-3 w-70" style="outline: none" matInput placeholder="Description"
                        type="datetime-local" formControlName="form_value"
                        *ngIf="fieldoption.value.type === 'DATE_TIME'">
                    <!--DATE_RANGE-->
                    <mat-form-field appearance="outline" class="w-100" *ngIf="fieldoption.value.type === 'DATE_RANGE'">
                        <mat-date-range-input [rangePicker]="dateRangepicker">
                            <input matStartDate formControlName="start" placeholder="Start date">
                            <input matEndDate formControlName="end" placeholder="End date">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="dateRangepicker"></mat-datepicker-toggle>
                        <mat-date-range-picker #dateRangepicker></mat-date-range-picker>
                    </mat-form-field>
                    <div *ngIf="fieldoption.value.type === 'FILTER'">
                        <ng-select class="form-control" bindLabel="label" [items]="formItem.options"
                            formControlName="form_value" [multiple]="true" placeholder="Options"
                            [maxSelectedItems]="10"></ng-select>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-light" (click)="modelService.dismissAll()">
            Cancel
        </button>
        <button class="btn btn-primary" (click)="passBack()">
            Save
        </button>
    </div>
</div>