<div class="container-fluid ms-2">
    <div class="row mt-3 mb-4">
        <div class="col-6 d-flex align-items-center">
            <div class="background-on-hover me-2 d-flex align-items-center">
                <mat-icon mat-raised-button matTooltip="Back" class="btn border-0 p-0"
                    aria-label="Button that displays a tooltip when focused or hovered over" class="text-primary cursor"
                    [routerLink]="['/crm/contacts']" [queryParams]="{contact_activeId: 3}"
                    routerLinkActive="router-link-active">arrow_back_ios
                </mat-icon><!--[routerLink]="['/campaign/group']" -->
            </div>
            <p class="m-0 heading font-size-18 fw-700 text-primary">{{group_user || 'List of Contacts'}}</p>
        </div>
        <div class="col-6 text-end px-4" style="height: 36px;">
            <div class="d-flex p-0 align-items-center height-max-content justify-content-end">
                <!-- <div (click)="downloadContacts($event)" *ngIf="activeId === 1" matTooltip="Export"
                class="btn custom-filter-btn d-flex align-items-center font-size-14 margin-right-14 cursor">
                <img src="assets/images/newUI/CRM/export_icon.svg" alt="" width="18">
              </div> -->
                <div matTooltip="Sort By"
                    class="btn custom-filter-btn margin-right-14 d-flex align-items-center font-size-16"
                    (click)="openFiltersModal('sort')">
                    <img src="assets/images/sort_logo.svg" alt="" width="20">
                </div>
                <div matTooltip="Filter"
                    class="btn custom-filter-btn d-flex align-items-center font-size-16 margin-right-14"
                    (click)="openFiltersModal('filters')">
                    <img src="assets/images/filter_log.svg" alt="" width="18">
                </div>
                <button class="btn custom-filter-btn d-flex align-items-center font-size-14 margin-right-14"
                    (click)="clearCustomFilter()"
                    *ngIf="(selectedSortOptions || column_filters.length > 0)">
                    <span class="limit-line-length-1 width-max">Clear filters</span>
                </button>
                <div *ngIf="featuresData?.contact_add && (getRolePermissions('contacts','can_create') || getRolePermissions('contacts','can_update'))"
                    (click)="importEexportModalDialog(importModel)" matTooltip="Import"
                    class="btn custom-filter-btn d-flex align-items-center margin-right-14 cursor">
                    <!--(click)="importEexportModalDialog(importExportModel, 'import')"-->
                    <img src="assets/images/newUI/CRM/import.svg" alt="" width="18">
                </div>
                <div class="btn custom-filter-btn px-2 margin-right-14 d-flex align-items-center contacts-filter-lable">
                    <span mat-raised-button class="menuTrigger  material-icons cursor font-size-20"
                        [matMenuTriggerFor]="filter_search_menu" matTooltip="Table Columns">filter_list</span>

                    <mat-menu #filter_search_menu="matMenu" class="tableColumnLists customised-sec">
                        <mat-form-field (click)="$event.stopPropagation()" appearance="outline" id="inputEle"
                            class="px-3 py-2 mat-hint-none searching-box">
                            <input (keyup)="onColumnFilterSearch($event)" [(ngModel)]="columnFilterSearch"
                                style="outline: none" matInput placeholder="Search Column" />
                            <mat-icon matPrefix class="d-flex" style="scale: 0.7;"><img
                                    src="assets/images/search_log.svg" alt=""></mat-icon>
                        </mat-form-field>
                        <div class="list-sec" (click)="$event.stopPropagation()">
                            <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                                <div cdkDrag *ngFor="let item of tableColumns;let i = index">
                                    <button mat-menu-item id="{{ i }}" (click)="$event.stopPropagation()"
                                        *ngIf="item.field_name !== 'ccode' && item.field_name !== 'assignee'"><!--[disabled]="['display_id','first_name','email','contact_type','tags'].includes(item?.field_name)"-->
                                        <mat-checkbox [checked]="item?.status"
                                            (change)="selectFilterLable($event,item,i)"
                                            class="text-capitalize">{{(item?.field_name!.split("_").join(" ") |
                                            titlecase) ||
                                            ''}}</mat-checkbox>
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="tableColumnNoResFound" class="d-flex align-items-center justify-content-center"
                                style="height:100%">
                                No Result Found
                            </div>
                        </div>
                        <div class="d-flex justify-content-end apply-btn-sec px-3">
                            <button class="btn btn-primary d-flex align-items-center font-size-13"
                                [disabled]="tableColumnNoResFound"
                                (click)="!tableColumnNoResFound && onUpdateTableColumns()">Apply
                                & Save</button>
                        </div>
                    </mat-menu>
                </div>
                <div matTooltip="Add Custom Field"
                    class="btn custom-filter-btn d-flex align-items-center font-size-16 margin-right-14"
                    (click)="openCustomFieldModal()" *ngIf="loggedInUser.data.role === 'ADMIN'">
                    <img src="assets/images/newUI/MENU/HEADER/add_icon.svg" alt="" width="20">
                </div>
                <mat-form-field appearance="outline" class="margin-right-14 mat-hint-none position-relative"
                    style="width: 212px;">
                    <input style="outline: none" class="font-size-14" id="search-box" matInput
                        placeholder="Search Here..." [(ngModel)]="search_global"
                        (ngModelChange)="searchSubject.next(search_global)" />
                    <mat-icon matPrefix class="d-flex" style="scale: 0.7;"><img src="assets/images/search_log.svg"
                            alt=""></mat-icon>
                </mat-form-field>
                <button *ngIf="selection.selected.length > 0" mat-raised-button class="btn btn-primary border-0"
                    (click)="deleteFromList()">Remove From List</button>
            </div>
        </div>
    </div>
    <div class="custom-table-div-crm-height d-flex flex-column justify-content-between">
        <div class="table-responsive table-height fixed-table">
            <table class="table align-middle table-nowrap table-hover mb-0 w-100">
                <thead>
                    <tr class="border-top-table">
                        <th scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                class="mat_checkbox"></mat-checkbox>
                        </th>
                        <ng-container *ngFor="let col of originalTableColumnLists">
                            <th scope="col"
                                *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')"
                                class="text-primary font-size-14 pb-3 table-sticky-header bg-white text-capitalize"
                                style="z-index: 999;">{{col.field_name!.split("_").join(" ")}}</th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody *ngIf="!display_no_data">
                    <tr class="cursor" *ngFor="let item of contactListArray; let i = index"
                        [routerLink]="['/crm/crm-details']"
                        [queryParams]="{ id: item.id, showtype: 'contact',activeId:1}">
                        <td>
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(item) : null" [checked]="selection.isSelected(item)"
                                class="mat_checkbox"></mat-checkbox>
                        </td>
                        <ng-container *ngFor="let col of originalTableColumnLists">
                            <td *ngIf="col.status && (col.field_name !== 'ccode' && col.field_name !== 'assignee')"
                                style="max-width: 200px;"
                                [ngStyle]="{'min-width': item[col.field_name] && item[col.field_name].length < 10 ? '110px' : !item[col.field_name] ? '100px' : '150px'}">
                                <div *ngIf="col.system_field">
                                    <span *ngIf="col.field_name === 'phone_number'"
                                        class="d-flex">{{item[col.field_name]
                                        ?
                                        item.ccode :
                                        ''}}&nbsp;{{item[col.field_name] || 'NA'}}
                                        <!-- <mat-icon class="ms-1 font-size-14 m-auto" style="color: #908989;"
                                            *ngIf="item[col.field_name] && callFeature && getRolePermissions('contact-voice-calls','can_create')"
                                            (click)="$event.stopPropagation();makeaCall(item)"> call</mat-icon> -->
                                    </span>
                                    <span class="limit-line-length-1"
                                        *ngIf="col.field_name === 'owner'">{{(item?.owner_info?.fname
                                        || '-') + ' ' +
                                        (item?.owner_info?.lname || '')}}</span>
                                    <span class="limit-line-length-1" *ngIf="col.data_type === 'DATE'">
                                        {{item.created_at ? (item.created_at | dateConversion : 'dateTime') : 'NA'}}
                                    </span>
                                    <span class="limit-line-length-1" *ngIf="col.data_type === 'DATE_TIME'">
                                        {{item.created_at
                                        | dateConversion }} {{ item.created_at | date: 'h:mm a' || 'NA'}}
                                    </span>
                                    <a class="limit-line-length-1" target="_blank"
                                        *ngIf="col.data_type === 'WEBSITE' && item[col.field_name]"
                                        [href]="item[col.field_name]" (click)="$event.stopPropagation()">
                                        {{item[col.field_name]}}
                                    </a>
                                    <span class="limit-line-length-1"
                                        *ngIf="col.data_type === 'WEBSITE' && !item[col.field_name]">NA</span>
                                    <div *ngIf="col.field_name === 'tags'" class="d-flex">
                                        <p class="text-name m-0" *ngIf="!item['tag_name']"
                                            style="width: -webkit-fill-available;">NA</p>
                                        <p class="text-name text-wrap w-100 cursor"
                                            style="margin-bottom: 0px !important;">
                                            {{item?.tag_name}}
                                        </p>
                                    </div>
                                    <div *ngIf="col.field_name === 'contact_type'" class="d-flex">
                                        <p class="text-name m-0" *ngIf="!item['contact_type']"
                                            style="width: -webkit-fill-available;">NA</p>
                                        <p class="text-name text-wrap w-100 cursor"
                                            style="margin-bottom: 0px !important;">
                                            {{item?.contact_type_name}}
                                        </p>
                                    </div>
                                    <div *ngIf="col.field_name === 'channel'">
                                        <p *ngIf="item['channel']" style="background-color: #FCEED8;color: #73321B;"
                                            class="text-name m-0 d-inline-flex align-items-center px-2 border-radius-50">
                                            <span class="border-radius-50 m-0 tag-color me-1"
                                                style="height: 2px; padding: 3px;"
                                                [style]="{'background-color': '#73321B'}"></span>
                                            <span class="limit-line-length-1 word-break"> {{item['channel']}}</span>
                                        </p>
                                        <p class="text-name m-0" *ngIf="!item['channel']">NA</p>
                                    </div>
                                    <span class="limit-line-length-1"
                                        *ngIf="col.field_name === 'company'">{{item.company_name ||
                                        'NA'}}</span>
                                    <span class="limit-line-length-1"
                                        *ngIf="col.field_name !== 'phone_number' && col.field_name !== 'contact_type' && col.field_name !== 'first_name' && col.field_name !== 'ccode' && col.field_name !== 'tags' && col.field_name !== 'channel' && col.data_type !== 'DATE_TIME' && col.data_type !== 'DATE' && col.field_name !== 'owner' && col.field_name !== 'company' && col.data_type !== 'WEBSITE'"
                                        [innerHTML]="item[col.field_name] || 'NA'"></span>

                                    <span *ngIf="col.field_name === 'first_name'">
                                        {{item[col.field_name]}}
                                    </span>
                                </div>
                                <div *ngIf="!col.system_field">
                                    <ng-container *ngFor="let customField of item?.custom_contact_fields">
                                        <ng-container *ngIf="customField.label === col.field_name">
                                            <span class="limit-line-length-1" *ngIf="col.data_type === 'DATE'">
                                                {{customField.data ? (customField.data | dateConversion) : 'NA'}}
                                            </span>
                                            <span class="limit-line-length-1" *ngIf="col.data_type === 'DATE_TIME'">
                                                {{customField.data
                                                | dateConversion }} {{ customField.data | date: 'h:mm a' || 'NA'}}
                                            </span>
                                            <span *ngIf="col.data_type === 'DATE_RANGE'">
                                                <span class="limit-line-length-1"
                                                    *ngIf="customField.data && customField.data.split('&').length > 1">
                                                    {{customField?.data.split("&")[0].split("-").reverse().join("/") + '
                                                    - ' +
                                                    customField?.data.split("&")[1].split("-").reverse().join("/")}}
                                                </span>
                                                <span *ngIf="!customField.data">
                                                    NA
                                                </span>
                                            </span>
                                            <span class="limit-line-length-1" *ngIf="col.data_type === 'PHONE_NUMBER'">
                                                {{customField?.data?.custom_ccode ?
                                                customField?.data?.custom_ccode :
                                                ''}}&nbsp;{{customField?.data?.custom_phone_number || 'NA'}}
                                            </span>
                                            <span class="limit-line-length-1" *ngIf="col.data_type === 'FILE'">
                                                {{customField?.data?.file_name || 'NA'}}
                                            </span>
                                            <a class="limit-line-length-1" target="_blank"
                                                *ngIf="col.data_type === 'WEBSITE' && customField.data"
                                                [href]="customField.data" (click)="$event.stopPropagation()">
                                                {{customField.data}}
                                            </a>
                                            <span class="limit-line-length-1"
                                                *ngIf="col.data_type === 'WEBSITE' && !customField.data">NA</span>
                                            <span class="limit-line-length-1"
                                                *ngIf="col.data_type !== 'PHONE_NUMBER' && col.data_type !== 'DATE' && col.data_type !== 'DATE_TIME' && col.data_type !== 'WEBSITE' && col.data_type !== 'DATE_RANGE' && col.data_type !== 'FILE'"
                                                [innerHTML]="customField.data || 'NA'">
                                            </span>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
            <app-nodata-found *ngIf="display_no_data"></app-nodata-found>

        </div>
        <div class="mt-3">
            <mat-paginator [length]="contactsObj.total" [pageSize]="page_size" (page)="page($event)"
                aria-label="Select page" showFirstLastButtons
                [pageSizeOptions]="[5, 20, 35, 50, 100, 200, 300, 400, 500]">
            </mat-paginator>
        </div>
    </div>
</div>
<div #dynamicComponentContainer></div><!--For customForm field modal opening-->


<ng-template #importModel let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-primary">
            {{contactsJson.label_import}}
        </h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeImportModal()"></button>
    </div>
    <div class="modal-body">
        <div class="" *ngIf="stepper === 1">
            <div class="contact-dotted-border text-center mb-3">
                <input type="file" class="form-control" (change)="onFileChange($event)" accept=".csv" />
            </div>
            <div class="d-flex justify-content-end">
                <a class="cursor" (click)="downloadSampleFile()">Download Sample
                    File</a>
            </div>

            <div class="d-flex justify-content-end mt-3">
                <button type="button" class="btn btn-primary" (click)="importContacts()">
                    {{contactsJson.import_btn}} <!--[disabled]="!enableImportBtn"-->
                </button>
            </div>
        </div>
        <div class="" *ngIf="stepper === 2">
            <h6 class="fw-600">What do you want to do with the records in the file?</h6>
            <mat-radio-group [(ngModel)]="operation">
                <mat-radio-button value="CREATE" *ngIf="getRolePermissions('contacts','can_create')">Add as new
                    Contacts</mat-radio-button>
                <mat-radio-button value="UPDATE" *ngIf="getRolePermissions('contacts','can_update')">Update existing
                    Contacts only</mat-radio-button>
                <mat-radio-button value="BOTH"
                    *ngIf="getRolePermissions('contacts','can_create') && getRolePermissions('contacts','can_update')">Both</mat-radio-button>
            </mat-radio-group>

            <div class="d-flex justify-content-end mt-4">
                <button type="button" class="btn btn-light me-2" (click)="closeImportModal()">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary" (click)="fieldsMapping()">
                    Next
                </button>
            </div>
        </div>
    </div>
</ng-template>