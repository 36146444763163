export const TIMELINEJSON = [
  {
    heading: 'History',
    filter_label: 'Filter'
  }
];

export const NOTESJSON = [
  {
    title_placeholder: 'Specify a title',
    content_placeholder: "What's this note about?",
    attach_file_label: 'Attach File',
    title_btn_label: 'Add a Title',
    save_btn_label: 'Save Notes',
    cancel_btn_label: 'Cancel'
  }
];

export const ACTIVITYJSON = [
  {
    task_btn_label: 'Task',
    event_btn_label: 'Event',
    call_btn_label: 'Call',
    activites_heading: 'Linked Activities',
    filter_label: 'Filter',
    activity_btn_label: 'Activity'
  }
];

export const FILESJSON = [
  {
    files_heading: 'Files',
    dropfile_placeholder_text1: 'Drag a file here or',
    dropfile_placeholder_text2: 'Click here',
    dropfile_placeholder_text3: 'to browse for a file to upload',
    col_one_label: 'Name',
    col_two_label: 'Created on',
    col_three_label: 'Size',
    col_four_label: 'Delete',
    col_five_label: 'Client visibility',
    col_seven_label: 'Created By',
    col_eight_label: 'Created on',
    col_nine_label: 'Source',
    remove_label: 'Remove',
    col_six_label: 'Actions'
  }
];

export const DEALSJSON = [
  {
    deals_label: 'Deals',
    col_one_label: 'Deal Name',
    col_two_label: 'Flow Name',
    col_three_label: 'Amount',
    col_four_label: 'Stage',
    mat_menu_search_label: 'Displayed Column',
    search_placeholder: 'Search',
    deal_info_label: 'Deal Information',
    deal_name_label: 'Deal Name'
  }
];

export const CONVERSATIONSJSON = [
  {
    start_conversation: 'Start New',
    send_btn_label: 'Send'
  }
];

export const PROJECTJSON = [
  {
    projectHeading: 'Projects',
    col_one_label: 'Name',
    col_two_label: 'Status',
    col_three_label: 'Client',
    col_four_label: 'Amount',
    col_five_label: 'Due Date'
  }
];

export const APPOINTMENTJSON = [
  {
    aptHeading: 'Appointments',
    col_one_label: 'Name',
    col_two_label: 'Client Name',
    col_three_label: 'Email',
    col_four_label: 'Phone Number',
    col_five_label: 'Amount',
    col_six_label: 'Due Date'
  }
];
export const IMAGE_EXTENSIONS = ['jpg','jpeg','png'];
export const FILEEXTENSIONS = [
  {
    type: 'pdf',
    file: 'assets/images/pdf_extension.svg'
  },
  {
    type: 'csv',
    file: 'assets/images/csv_extension.svg'
  },
  {
    type: 'doc',
    file: "assets/images/doc_extension.svg"
  },
  {
    type: 'docx',
    file: 'assets/images/doc_extension.svg'
  },
  {
    type: 'txt',
    file: 'assets/images/txt.svg'
  },
  {
    type: 'mp3',
    file: 'assets/images/mp3.svg'
  },
  {
    type: 'mp4',
    file: 'assets/images/mp4.svg'
  },
  {
    type: 'unknown',
    file: 'assets/images/unknown_extension.svg'
  }
];

export const ContactType = [
  {
    name: "Lead - 🎯",
    id: "LEAD - 🎯"
  },
  {
    name: "Vendor - 🏭",
    id: "VENDOR - 🏭"
  },
  {
    name: "Candidate - 🧑‍💼",
    id: "CANDIDATE - 🧑‍💼"
  },
  {
    name: "Visitor - 👋",
    id: "VISITOR - 👋"
  },
  {
    name: "Member - 📇",
    id: "MEMBER - 📇"
  },
  {
    name: "Client or Customer - 👥",
    id: "CLIENT OR CUSTOMER - 👥"
  },
  {
    name: "Prospect - 🔍",
    id: "PROSPECT - 🔍"
  },
  {
    name: "MQL(Marketing Qualified Lead) - 📈",
    id: "MQL(Marketing Qualified Lead) - 📈"
  },
  {
    name: "SQL(Sales Qualified Lead) - 💼",
    id: "SQL(Sales Qualified Lead) - 💼"
  },
  {
    name: "Partner - 🤝",
    id: "PARTNER - 🤝"
  },
  {
    name: "Employee - 👷",
    id: "EMPLOYEE - 👷"
  },
  {
    name: "Referer - 🗣️",
    id: "REFERER - 🗣️"
  },
  {
    name: "Referee - 📝",
    id: "REFEREE - 📝"
  },
  {
    name: "Consultant - 💡",
    id: "CONSULTANT - 💡"
  },
  {
    name: "Service Provider - 🔧",
    id: "SERVICE_PROVIDER - 🔧"
  },
  {
    name: "Other",
    id: "OTHER"
  }
];
