import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { maximumNumberAllowed, onOnlyNumbers, onParseDomValue, pasteEventInCkeditor } from 'src/app/core/common/common-functions';
import { countryData } from 'src/app/core/data/countryData';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { THANKUPAGE } from 'src/app/core/data/form_builder_json';
import { FormBuilderService } from 'src/app/core/service/form-builder.service';

@Component({
  selector: 'app-formthank-uscreen',
  templateUrl: './formthank-uscreen.component.html',
  styleUrls: ['./formthank-uscreen.component.scss']
})
export class FormthankUscreenComponent implements OnInit, OnChanges {
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  countryjson = countryData;

  @Input() formId = '';
  @Input() type = '';

  thankUpage = true;

  thankYouForm!: FormGroup;

  title = 'Saved';
  description = 'You submitted the form successfully.';
  thankupageJson = THANKUPAGE[0];
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  isSubmited = false;
  errorjson = ERRORJSON[0];
  ckeditorLimit = {
    maxLimit: 1000,
    actualLimit: 0,
    type: false
  };

  constructor(
    private formBuilder: FormBuilder,
    private _formServices: FormBuilderService
  ) {
    this.initializeThankyouForm();
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
  }

  ngOnInit(): void {
    this.filteredBanks.next(this.countryjson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });

    if (this.formId) {
      this.getFormThankYou();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formId'] && changes['formId'].currentValue) {
      this.getFormThankYou();
    }
  }

  onReady(editor: any, editorComponent: any) {
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const contentLength = pasteEventInCkeditor(editorComponent, pasteContent);
      this.ckeditorLimit = Object.assign(this.ckeditorLimit, { ...contentLength });
    });
  }

  editorkeyEvent(editorComponent: any) {
    const inputData = editorComponent.editorInstance.getData();
    const dataWithoutTags = onParseDomValue(inputData);
    this.ckeditorLimit = Object.assign(this.ckeditorLimit, { actualLimit: dataWithoutTags.length || 0 });
  }

  keyDownEvent(event: any, maxLength: number) {
    const maxNum = maximumNumberAllowed(event, maxLength);
    const onlyNum = onOnlyNumbers(event);
    return (maxNum && onlyNum);
  }

  getFormThankYou() {
    this._formServices.getFormThankYou(this.formId, this.type).subscribe((resp: any) => {
      this.initializeThankyouForm(resp.data);
      const dataWithoutTags = onParseDomValue(resp.data?.description);
      this.ckeditorLimit['actualLimit'] = dataWithoutTags?.length || 0;
    });
  }

  initializeThankyouForm(data?: any) {
    this.thankYouForm = this.formBuilder.group({
      id: new FormControl(data?.id ? data.id : ''),
      title: new FormControl(data?.title ? data.title : '', [Validators.required]),// Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)
      description: new FormControl(data?.description ? data.description : ''),
      ccode: new FormControl(data?.ccode ? data.ccode : '+91'),
      phone_number: new FormControl(data?.phone_number ? data.phone_number : ''),
      redirect_link: new FormControl(data?.redirect_link ? data.redirect_link : ''),
      show: new FormControl(data?.show ? data.show : false),
      form_id: new FormControl(this.formId)
    });
  }

  get f() {
    return this.thankYouForm.controls;
  }

  onThankYouFormSubmit() {
    this.isSubmited = true;
    if (this.thankYouForm.invalid) return;
    if (this.thankYouForm.value.id) {
      this._formServices.putFormThankYou(this.thankYouForm.value, this.formId, this.type).subscribe((resp) => {
        this.getFormThankYou();
      });
    } else {
      this._formServices.postFormThankYou(this.thankYouForm.value,this.type).subscribe((resp) => {
        this.getFormThankYou();
      });
    }
  }

  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }

}
