import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-chat-bot',
  templateUrl: './chat-bot.component.html',
  styleUrls: ['./chat-bot.component.scss']
})
export class ChatBotComponent  implements AfterViewInit, OnDestroy{
  @ViewChild('iframeRefbot', { static: true }) iframeRefbot!: ElementRef;
  iframeLoaded = false;
  private receiveMessage: ((event: any) => void);
  iframe: any;
  src: any;

  constructor(private spinner: NgxSpinnerService,private sanitizer: DomSanitizer){ 
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/inbox/tasks' : window.location.hostname.includes('demo.brexa.ai') ? 'https://demoautomation.brexa.ai/inbox/tasks' : 'https://stageautomation.brexa.ai/inbox/tasks');
    this.spinner.show();
    this.receiveMessage = (event: any)=> {
      const sessionValues = {
        ...JSON.parse(sessionStorage.getItem('currentUser') ?? "{}")
      };
      if (event.data.message === "auth") {
        this.iframe?.contentWindow.postMessage({ type: "auth", data: sessionValues }, '*');
      } else if (event.data.message === "updateUrl" && event.data.url !== "/") {
        sessionStorage.setItem('redirectUrl', event.data.url);
      }
    };
  }

  ngOnInit(): void {
    // Parent page

    // function receiveMessage(event: any) {
    //   // if (event.origin !== environment.chatBotUrl) {
    //   //   return;
    //   // }
    //   const sessionValues = {
    //     ...JSON.parse(sessionStorage.getItem('currentUser') ?? "{}")
    //   };
    //   const iframe: any = document.getElementById('chatbot-widget-value');
    //   if (event.data.message === "auth") {
    //     iframe?.contentWindow.postMessage({ type: "auth", data: sessionValues }, '*');
    //   } else if (event.data.message === "updateUrl" && event.data.url !== "/") {
    //     sessionStorage.setItem('redirectUrl', event.data.url);
    //   }
    // }
    this.iframe = document.getElementById('chatbot-widget-value');
    window.addEventListener('message', this.receiveMessage, false);

    setTimeout(async () => {
      const iframeReload = this.iframeRefbot.nativeElement;
      iframeReload.src = this.iframe.src;
      await this.delay(500);
      this.iframeLoaded = true;
      this.spinner.hide();
    }, 3000);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ngAfterViewInit() {
    const url = sessionStorage.getItem('redirectUrl');
    this.iframe.onload = () => {
      setTimeout(() => {
        this.iframe?.contentWindow.postMessage({ type: "redirect", data: { redirectUrl: url } }, '*');
      }, 500);
    };
    
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.receiveMessage, false);
    sessionStorage.removeItem('redirectUrl');
  }
}
