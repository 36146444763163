import { Component, HostListener, Input, Renderer2, ViewChild } from '@angular/core';
import { NgbDropdown, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
// import { ActivityModalComponent } from 'src/app/pages/shared/activity-modal/activity-modal.component';
import { AppointmentModalComponent } from 'src/app/pages/shared/appointment-modal/appointment-modal.component';
import { CompanyModalComponent } from 'src/app/pages/shared/company-modal/company-modal.component';
import { ContactsModalComponent } from 'src/app/pages/shared/contacts-modal/contacts-modal.component';
import { LeadModalComponent } from 'src/app/pages/shared/lead-modal/lead-modal.component';
import { PipelineModalComponent } from 'src/app/pages/shared/pipeline-modal/pipeline-modal.component';
import { header } from '../../data/menu_list';
import { AuthService } from '../../service/auth.service';
import { AddTaskComponent } from 'src/app/pages/shared/add-task/add-task.component';
import { CommonService } from '../../service/common.service';
import { ActivityModalComponent } from 'src/app/pages/shared/activity-modal/activity-modal.component';
import { HomeService } from '../../service/home.service';
import { colorCodes, source_color_code } from '../../data/colorcode';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SetUpService } from '../../service/set-up.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ProfileService } from '../../service/profile.service';
import { ChatService } from '../../service/chat.services';
import { Location } from '@angular/common';
import { customDateTimeConversion } from '../../common/common-functions';
import { SupportBotComponent } from 'src/app/pages/support-bot/support-bot.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { debounceTime, Subject } from 'rxjs';
import { TwilioVoiceService } from '../../service/twilio-voice.service';
import { GlobalSearchComponent } from 'src/app/pages/global-search/global-search.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() selectedMenu: any;
  @ViewChild('notifydrop') notifydrop!: NgbDropdown;
  task_modelref!: NgbModalRef;
  profile_name = JSON.parse(sessionStorage.getItem('currentUser')!);
  selectopt = 'all';
  showType = ['lead', 'project', 'deal', 'company', 'contact'];
  headerJson = header[0];
  announcements_list: any[] = [];
  sourceColorCode: any = source_color_code;
  addmenuList = [
    {
      menu_title: 'Contact',
      menu_icon: 'add-contact',
      menu_feature: 'contact_add',
      viewFeature: false,
      launch_dkly_keys: ['contact_add', 'contact']
    },
    {
      menu_title: 'Lead',
      menu_icon: 'add-enquiry',
      menu_feature: 'enquiry',
      viewFeature: false,
      launch_dkly_keys: ['enquiry_add', 'enquiry', 'contact']
    },
    {
      menu_title: 'Company',
      menu_icon: 'add-company',
      menu_feature: 'company',
      viewFeature: false,
      launch_dkly_keys: ['create_company', 'company']
    },
    {
      menu_title: 'Flow records',
      menu_icon: 'add-pipeline',
      menu_feature: 'pipeline_record',
      viewFeature: false,
      launch_dkly_keys: ['create_pipeline', 'pipeline', 'contact']
    },
    {
      menu_title: 'Schedule a slot',
      menu_icon: 'add-appointment',
      menu_feature: 'appointment',
      viewFeature: false,
      launch_dkly_keys: ['create_appointment', 'appointment']
    },
    {
      menu_title: 'Activity',
      menu_icon: 'add-activity',
      menu_feature: 'activity',
      viewFeature: false,
      launch_dkly_keys: ['global_create_activity']
    },
    {
      menu_title: 'Task',
      menu_icon: 'add-task',
      menu_feature: 'task',
      viewFeature: false,
      launch_dkly_keys: ['create_task']
    }
  ];
  colorCodes = colorCodes;
  notification_list: any = [];
  activity_logs: any[] = [];
  activityCurrentpage = 1;
  notificationsCurrentpage = 1;
  activityLastPage = 1;
  notificationsLastPage = 1;
  featuresData: any;
  notificationsCount: any;
  currentlyLandedInnerScreen = '';
  isHomeScreen = true;
  headerText = '';
  setupData: any;
  @ViewChild('menuContacts') menuContacts: any;
  contactsList: any[] = [];
  searchSubject: Subject<any> = new Subject<any>();
  toNumber: any;
  fromNumber: any;
  twilioFromNumbers: any[] = [];
  callFeature: any;
  selected_contact: any;
  disableCall = true;
  callStatus: any;
  loggedInUser: any;
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // Check for Ctrl + space
    if (event.ctrlKey && event.code === 'Space') {
      event.preventDefault();
      this.globalSearch();
    }
  }
  constructor(private authservice: AuthService, private modalService: NgbModal, private commonservice: CommonService, private router: Router, private activatedRoute: ActivatedRoute,
    private homeService: HomeService, private chatServices: ChatService, private setup_service: SetUpService, private profile_service: ProfileService, private renderer: Renderer2, private sanitizer: DomSanitizer, private _location: Location,
    private twilioService: TwilioVoiceService) {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.activatedRoute.queryParams.subscribe((params: any) => {
      const regex = /^\/home\/([^/]+)\/home-management$/;
      this.isHomeScreen = false;
      if (window.location.pathname === '/crm/crm-details' || window.location.pathname === "/clients/client-managment") {
        this.currentlyLandedInnerScreen = params?.showtype === 'lead' ? 'Leads' : params?.showtype;
      } else if (window.location.pathname.match(regex)) {
        this.currentlyLandedInnerScreen = params?.show_type === 'appointment' ? 'Scheduler' : params?.show_type.split("_").join(" ");
      } else if (window.location.pathname.match(/^\/home\/([^/]+)/)) {
        this.isHomeScreen = true;
        this.currentlyLandedInnerScreen = '';
      } else {
        this.isHomeScreen = false;
        this.currentlyLandedInnerScreen = '';
      }
    });
    this.homeService.headerNotificationsSubscription$.subscribe((data: any) => {
      this.notificationsCount = data.unread_count;
    });
    this.commonservice.pipelineNameSubs$.subscribe((data: any) => { data && (this.currentlyLandedInnerScreen = data); });
    this.searchSubject.pipe(
      debounceTime(300)
    ).subscribe((searchTerm: any) => {
      if (searchTerm.length >= 3) {
        this.authservice.getContactsBySearch(searchTerm).subscribe((result: any) => {
          this.contactsList = [];
          result.data.filter((item: any) => {
            if (item.phone_number) {
              item.number = item.ccode + item.phone_number;
              this.contactsList.push(item);
            }
          });
        });
      } else if (searchTerm.length === 0) {
        this.getContacts();
      }
    });
    this.twilioService.twilioConnection.subscribe((connection: any) => {
      this.callFeature = connection;
    });
    this.twilioService.voicecallStatus.subscribe((resp: any) => {
      this.callStatus = resp;
      if ((this.callStatus === 'closed' || !this.callStatus) && this.toNumber) {
        this.disableCall = false;
      } else {
        this.disableCall = true;
      }
    });
  }

  getCustomisedHeaders() {
    this.headerText = this.selectedMenu?.label;
    if (window.location.pathname.match(/^\/crm\/(leads|contacts|companies)/)) {
      this.isHomeScreen = true;
      if (window.location.pathname.match(/^\/crm\/leads/)) {
        this.headerText = `<span style="color:#797979">CRM / </span> Leads`;
      } else if (window.location.pathname.match(/^\/crm\/contacts/)) {
        this.headerText = `<span style="color:#797979">CRM / </span> Contacts`;
      } else {
        this.headerText = `<span style="color:#797979">CRM / </span> Companies`;
      }
    } else if (window.location.pathname.match(/^\/clients\/(clients|client-projects)/)) {
      this.isHomeScreen = true;
      if (window.location.pathname.match(/^\/clients\/clients/)) {
        this.headerText = `<span style="color:#797979">Client / </span> Clients`;
      } else {
        this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        if (this.loggedInUser?.data?.organisation === '2c094b33-f57c-442d-9438-e586ed9a1ebf') {
          this.headerText = `<span style="color:#797979">Client / </span> Policies`;
        } else {
          this.headerText = `<span style="color:#797979">Client / </span> Projects`;
        }
      }
    } else {
      this.headerText = '';
    }
  }

  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.getCustomisedHeaders();
      }
    });
    this.getCustomisedHeaders();
    this.getFeatues();
    // this.getNotificationsCount();
    window.addEventListener('message', (event) => {
      let modelRef: any;
      if (event.data.modal === "showPipelineModal") {
        modelRef = this.modalService.open(PipelineModalComponent, { size: 'lg', scrollable: true });
      } else if (event.data.modal === "showEnquiryModal") {
        modelRef = this.modalService.open(LeadModalComponent, { size: 'lg', scrollable: true });
      } else if (event.data.modal === "showAppointmentModal") {
        modelRef = this.modalService.open(AppointmentModalComponent, { size: 'lg', scrollable: true });
      } else if (event.data.modal === "showContactModal") {
        modelRef = this.modalService.open(ContactsModalComponent, { size: 'lg', scrollable: true });
      } else if (event.data.name === "redirectURL") {
        let url: any = `/crm/crm-details?id=${event.data?.id}&showtype=${event.data?.showtype}&contact_id=${event.data?.contactId}`;
        if (event.data.showtype === "lead") {
          this.router.navigateByUrl(url);
        } else if (event.data.showtype === "deal") {
          url = url + `&pipeline_id=${event.data.pipeLineId}`;
          this.router.navigateByUrl(url);
        } else if (event.data.showtype === "project") {
          this.router.navigateByUrl(`/clients/client-managment?id=${event.data.id}&showtype=${event.data.showtype}&contact_id=${event.data.contactId}`);
        } else if (event.data.showtype === 'setup') {
          this.router.navigateByUrl(`/setup`);
        } else if (event.data.showtype === 'contact') {
          this.router.navigateByUrl(url);
        } else if (event.data.showtype === 'appointment') {
          this.router.navigateByUrl(`/home/appointments/home-management?show_type=appointment&id=${event.data.id}`);
        } else if (event.data.showtype === 'integration') {
          this.router.navigateByUrl(`/settings/integrations`);
        }
      }
      if (modelRef) {
        modelRef.componentInstance.flow_type = event.data?.flowType;
        modelRef.componentInstance.contact = event.data.contactId;
        modelRef.componentInstance.chatId = event.data.chatId;
        modelRef.componentInstance.iframe_type = event.data?.type;
      }
    }, false);
    // Socket calling for notification count
    this.chatServices.notificationCount$.subscribe(() => {
      const location = window.location.pathname;
      if (location.split("/")[1] !== 'home') {
        this.getNotificationsCount();
      }
    });
    // this.getNotification();
    this.profile_service.stringSubject.subscribe({
      next: (resp: any) => {
        console.log(resp);

        if (resp) {
          this.profile_name = JSON.parse(sessionStorage.getItem('currentUser')!);
        }
      }
    });
  }

  logout() {
    this.authservice.logout();
  }
  getNotificationsCount() {
    this.homeService.getNotificationsCount().subscribe((response: any) => {
      this.notificationsCount = response.data.unread_count;
    });
  }
  // notificationsCount

  onClickSeemore(hpRef: any, index: number) {
    const notifications = document.querySelectorAll(".h-notification-text");
    const seeMore = document.querySelectorAll('.h-see-more');
    this.renderer.addClass(notifications[index], 'notification-see-more-expand');
    this.renderer.removeClass(notifications[index], 'limit-line-length-1');
    if (hpRef.scrollHeight > 38) {
      setTimeout(() => {
        const height = notifications[index]?.getElementsByTagName('p')[0]?.clientHeight;
        this.renderer.setStyle(notifications[index], 'height', height ? height + 'px' : notifications[index]?.scrollHeight + 'px');
        this.renderer.setStyle(notifications[index], 'transition', 'all 0.3s ease');
      });
    } else {
      this.renderer.setStyle(notifications[index], 'height', 'fit-content');
    }
    this.renderer.addClass(seeMore[index], 'd-none');
  }

  openModalDialog(dialogName: string) {
    if (dialogName === 'Contact') {
      this.modalService.open(ContactsModalComponent, { size: 'lg', scrollable: true });
    } else if (dialogName === 'Lead') {
      this.modalService.open(LeadModalComponent, { size: 'lg', scrollable: true });
    } else if (dialogName === 'Schedule a slot') {
      this.modalService.open(AppointmentModalComponent, { size: 'lg', scrollable: true });
    } else if (dialogName === 'Company') {
      this.modalService.open(CompanyModalComponent, { size: 'lg', scrollable: true });
    } else if (dialogName === 'Flow records') {
      this.modalService.open(PipelineModalComponent, { size: 'lg', scrollable: true });
    } else if (dialogName === 'Task') {
      this.task_modelref = this.modalService.open(AddTaskComponent, { size: 'lg', scrollable: true });
      this.task_modelref.componentInstance.is_global = true;
    } else if (dialogName === 'Activity') {
      this.modalService.open(ActivityModalComponent, { size: 'lg', scrollable: true });
    }
  }

  getActivityLogs(type?: string) {
    if (type) this.activity_logs = [];
    this.commonservice.getActivity(this.activityCurrentpage).subscribe((resp: any) => {
      resp.data.forEach((element: any) => {
        element.activity_text = this.sanitizer.bypassSecurityTrustHtml(element.activity_text);
      });
      this.activity_logs.push(...resp.data);
      this.activityLastPage = resp.last_page;
    });
  }

  onScroll(type: string): void {
    if (type === 'activity') {
      if (this.activityCurrentpage !== this.activityLastPage) {
        ++this.activityCurrentpage;
        this.getActivityLogs();
      }
    } else {
      if (this.notificationsCurrentpage !== this.notificationsLastPage) {
        ++this.notificationsCurrentpage;
        this.getNotification();
      }
    }
  }

  getAnnouncments() {
    this.setup_service.getInternalAnnouncements().subscribe((resp: any) => {
      this.announcements_list = resp.data;
      this.announcements_list.forEach((data: any) => {
        if (data?.text) {
          data.text = data.text.replace(/\\/g, '');
          const regex = /(https:\/\/www\.youtube\.com\/[^\s"']+)/g;
          data.text = data.text.replace(regex, (match: any) => {
            if (match.includes('&')) {
              return match.split('&')[0];
            } else {
              return match;
            }
          });
        }
      });
    });
  }

  getNotification(type?: string) {
    this.homeService.getNotifications(this.notificationsCurrentpage).subscribe((resp: any) => {
      resp.data.forEach((element: any) => {
        const timeString = element?.created_at;
        element['customTime'] = customDateTimeConversion(new Date(timeString).getTime());
        element.message = this.sanitizer.bypassSecurityTrustHtml(element?.message);
      });
      type && (this.notification_list = []);
      this.notification_list.push(...resp.data);
      this.notificationsLastPage = resp?.last_page;
      for (let i = 0; i < this.notification_list.length; i++) {
        const colorCount = (i % 4);
        this.notification_list[i].colorCode = this.colorCodes[colorCount].color;
      }
      this.notification_list.forEach((element: any) => {
        const inputString = element.description;
        const regex = /{[^{}]*}/g;
        const modifiedString = element.description && inputString.replace(regex, (match: string) => {
          try {
            const jsonObject: any = JSON.parse(match);
            if (Object.keys(jsonObject).length !== 0 && jsonObject.fname && jsonObject.id) {
              const searchString = jsonObject.fname + ' ' + jsonObject.lname;
              element['mentioned'] = true;
              return '<span " class="pointer custom_events" style="color: #0072f0;">' +
                searchString + `</span>`; // this is not required currently
            } else {
              return match;
            }
          } catch (error) {
            console.error('');
            return match;
          }
        });
        element.description = modifiedString;
      });
    });
  }

  onEventDeligation(item: any) {
    this.onNavigateFromNotifications(item);
  }

  onNavigateFromNotifications(item: any) {
    this.router.navigateByUrl(item.redirection);
    // if (item?.source_type && this.showType.includes(item?.source_type) && item?.source_id) {
    //   const queryParams = {
    //     showtype: item?.source_type,
    //     id: item?.source_id,
    //     type: 'notes'
    //   };
    //   if (this.notifydrop && this.notifydrop.isOpen()) {
    //     this.notifydrop.close();
    //   }
    //   const crm = ['lead', 'contact', 'company', 'pipeline'];
    //   const client = ['client', 'project'];
    //   if (crm.includes(item?.source_type)) {
    //     this.router.navigate(['/crm/crm-details'], { queryParams: queryParams });
    //   } else if (client.includes(item?.source_type)) {
    //     this.router.navigate(['/clients/client-managment'], { queryParams: queryParams });
    //   }
    // }
  }
  onBack() {
    this._location.back();
  }

  markasRead(item: any, type: string) {
    if (type === 'lbl') {
      item.is_read = !item.is_read;
    }
    this.homeService.putNotification(item).subscribe(() => {
      this.homeService.headerNotificationsSubscription$.next('');
      this.getNotification('marked');
    });
  }
  markAllasRead(event: any) {
    const inputBox: any = document.getElementById('mark_as_read')!;
    inputBox.checked = true;
    this.homeService.notificationsRead().subscribe(() => {
      inputBox.checked = false;
      this.notification_list = [];
      this.getNotification('marked');
    });
  }

  getFeatues() {
    this.setup_service.getGlobalSetupInfo().subscribe((data: any) => {
      this.setupData = data.data;
    });
    this.authservice.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
      this.addmenuList.forEach((item: any) => {
        const launch_dkly_keys = item.launch_dkly_keys.every((launchKeys: string) => this.featuresData[launchKeys]);
        if (item.menu_feature === 'task' || item.menu_feature === 'activity') {
          (launch_dkly_keys && (this.featuresData?.contact || this.featuresData?.company || this.featuresData?.projects || this.featuresData?.enquiry)) ? (item.viewFeature = true) : (item.viewFeature = false);
        } else if (item.menu_feature === 'appointment') {
          item.viewFeature = false;
          if (this.setupData[0].status) (launch_dkly_keys && (this.featuresData?.contact && this.getRolePermissions('scheduler-set-up', 'can_create') && this.featuresData?.products_service)) ? (item.viewFeature = true) : (item.viewFeature = false);
        } else if (item.menu_feature === 'contact_add') {
          launch_dkly_keys && this.getRolePermissions('contacts', 'can_create') ? (item.viewFeature = true) : (item.viewFeature = false);
        } else if (item.menu_feature === 'company') {
          launch_dkly_keys && this.getRolePermissions('companies', 'can_create') ? (item.viewFeature = true) : (item.viewFeature = false);
        } else if (item.menu_feature === 'enquiry' || item.menu_feature === 'pipeline') {
          launch_dkly_keys && this.getRolePermissions(item.menu_feature === 'enquiry' ? 'leads' : 'flow-cards', 'can_create') && this.featuresData?.contact ? (item.viewFeature = true) : (item.viewFeature = false);
        } else {
          launch_dkly_keys ? (item.viewFeature = true) : (item.viewFeature = false);
        }
      });
    });
  }
  openSupportModel() {
    this.modalService.open(SupportBotComponent, {
      modalDialogClass: 'settings-dialog',
      centered: false,
      size: 'md'
    });
  }

  getContacts() {
    this.authservice.getContacts().subscribe((resp: any) => {
      this.contactsList = [];
      resp.data.filter((item: any) => {
        if (item.phone_number) {
          item.number = item.ccode + item.phone_number;
          this.contactsList.push(item);
        }
      });
    });
    this.getFromNumbers();
    this.twilioService.twilioToken.subscribe((data: any) => {
      this.twilioService.initializeDevice(data);
    });
  }

  closeMe(menuTrigger: MatMenuTrigger) {
    menuTrigger.closeMenu();
  }

  makeaCall() {
    const body = {
      to: this.toNumber,
      from: this.fromNumber,
      contact_id: this.selected_contact.id,
      contact_name: this.selected_contact.first_name + ' ' + this.selected_contact.last_name
    };
    this.twilioService.makeCall(body);
    this.disableCall = true;
  }

  onSelectContact(contact: any) {
    this.selected_contact = contact;
    if ((this.callStatus !== 'closed' && this.callStatus)) {
      this.disableCall = true;
    } else {
      this.disableCall = false;
    }
  }

  getFromNumbers() {
    this.twilioService.getTwilioFromNumbers().subscribe((resp: any) => {
      this.twilioFromNumbers = resp.phoneNumbers;
      this.fromNumber = this.twilioFromNumbers[0];
    });
  }

  getRolePermissions(item: any, permission: any) {
    return this.authservice.rolefeatureShow(item, permission);
  }

  globalSearch() {
    this.modalService.open(GlobalSearchComponent, { size: 'lg' });
  }
}
