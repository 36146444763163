<div class="orders-inner-screen newUI mt-1 col-12 d-flex ">
  <div class="orders-details col-12 border-radius-16 flex-shrink">
    <div class="orders-details-header py-2 px-3 d-flex align-items-center justify-content-between">
      <p class="m-0 font-size-18 fw-600" style="color: #232323;">Orders Details</p>
      <div class="d-flex align-items-center">
        <div class="border-radius-8 orders-source px-2 me-3">
          <div class="text-center limit-line-length-1 font-size-14 d-flex align-items-center ">
            <img src="assets/images/newUI/HOME/information_icon.svg" class="me-1" alt="">
            You got this source
            from
            <span class="fw-600 ms-1">{{orderDetails?.source_type}}</span>
          </div>
        </div>
        <button class="btn border-radius-8 bg-white me-3" (click)="openGenerateUrlModal(paymentUrl)"
          *ngIf="payment_connected && orderDetails?.payment_type === 'COD' && !orderDetails?.payment_link_url && orderDetails?.order_status === 'ACCEPTED' && orderDetails?.payment_status === 'PENDING' && orderDetails?.final_price !== 0">
          Generate Payment Url
        </button>
        <button class="btn border-radius-8 bg-white">
          <a [href]="orderDetails?.media_url" target="_blank" download
            class="limit-line-length-1 d-flex align-items-center text-decoration-none" style="color: #09090B;">
            <img src="assets/images/newUI/HOME/download_icons.svg" class="me-2" alt="">
            Download Invoice
          </a>
        </button>
      </div>
    </div>
    <div class="orders-details-info auto-hide-scroll-bar overflow-auto" style="height: calc(100vh - 230px);">
      <div>
        <div class="d-flex align-items-center p-3 ">
          <img src="assets/images/newUI/HOME/android_home_menu.svg" alt="">
          <p class="m-0 ps-2 font-size-16 fw-600" style="color: #232323;">Products</p>
        </div>
        <div class="d-flex flex-column justify-content-between mb-3 px-3">
          <div class="table-responsive table-height h-100 border-radius-16">
            <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="table_cols.length"></app-table-skeleton>
            <table *ngIf="!tableSkeletonLoader"
              class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
              <thead>
                <tr class="table-header-color">
                  <th scope="col" class="p-2" *ngFor="let col of table_cols">
                    {{col}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="cursor" *ngFor="let item of orderDetails.product_items; let i = index">
                  <td>
                    <span class="limit-line-length-1">
                      {{ i + 1}}.
                    </span>
                  </td>
                  <td style="min-width: 80px;max-width: 100px;" class="p-2">
                    <div style="width: 30px; height: 30px;"
                      class="border-radius-50 overflow-hidden d-flex align-items-center justify-content-center">
                      <img src="{{item?.image[0]}}" onerror="this.src='../../../../assets/images/default_image.png'"
                        alt="" width="24" height="24">
                    </div>
                  </td>
                  <td class="p-2" style="min-width: 100px;max-width: 250px;"><span class="limit-line-length-1">{{
                      item.name }}</span></td>
                  <td class="p-2"><span class="limit-line-length-1">{{item.item_quantity}}</span></td>

                  <td class="p-2"><span class="limit-line-length-1">
                      <ng-container *ngIf="item?.discount_type === 'PERCENTAGE'">
                        <span class="bg-green" style="border-radius: 99px;padding: 8px 12px;">{{item.discount_value
                          ? item?.discount_value : '0'}}%</span></ng-container>
                      <ng-container *ngIf="item?.discount_type === 'AMOUNT'">
                        {{item?.discount_value ? (item?.discount_value |
                        currencyConversion) : '0'}}
                      </ng-container></span></td>
                  <td class="p-2"><span class="limit-line-length-1">{{ item?.price }}</span></td>
                  <td class="p-2"><span class="limit-line-length-1">{{ item?.product_discounted_price
                      }}</span></td>
                  <td class="p-2" style="min-width: 100px;max-width: 250px;"><span class="limit-line-length-1">{{
                      item?.description ? item?.description : 'NA' }}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div class="d-flex align-items-center p-3 ">
            <img src="assets/images/newUI/HOME/contact_icon.svg" alt="">
            <p class="m-0 ps-2 font-size-16 fw-900" style="color: #232323;">Contact Details</p>
          </div>
          <ng-container
            *ngTemplateOutlet="list_view;context:{title:'Booked By',titleValue:orderDetails?.user_info?.fname ? orderDetails?.user_info?.fname + orderDetails?.user_info?.lname : ''}"></ng-container>
          <ng-container
            *ngTemplateOutlet="list_view;context:{title:'Email Address',titleValue:orderDetails?.user_info?.email ? orderDetails?.user_info?.email : 'NA'}"></ng-container>
          <ng-container
            *ngTemplateOutlet="list_view;context:{title:'Phone Number',titleValue:orderDetails?.user_info?.phone_number ? orderDetails?.user_info?.ccode + ' ' + orderDetails?.user_info?.phone_number :'' }"></ng-container>
        </div>
      </div>
      <div *ngIf="qr_code_details">
        <div class="d-flex align-items-center p-3 ">
          <img src="assets/images/newUI/HOME/calender_icon.svg" alt="">
          <p class="m-0 ps-2 font-size-16 fw-900" style="color: #232323;">Ordered From</p>
        </div>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'QR name',titleValue:qr_code_details?.name ? qr_code_details?.name : 'NA'}"></ng-container>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'QR type',titleValue:qr_code_details?.qr_type ? qr_code_details?.qr_type : 'NA'}"></ng-container>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Locations',titleValue:qr_code_details?.location.length>0 ? qr_code_details?.location : 'NA'}"></ng-container>
      </div>
      <div>
        <div>
          <div class="d-flex align-items-center p-3 ">
            <img src="assets/images/newUI/HOME/calender_icon.svg" alt="">
            <p class="m-0 ps-2 font-size-16 fw-900" style="color: #232323;">Order Details</p>
          </div>
          <ng-container
            *ngTemplateOutlet="list_view;context:{title:'Ordered On',titleValue:orderDetails?.created_at ? (orderDetails?.created_at | dateConversion)+ ' ' +(orderDetails?.created_at | date: 'h:mm a') : ''}"></ng-container>
          <!-- <ng-container
            *ngTemplateOutlet="list_view;context:{title:'Status',titleValue:orderDetails.order_status}"></ng-container> -->
          <ng-container
            *ngTemplateOutlet="list_view;context:{title:'Payment Type',titleValue:orderDetails.payment_type ? (orderDetails.payment_type) : ''}"></ng-container>
          <ng-container *ngIf="orderDetails.payment_link_url">
            <ng-container
              *ngTemplateOutlet="list_view;context:{title:'Payment Url',titleValue:orderDetails.payment_link_url ? (orderDetails.payment_link_url) : ''}"></ng-container>
          </ng-container>
          <ng-container
            *ngTemplateOutlet="list_view;context:{title:'Delivery Type',titleValue:orderDetails.delivery_typ ? (orderDetails.delivery_typ) : ''}"></ng-container>
          <ng-container
            *ngTemplateOutlet="list_view;context:{title:'Pickup Date',titleValue:orderDetails.pickup_date ? (orderDetails.pickup_date) : ''}"></ng-container>
          <ng-container
            *ngTemplateOutlet="list_view;context:{title:'Pickup Time',titleValue:orderDetails.pickup_time ? (orderDetails.pickup_time) : ''}"></ng-container>
          <ng-container
            *ngTemplateOutlet="list_view;context:{title:'Delivery Type',titleValue:orderDetails.delivery_typ ? (orderDetails.delivery_typ) : ''}"></ng-container>
          <ng-container
            *ngTemplateOutlet="list_view;context:{title:'Pickup Date',titleValue:orderDetails.pickup_date ? (orderDetails.pickup_date) : ''}"></ng-container>
          <ng-container
            *ngTemplateOutlet="list_view;context:{title:'Pickup Time',titleValue:orderDetails.pickup_time ? (orderDetails.pickup_time) : ''}"></ng-container>
          <ng-container
            *ngTemplateOutlet="list_view;context:{title:'Billing Address',titleValue:orderDetails.billing_address ? orderDetails.billing_address : ''}"></ng-container>
          <ng-container
            *ngTemplateOutlet="list_view;context:{title:'Shipping Address',titleValue:orderDetails.shipping_address ? orderDetails.shipping_address : ''}"></ng-container>
        </div>
      </div>
      <div>
        <div class="d-flex align-items-center p-3">
          <img src="assets/images/newUI/HOME/bill_icons.svg" alt="">
          <p class="m-0 ps-2 font-size-16 fw-900" style="color: #232323;">Payment Details</p>
        </div>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Order ID',titleValue:orderDetails.order_id ? orderDetails.order_id : ''}"></ng-container>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Reference ID',titleValue:orderDetails.reference_id ? orderDetails.reference_id : ''}"></ng-container>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Payment ID',titleValue:orderDetails.payment_id ? orderDetails.payment_id : ''}"></ng-container>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'No.Of Products',titleValue:totalQuantityInNum ? totalQuantityInNum : ''}"></ng-container>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Total Price',titleValue:orderDetails.total_price ? (orderDetails.total_price.toString() | decimal | currencyConversion : orderDetails.currency_symbol) : ''}"></ng-container>
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Discount',titleValue:orderDetails.discount_price ? (orderDetails.discount_price.toString() | decimal | currencyConversion) : ''}"></ng-container>
        <!-- <ng-container *ngFor="let tax_item of orders_tax_list"> -->
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Total Tax',titleValue: orderDetails.total_order_tax | currencyConversion}"></ng-container>
        <!-- </ng-container> -->
        <ng-container
          *ngTemplateOutlet="list_view;context:{title:'Final Price',titleValue:orderDetails.final_price ? (orderDetails.final_price.toString() | decimal | currencyConversion : orderDetails.currency_symbol) : ''}"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #list_view let-title="title" let-value="titleValue" let-color="color" let-colValue="col">
  <div class="no-gutters bottom-border p-3 cursor d-flex">
    <div class="info-accord" [ngClass]="colValue ? 'col-4' : 'col-2'">
      <p class="text-inner-info m-0 limit-line-length-1" [matTooltip]="title">{{title}}</p>
    </div>
    <!-- <div class="col-md-1"></div> -->
    <div class="info-accord d-flex" [ngClass]="colValue ? 'col-8' : 'col-10'">
      <div class="text-name d-flex text-wrap w-100">
        <span class="pe-1 fw-600">:</span>
        <p class="text-name text-ellipsis cursor m-0" [matTooltip]="value"
          [ngClass]="title === 'Final Price' ? 'fw-900' : 'fw-500'"
          *ngIf="title !== 'Status' && title !== 'Payment Url' && title !== 'Payment Type' && title !== 'Billing Address' && title !== 'Shipping Address' && title !== 'Locations'">
          {{value || 'NA'}}
        </p>
        <div *ngIf="title === 'Billing Address' || title === 'Shipping Address'">
          <p class="text-name text-ellipsis cursor m-0" *ngIf="value">
            <span *ngIf="value?.address_one || value?.billing_address || value?.shipping_address">{{value?.address_one
              || value.billing_address || value.shipping_address}}</span>
            <span *ngIf="value?.address_two">{{','+value.address_two}},</span>
            <span *ngIf="value?.city">{{value.city}},</span>
            <span *ngIf="value?.state">{{value.state}},</span>
            <span *ngIf="value?.zipcode">{{value.zipcode}},</span>
            <span *ngIf="value?.country">{{value.country}},</span>
            <span *ngIf="value?.contact_person_name">{{value.contact_person_name}},</span>
            <span *ngIf="value?.contact_person_phone">{{value.contact_person_phone}}</span>
          </p>
        </div>
        <div *ngIf="title === 'Status'">
          <mat-form-field appearance="outline" class="status-select mat-hint-none" *ngIf="value === 'PENDING'">
            <mat-select placeholder="Select" (selectionChange)="onStatusChange()"
              [(ngModel)]="orderDetails.order_status" [disabled]="!orderDetails?.is_contact_active">
              <mat-option value="ACCEPTED">Accepted</mat-option>
              <mat-option value="REJECTED">Rejected</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="status-select mat-hint-none" *ngIf="value === 'ACCEPTED'">
            <mat-select placeholder="Select" (selectionChange)="onStatusChange()"
              [(ngModel)]="orderDetails.order_status" [disabled]="!orderDetails?.is_contact_active">
              <mat-option value="ACCEPTED">Accepted</mat-option>
              <!-- <mat-option value="REFUNDED">Refunded</mat-option> -->
              <mat-option value="DELIVERED">Delivered</mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="value!=='ACCEPTED' && value!=='PENDING'"
            class="badge-soft-info border-radius-4 fw-600 text-center font-size-12 p-2 text-capitalize text-ellipsis"
            style="max-width: 150px;" [ngClass]="{'bg-high':value === 'REJECTED','bg-medium':value === 'REFUNDED'}">
            {{value | titlecase}}
          </div>
        </div>
        <div *ngIf="title === 'Payment Url'" class="d-flex align-items-center">
          <p class="text-name text-ellipsis cursor m-0 fw-500" [matTooltip]="value">
            {{value || 'NA'}}
          </p>
          <button (cdkCopyToClipboardCopied)="onClipboardCopy($event)" matTooltip="Copy to clipboard"
            matTooltipPosition="above" #tp="matTooltip" [cdkCopyToClipboard]="value"
            class="copy-link label-text-clr border-0 py-2 me-2">
            <mat-icon style="width: 12px;height: 12px;" class="me-1 font-size-12 fw-600">content_copy</mat-icon>
          </button>
          <button class="label-text-clr border-0 py-2 text-danger d-flex align-items-center"
            matTooltip="Cancel Payment Url" matTooltipPosition="above" (click)="cancelPaymentUrl()">
            <mat-icon class="me-1 font-size-16 fw-600">delete</mat-icon>
          </button>
        </div>
        <div *ngIf="title === 'Payment Type'" class="d-flex align-items-center"
          [ngClass]="value ? 'assigned-staff' :''">
          <p class="m-0 font-size-14 fw-600" [ngStyle]="{'color':value ? '#73321B' : ''}">{{value ||
            'NA'}}</p>
        </div>
        <div *ngIf="title === 'Locations'" class="d-flex align-items-center">
          <p class="m-0 font-size-14 fw-600" *ngFor="let loct of value">{{loct.location_name}},</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #paymentUrl let-modal>
  <div class="modal-header">
    <h5 class="modal-title text-dark fw-500" id="systemFieldModal">
      Generate Url
    </h5>
    <button type="button" class="btn-close" (click)="modalService.dismissAll()" aria-hidden="true"></button>
  </div>
  <div class="modal-body">
    <div class="mb-3 d-flex">
      <div class="w-50 me-2">
        <label>Time </label>
        <mat-form-field appearance="outline" class="w-100 mat-hint-none">
          <input style="outline: none" matInput type="number" [(ngModel)]="payment_time"
            (wheel)="$event.preventDefault()" />
        </mat-form-field>
      </div>
      <div class="w-50">
        <label>Type </label>
        <mat-form-field appearance="outline" class="w-100 mat-hint-none">
          <mat-select placeholder="Select Type" class="pt-1" [(ngModel)]="payment_type">
            <mat-option value="m">Minutes</mat-option>
            <mat-option value="h">Hours</mat-option>
            <mat-option value="d">Days</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div>
      <button type="button" class="btn btn-secondry" (click)="modalService.dismissAll()">Cancel</button>
      <button class="btn btn-primary ms-2" (click)="onPaymentUrlCreation()">Save</button>
    </div>
  </div>

</ng-template>

<!-- <div class="container-fluid h-100 w-100">
    <div class="row">
        <div class="col-md-8">
            <div class="table-responsive table-height order-list">
                <table class="table table-borderless align-middle table-nowrap table-hover mb-0 w-100">
                    <thead>
                        <tr class="border-top-table">
                            <th scope="col" *ngFor="let col of table_cols" class="text-primary font-size-14 pb-3">
                                {{col}}</th>
                        </tr>
                    </thead>
                    <tbody class="font-size-14 text-center">
                        <tr class="cursor" *ngFor="let item of orderDetails.items; let i = index">
                            <td>
                                {{ i + 1 }}
                            </td>
                            <td>
                                <img [src]="item?.item_data?.item_image"
                                    onerror="this.src='../../../../assets/images/default_image.png'" height="50px"
                                    width="50px" class="border-radius-50">
                            </td>
                            <td>
                                {{ item.item_data.item_name }}
                            </td>
                            <td>
                                {{item.item_data.quantity}}
                            </td>
                            <td>
                                <ng-container
                                    *ngIf="item?.item_data?.discount_type === 'PERCENTAGE'">{{item.item_data.discount_value
                                    ? item.item_data.discount_value : '0'}}%</ng-container>
                                <ng-container *ngIf="item.item_data?.discount_type === 'AMOUNT'">
                                    {{item.item_data.discount_value ? (item.item_data.discount_value |
                                    currencyConversion) : '0'}}
                                </ng-container>
                            </td>
                            <td>{{ item.item_data.item_total_price }}</td>
                            <td>{{ item.item_data.item_total_discounted_price }}</td>
                            <td style="word-wrap: break-word;min-width: 160px;max-width: 160px; white-space: normal">
                                {{ item.item_data.product_desp ? item.item_data.product_desp : 'NA' }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="border-right-1 mt-3 px-3">
                <div class="bg-orange orange-border p-2 text-center border-radius-8 font-size-14"> You got this source
                    from
                    <b>IFRAME</b>
                </div>
                <div class="mt-3 d-flex justify-content-between align-items-center">
                    <div>
                        <div class="d-flex align-items-center mb-2">
                            <mat-icon style="color: #00000059;">account_circle</mat-icon>
                            <p class="mb-0 ms-2" [ngClass]="{'cursor':validateUserAccess}"
                            (click)="validateUserAccess && onNaviagtetoContactInnerScreen(orderDetails?.user_info?.id)">
                                {{orderDetails?.user_info?.fname ? orderDetails?.user_info?.fname : 'NA'}}
                                {{orderDetails?.user_info?.lname ? orderDetails?.user_info?.lname : ''}}
                            </p>
                        </div>
                        <div class="d-flex align-items-center mb-2">
                            <mat-icon style="color: #00000059;">mail_outline</mat-icon>
                            <p class="mb-0 ms-2"> {{orderDetails?.user_info?.email ? orderDetails?.user_info?.email :
                                'NA'}}</p>
                        </div>
                        <div class="d-flex align-items-center mb-2">
                            <mat-icon style="color: #00000059;">call</mat-icon>
                            <p class="mb-0 ms-2" *ngIf="orderDetails?.user_info?.phone_number">
                                {{orderDetails?.user_info?.ccode ?
                                orderDetails?.user_info?.ccode : ''}}</p>
                            <p class="mb-0 ms-1"> {{orderDetails?.user_info?.phone_number ?
                                orderDetails?.user_info?.phone_number : 'NA'}}</p>
                        </div>
                    </div>
                </div>

                <mat-divider class="mx-2"></mat-divider>
                <div class="my-3 border-0 d-flex align-items-center justify-content-between">
                    <div>
                        <div>
                            <label class="mb-1 fw-600  font-size-14">Order On</label>
                            <p>{{orderDetails.created_at | dateConversion}}</p>
                        </div>
                        <div>
                            <label class="mb-1 fw-600 font-size-14">Payment Type</label>
                            <p>{{orderDetails.payment_type}}</p>
                        </div>
                    </div>
                    <div class="mb-5 pb-2">
                        <p class="mb-1">Status</p>
                        <mat-form-field appearance="outline" class="status-select mat-hint-none"
                            *ngIf="orderDetails.order_status === 'PENDING'">
                            <mat-select placeholder="Select" (selectionChange)="onStatusChange()"
                                [(ngModel)]="orderDetails.order_status" [disabled]="!orderDetails?.is_contact_active">
                                <mat-option value="ACCEPTED">Accepted</mat-option>
                                <mat-option value="REJECTED">Rejected</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="status-select mat-hint-none"
                            *ngIf="orderDetails.order_status === 'ACCEPTED'">
                            <mat-select placeholder="Select" (selectionChange)="onStatusChange()"
                                [(ngModel)]="orderDetails.order_status" [disabled]="!orderDetails?.is_contact_active">
                                <mat-option value="ACCEPTED">Accepted</mat-option>
                                <mat-option value="REFUNDED">Refunded</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="orderDetails.order_status!=='ACCEPTED' && orderDetails.order_status!=='PENDING'"
                            class="badge-soft-info border-radius-4 fw-600 text-center font-size-12 p-2 text-capitalize text-ellipsis"
                            style="max-width: 150px;"
                            [ngClass]="{'bg-high':orderDetails.order_status === 'REJECTED','bg-medium':orderDetails.order_status === 'REFUNDED'}">
                            {{orderDetails.order_status | titlecase}}
                        </div>
                    </div>
                </div>
                <p class=""><a [href]="orderDetails?.media_url" target="_blank" download>Click here</a>
                    to download the invoice</p>
               
            </div>
        </div>
        <div class="col-md-4">
            <div class="px-1 py-1">
                <p class="fw-600 font-size-15">Payment Details</p>
                <div class="row">
                    <div class="d-flex align-items-center ">
                        <p class="col-4">Payment Mode:</p>
                        <p class="col-8">{{orderDetails.payment_type}}</p>
                    </div>
                    <div class="d-flex align-items-start">
                        <p class="col-4">Billing Address:</p>
                        <p class="col-8">
                            <span *ngIf="orderDetails.billing_address?.address_one || orderDetails?.billing_address?.billing_address">{{orderDetails?.billing_address?.address_one || orderDetails.billing_address.billing_address}}</span>
                            <span *ngIf="orderDetails.billing_address?.address_two">{{','+orderDetails.billing_address.address_two}},</span>
                            <span *ngIf="orderDetails.billing_address?.city">{{orderDetails.billing_address.city}},</span>
                            <span *ngIf="orderDetails.billing_address?.state">{{orderDetails.billing_address.state}},</span>
                            <span *ngIf="orderDetails.billing_address?.zipcode">{{orderDetails.billing_address.zipcode}},</span>
                            <span *ngIf="orderDetails.billing_address?.country">{{orderDetails.billing_address.country}},</span>
                            <span
                                *ngIf="orderDetails.billing_address?.contact_person_name">{{orderDetails.billing_address.contact_person_name}},</span>
                            <span
                                *ngIf="orderDetails.billing_address?.contact_person_phone">{{orderDetails.billing_address.contact_person_phone}}</span>
                        </p>
                    </div>
                    <div class="d-flex align-items-start">
                        <p class="col-4">Shipping Address:</p>
                        <p class="col-8">
                            <span
                                *ngIf="orderDetails.shipping_address?.address_one || orderDetails?.shipping_address?.shipping_address">{{orderDetails?.shipping_address?.address_one
                                 || orderDetails?.shipping_address?.shipping_address}}</span>
                                 
                            <span *ngIf="orderDetails.shipping_address?.address_two">{{','+orderDetails.shipping_address.address_two}},</span>
                            <span *ngIf="orderDetails.shipping_address?.city">{{orderDetails.shipping_address.city}},</span>
                            <span *ngIf="orderDetails.shipping_address?.state">{{orderDetails.shipping_address.state}},</span>
                            <span *ngIf="orderDetails.shipping_address?.zipcode">{{orderDetails.shipping_address.zipcode}},</span>
                            <span *ngIf="orderDetails.shipping_address?.country">{{orderDetails.shipping_address.country}},</span>
                            <span
                                *ngIf="orderDetails.shipping_address?.contact_person_name">{{orderDetails.shipping_address.contact_person_name}},</span>
                            <span
                                *ngIf="orderDetails.shipping_address?.contact_person_phone">{{orderDetails.shipping_address.contact_person_phone}}</span>
                        </p>
                    </div>
                    
                </div>
                <div class="mt-4">
                    <p class="fw-600">Order Summary</p>
                    <div class="row">
                        <div class="col-md-6">
                            <p>Order ID</p>
                        </div>
                        <div class="col-md-6 d-flex align-items-center justify-content-end flex-wrap">
                            <p>{{orderDetails.order_id ? orderDetails.order_id : 'NA'}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <p>Payment ID</p>
                        </div>
                        <div class="col-md-6 d-flex align-items-center justify-content-end flex-wrap">
                            <p>{{orderDetails.reference_id ? orderDetails.reference_id : 'NA'}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <p>Number of products</p>
                        </div>
                        <div class="col-md-6 d-flex align-items-center justify-content-end">
                            <p>{{totalQuantityInNum ? totalQuantityInNum : 'NA'}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <p>Total Price</p>
                        </div>
                        <div class="col-md-6 d-flex align-items-center justify-content-end">
                            <p>{{orderDetails.total_price ? (orderDetails.total_price | currencyConversion) : 'NA'}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <p>Discount</p>
                        </div>
                        <div class="col-md-6 d-flex align-items-center justify-content-end">
                            <p><span *ngIf="orderDetails?.discount_price">- </span>{{orderDetails.discount_price ?
                                (orderDetails.discount_price | currencyConversion) :
                                'NA'}}</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <p>Tax</p>
                        </div>
                        <div class="col-md-6 d-flex align-items-center justify-content-end">
                            <div class="mb-3">
                                <ng-container *ngIf="orders_tax_list.length > 0">
                                    <p class="mb-0" *ngFor="let tax_item of orders_tax_list">
                                        {{tax_item.name}} ({{tax_item.percentage}}%) &nbsp; +{{tax_item.tax_value |
                                        currencyConversion}}
                                    </p>
                                </ng-container>
                                <p class="mb-0" *ngIf="orders_tax_list.length === 0">NA</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <p>Final Price</p>
                        </div>
                        <div class="col-md-6 d-flex align-items-center justify-content-end">
                            <p class="fw-600">{{orderDetails.final_price | currencyConversion}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->