<div class="modal-header">
    <h5 class="modal-title fw-600 text-capitalize">Search</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalService.dismissAll()"></button>
</div>

<div class="modal-body">
    <div class="">
        <label class="fw-600 mb-2">Search</label>
        <mat-form-field appearance="outline" class="w-100">
            <input matInput type="text" placeholder="Search here" [(ngModel)]="search_global"
                (ngModelChange)="searchSubject.next(search_global)">
        </mat-form-field>
    </div>
    <ng-container *ngIf="globalSearchData">
        <ng-container *ngFor="let item of globalSearchData | keyvalue">
            <mat-accordion class="contact-accord" *ngIf="item.value?.length"
                style="overflow: scroll;"><!-- && item.value?.length > 0-->
                <mat-expansion-panel [expanded]="true" class="mt-3">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="w-100 d-flex align-items-center justify-content-between">
                                <p class="fw-600 mb-1 text-capitalize">{{item.key}}</p>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <table class="table align-middle table-nowrap table-hover mb-0 w-100" style="overflow: scroll;">
                        <thead>
                            <ng-container *ngIf="item.value.length > 0">
                                <tr class="">
                                    <ng-container *ngFor="let col of item.value[0] | keyvalue">
                                        <th *ngIf="col?.key !== 'routing_key' && col?.key !== 'ccode' && col?.key !== 'id'"
                                            scope="col" class="text-capitalize">
                                            {{col.key}}</th>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let value of item.value">
                                <ng-container>
                                    <tr class="cursor" (click)="navigation(value.routing_key)">
                                        <!-- [routerLink]="[col.value.routing_key]"-->
                                        <ng-container *ngFor="let col of value | keyvalue">
                                            <td style="padding:10px 16px !important; border-left: 0px;"
                                                *ngIf="col?.key !== 'routing_key' && col?.key !== 'ccode' && col?.key !== 'id'">
                                                <ng-container>
                                                    <ng-container *ngIf="col?.key === 'phone_number'">
                                                        {{value.ccode}} {{col.value}}
                                                    </ng-container>
                                                    <ng-container *ngIf="col?.key !== 'phone_number'">
                                                        {{col.value}}
                                                    </ng-container>
                                                </ng-container>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="(!globalSearchData || isGlobalSearchDataEmpty()) && (search_global.length>=3)">
        <app-nodata-found></app-nodata-found>
    </ng-container>
</div>