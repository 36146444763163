import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { InboxService } from 'src/app/core/service/inbox.service';
import { PaymentsService } from 'src/app/core/service/payments.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
  table_cols = ['S.No', 'Image', 'Product Name', 'Quantity', 'Discount', 'Price', 'Final Price', 'Description'];
  orderDetails: any = {};
  @Input() order_id!: string;
  preSendUrl = environment.preSendUrl;
  billing_address: any;
  shipping_address: any;
  totalQuantityInNum = 0;
  orders_tax_list: any = [];
  loggedInUser: any;
  validateUserAccess = false;
  tableSkeletonLoader = true;
  payment_time = '';
  payment_type = 'm';
  @ViewChild('tp') _matTooltip!: MatTooltip;
  payment_connected = false;
  qr_code_details: any;

  constructor(private route: ActivatedRoute, private router: Router, private order_service: InboxService,private setup_service: SetUpService,
    public modalService: NgbModal, private _paymentService: PaymentsService,private confirmationMsz: ConfirmationMszService) { }

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.route.queryParams.subscribe((params: any) => {
      console.log("route params - ", params);
      // this.order_id = '9b175b0d-216b-40a1-970d-2ea5eb2cbc94';
      if (this.order_id) {
        this.getSelectedOrderDetails();
        this.checkPaymentMode();
      }
    });
  }

  onStatusChange() {
    this.order_service.updateOrderStatus(this.orderDetails).subscribe({
      next: (resp: any) => {
        this.getSelectedOrderDetails();
      }
    });
  }

  onNaviagtetoContactInnerScreen(userId: string) {
    const queryParams: any = {
      id: userId,
      showtype: 'contact'
    };
    this.router.navigate(['/crm/crm-details'], { queryParams: queryParams });
  }

  getNavigationAccess() {
    if (this.loggedInUser.data.role === 'ADMIN') {
      this.validateUserAccess = true;
    } else {
      this.validateUserAccess = false;
    }
  }

  getSelectedOrderDetails() {
    this.order_service.getOrderById(this.order_id).subscribe({
      next: (resp: any) => {
        this.tableSkeletonLoader = false;
        this.orderDetails = resp.data;
        let total_quantity = 0;
        this.getNavigationAccess();
        if (this.orderDetails?.items.length > 0) {
          this.orderDetails.items.forEach((ele: any) => {
            total_quantity += ele.quantity;
          });
        }
        if (Object.keys(this.orderDetails?.taxes_dict).length > 0) {
          this.orders_tax_list = Object.values(this.orderDetails?.taxes_dict);
        }
        this.totalQuantityInNum = total_quantity;
        if(resp.data.source_qr) this.setup_service.getAllQrCodesById(resp.data.source_qr).subscribe((qr_resp: any)=>{
          this.qr_code_details = qr_resp.data;
        });
      }
    });
  }

  openGenerateUrlModal(modal: any) {
    this.modalService.open(modal);
  }

  onPaymentUrlCreation() {
    const body = {
      expiry_time: this.convertTime(this.payment_time, this.payment_type),
      order_id: this.order_id
    };
    this.order_service.generatePaymentUrl(body).subscribe(() => {
      this.getSelectedOrderDetails();
      this.modalService.dismissAll();
    });
  }

  convertTime(payment_time: any, payment_type: any) {
    let totalMinutes = 0;
    switch (payment_type) {
      case 'm':
        totalMinutes = payment_time; // Minutes remain unchanged
        break;
      case 'h':
        totalMinutes = payment_time * 60; // 1 hour = 60 minutes
        break;
      case 'd':
        totalMinutes = payment_time * 1440;
        break;
      default:
        throw new Error('Invalid unit provided.');
    }

    return totalMinutes;
  }

  checkPaymentMode() {
    this._paymentService.getPaymentKeys('razorpay').subscribe((resp: any) => {
      this.payment_connected = resp?.data?.gateway_id ? true : false;
    });
  }

  onClipboardCopy(e: any) {
    setTimeout(() => {
      this._matTooltip.show();
      this._matTooltip.message = e ? 'Copied!' : 'Copy to clipboard';
    });
  }

  cancelPaymentUrl() {
    this.confirmationMsz.delete()
      .then((data) => {
        if (data.value)
          this.order_service.cancelPaymentUrl(this.order_id).subscribe(() => {
            this.getSelectedOrderDetails();
          });
      });
  }
}
