import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { CustomFieldsService } from 'src/app/core/service/custom-fields.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { ActivatedRoute } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { AuthService } from 'src/app/core/service/auth.service';
import { onCheckRequiredFieldStatus } from 'src/app/core/common/common-functions';

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss']
})
export class CustomFieldsComponent implements OnInit {
  background: ThemePalette = undefined;
  custom_form!: FormGroup;
  system_form!: FormGroup;
  modelRef!: NgbModalRef;

  links = [{
    label: 'Contact',
    launchDlKeys: 'contact'
  },
  {
    label: 'Company',
    launchDlKeys: 'company'
  },
  {
    label: 'Leads',
    launchDlKeys: 'enquiry'
  },
  {
    label: 'Clients',
    launchDlKeys: 'client'
  },
  {
    label: 'Client Projects',
    launchDlKeys: 'projects'
  },
  {
    label: 'Pipeline',
    launchDlKeys: 'pipeline'
  }];
  system_fields_count = 0;
  activeLink = this.links[0].label;
  currentUser: string = sessionStorage.getItem('currentUser') || "{}";
  ORGANISATION: string = JSON.parse(this.currentUser).data.organisation;
  loggedInUser: any = JSON.parse(this.currentUser);
  cotactModel: any;
  companyModel: any;
  enquiryModel: any;
  clientModel: any;
  clientProjectModel: any;
  systemFields: any;
  customFieldsList: any = [];
  pipeline_list: any;
  selected_pipeline: any;
  pipelineModel: any = {};
  openmodelType: string | undefined;
  activeId = '';
  featuresData: any;
  submited = false;
  errorjson = ERRORJSON[0];
  edditedSysField: any;
  tableSkeletonLoader = true;
  revokedSysFieldsList: { [key: string]: { is_visible: any } } = {};
  showErrorMessage = false;
  @ViewChild('customFieldModal') customFieldModal!: TemplateRef<any>;
  @Output() closeModalEvent = new EventEmitter<string>();
  modalDismissed: any;
  constructor(
    public modalService: NgbModal,
    private _customFields: CustomFieldsService,
    private crmService: CrmService,
    private activatedRoute: ActivatedRoute,
    private confirmationMsz: ConfirmationMszService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((data: any) => {
      this.getFeatues();
      if (data.activeLink) {
        this.activeLink = data.activeLink;
        this.onTabChange(this.activeLink);
        this.selected_pipeline = data?.pipelineId;
      } else {
        this.getContactModelFields();
      }
    });
  }

  initializeCustomField(data?: any) {
    this.custom_form = new FormGroup({
      id: new FormControl(data?.id ? data.id : ""),
      label: new FormControl(data?.label ? data.label : "", [Validators.required]),//Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)
      original_label: new FormControl(data?.original_label ? data.original_label : "", [Validators.required]),//Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)
      data_type: new FormControl(data?.data_type ? data.data_type : "", Validators.required),
      is_mandatory: new FormControl(data?.is_mandatory ? data.is_mandatory : false, Validators.required),
      validation: new FormControl(data?.validation ? data.validation : "NONE", Validators.required),
      options: new FormControl(data?.options ? data.options : []),
      on_form: new FormControl(data?.on_form === false ? data.on_form : true, Validators.required),
      is_inner_screen: new FormControl(data?.is_inner_screen ? data.is_inner_screen : true, Validators.required),
      on_dashboard: new FormControl(data?.on_dashboard ? data.on_dashboard : false, Validators.required),
      organisation: new FormControl(this.ORGANISATION, Validators.required),
      contact_model: new FormControl(this.cotactModel?.id),
      company_model: new FormControl(this.companyModel?.id),
      lead_model: new FormControl(this.enquiryModel?.id),
      client_model: new FormControl(this.clientModel?.id),
      project_model: new FormControl(this.clientProjectModel?.id),
      is_client_visible: new FormControl(data?.is_client_visible ? data?.is_client_visible : false),
      status: new FormControl(data?.status ? data?.status : false)
    });
    if (data?.is_mandatory) {
      this.custom_form.patchValue({
        on_form: true
      });
      this.custom_form.controls["on_form"].disable();
    }
    if (this.custom_form.value.on_form) this.custom_form.controls["is_inner_screen"].disable();
    if (data?.id) this.custom_form.controls['data_type'].disable();
  }

  get f() {
    return this.custom_form.controls;
  }

  initializeSystemField(data?: any) {
    this.system_form = new FormGroup({
      label: new FormControl(data ? data[0] : "", Validators.required),
      is_mandatory: new FormControl(data ? data[1].is_mandatory : false, Validators.required),
      on_model: new FormControl(data ? data[1].on_model : false, Validators.required),
      on_form: new FormControl(data ? data[1].on_form : true, Validators.required),
      is_inner_screen: new FormControl(data ? data[1].is_inner_screen : true, Validators.required),
      on_dashboard: new FormControl(data ? data[1].on_dashboard : false, Validators.required),
      is_client_visible: new FormControl(data ? data[1]?.is_client_visible : false)
    });
    if (!(this.system_form.value.on_form || this.system_form.value.is_inner_screen)) this.system_form.controls["is_client_visible"].disable();
    if (this.system_form.value.on_form) this.system_form.controls["is_inner_screen"].disable();
  }


  onTabChange(item: string = this.activeLink) {
    this.activeLink = item;
    this.customFieldsList = [];
    this.systemFields = [];
    this.revokedSysFieldsList = {};
    this.showErrorMessage = false;
    if (this.activeLink === 'Contact') {
      this.getContactModelFields();
    } else if (this.activeLink === 'Company') {
      this.getCompanyModelFields();
    } else if (this.activeLink === 'Leads') {
      this.getEnquiryModelFields();
    } else if (this.activeLink === 'Clients') {
      this.getClientModelFields();
    } else if (this.activeLink === 'Client Projects') {
      this.getClientProjectModelFields();
    } else if (this.activeLink === 'Pipeline') {
      this.getPipelines();
    }
  }

  addnew = (option: any) => ({ mail: option });


  // onLabelChange(event: any) {
  //   if (this.custom_form.value?.id) return;
  //   this.custom_form.patchValue({
  //     original_label: this.convertToLowerCaseWithUnderscore(event.target.value)
  //   })
  // }

  // onCheckBoxChange(event: any, type: string = 'Required') {
  //   if (type === 'Required') {
  //     if (event.target.checked) {
  //       this.custom_form.patchValue({
  //         on_form: true
  //       });
  //       this.custom_form.controls["on_form"].disable();
  //     } else {
  //       this.custom_form.controls["on_form"].enable();
  //     }
  //   }
  // }

  onCustomFieldSubmit() {
    if (this.custom_form.value.data_type === 'CHECK_BOX' || this.custom_form.value.data_type === 'SELECT_DROPDOWN' || this.custom_form.value.data_type === 'RADIO_BUTTON') {
      this.custom_form.get('options')?.addValidators([Validators.required]);
      this.custom_form.get('options')?.updateValueAndValidity();
      if (this.custom_form.value.options.length < 2) {
        return;
      }
    } else {
      this.custom_form.get('options')?.updateValueAndValidity();
      this.custom_form.get('options')?.clearValidators();
    }
    this.submited = true;
    if (!this.custom_form.valid) {
      this.custom_form.markAllAsTouched();
      return;
    }

    const temp_option: any = [];
    this.custom_form.value.options.map((item: any) => {
      temp_option.push(item?.label ? item.label : item);
    });
    this.custom_form.controls["on_form"].enable();
    this.custom_form.controls["is_inner_screen"].enable();
    this.custom_form.controls["data_type"].enable();
    this.custom_form.patchValue({
      options: temp_option,
      on_form: this.custom_form.value.is_mandatory ? true : this.custom_form.value.on_form,
      label: this.custom_form.value.label.trim()
    });

    if (this.activeLink === 'Contact') {
      this.contactFields();
    } else if (this.activeLink === 'Company') {
      this.companyFields();
    } else if (this.activeLink === 'Leads') {
      this.enquiryFields();
    } else if (this.activeLink === 'Clients') {
      this.clientFields();
    } else if (this.activeLink === 'Client Projects') {
      this.clientProjectFields();
    } else if (this.activeLink === 'Pipeline') {
      this.pipelineFields();
    }
  }

  onSystemFieldSubmit() {
    this.system_form.controls["on_form"].enable();
    this.system_form.patchValue({
      on_form: this.system_form.value.is_mandatory ? true : this.system_form.value.on_form
    });
    const on_form = this.system_form.value.label + '_' + 'on_form';
    const is_inner_screen = this.system_form.value.label + '_' + 'is_inner_screen';
    const is_mandatory = this.system_form.value.label + '_' + 'is_mandatory';
    const on_dashboard = this.system_form.value.label + '_' + 'on_dashboard';
    const on_model = this.system_form.value.label + '_' + 'on_model';
    const is_client_visible = this.system_form.value.label + '_' + 'is_client_visible';
    const body = {
      [on_form]: this.system_form.value.is_mandatory ? true : this.system_form.value.on_form,
      [is_mandatory]: this.system_form.value.is_mandatory,
      [is_inner_screen]: this.system_form.value.on_form ? true : this.system_form.value.is_inner_screen,
      [on_dashboard]: this.system_form.value.on_dashboard,
      [on_model]: this.system_form.value.on_model,
      id: this.activeId,
      field_name: this.system_form.value.label,
      field_data_type: this.edditedSysField ? this.edditedSysField[1]?.data_type : '',
      [is_client_visible]: this.system_form.value.is_client_visible || false
    };
    if (this.activeLink === 'Contact') {
      this._customFields.putContactModelFields(body).subscribe(() => {
        this.onSuccess();
      });
    } else if (this.activeLink === 'Company') {
      this._customFields.putCompanyModelFields(body).subscribe(() => {
        this.onSuccess();
      });
    } else if (this.activeLink === 'Leads') {
      this._customFields.putEnquiryModelFields(body).subscribe(() => {
        this.onSuccess();
      });
    } else if (this.activeLink === 'Pipeline') {
      this.crmService.PutTemplateCard(body).subscribe(() => {
        this.onSuccess();
      });
    } else if (this.activeLink === 'Clients') {
      this._customFields.putClientModelFields(body).subscribe(() => {
        this.onSuccess();
      });
    } else if (this.activeLink === 'Client Projects') {
      this._customFields.putClientProjectModelFields(body).subscribe(() => {
        this.onSuccess();
      });
    }
  }

  onLabelChange(event: any) {
    if (this.custom_form.value?.id) return;
    this.custom_form.patchValue({
      original_label: this.convertToLowerCaseWithUnderscore(event.target.value)
    });
  }

  onCheckBoxChange(event: any, type = 'Required') {
    if (type === 'Required') {
      if (this.openmodelType === 'systemfield') {
        if (event.target.checked) {
          this.system_form.patchValue({
            on_form: true
          });
          this.system_form.controls["on_form"].disable();
        } else {
          this.system_form.controls["on_form"].enable();
        }
      }
      else {
        if (event.target.checked) {
          this.custom_form.patchValue({
            on_form: true
          });
          this.custom_form.controls["on_form"].disable();
        } else {
          this.custom_form.controls["on_form"].enable();
        }
      }
    }
  }

  onCheckBoxFormChange(ev: any, type?: string) {
    if (type === "systemfield") {
      if (ev.target.checked) {
        this.system_form.patchValue({
          is_inner_screen: true
        });
        this.system_form.controls["is_inner_screen"].disable();
        this.system_form.controls["is_client_visible"].enable();
      } else {
        this.system_form.controls["is_inner_screen"].enable();
        this.system_form.controls["is_client_visible"].enable();
      }
    } else {
      if (ev.target.checked) {
        this.custom_form.patchValue({
          is_inner_screen: true
        });
        this.custom_form.controls["is_inner_screen"].disable();
        this.custom_form.controls["is_client_visible"].enable();
      } else {
        this.custom_form.controls["is_inner_screen"].enable();
        this.custom_form.controls["is_client_visible"].enable();
      }
    }
  }
  onCheckBoxInnerFormChange(ev: any, type?: string) {
    if (type === "systemfield") {
      if (ev.target.checked) {
        this.system_form.controls["is_client_visible"].enable();
      } else {
        this.system_form.controls["is_client_visible"].disable();
      }
    } else {
      if (ev.target.checked) {
        this.custom_form.controls["is_inner_screen"].enable();
      } else {
        this.custom_form.controls["is_inner_screen"].disable();
      }
    }
  }

  contactFields() {
    if (this.custom_form.value.id) {
      this._customFields.putContactTemplateFields(this.custom_form.value).subscribe((resp) => {
        this.onSuccess();
      });
    } else {
      this._customFields.postContactTemplateFields(
        this.custom_form.value
      ).subscribe((resp) => {
        this.onSuccess();
      });
    }
  }

  companyFields() {
    if (this.custom_form.value.id) {
      this._customFields.putCompanyTemplateFields(this.custom_form.value).subscribe((resp) => {
        this.onSuccess();
      });
    } else {
      this._customFields.postCompanyTemplateFields(
        this.custom_form.value
      ).subscribe((resp) => {
        this.onSuccess();
      });
    }
  }

  enquiryFields() {
    if (this.custom_form.value.id) {
      this._customFields.putEnquiryTemplateFields(this.custom_form.value).subscribe((resp) => {
        this.onSuccess();
      });
    } else {
      this._customFields.postEnquiryTemplateFields(
        this.custom_form.value
      ).subscribe((resp) => {
        this.onSuccess();
      });
    }
  }

  clientFields() {
    if (this.custom_form.value.id) {
      this._customFields.putClientTemplateFields(this.custom_form.value).subscribe((resp) => {
        this.onSuccess();
      });
    } else {
      this._customFields.postClientTemplateFields(
        this.custom_form.value
      ).subscribe((resp) => {
        this.onSuccess();
      });
    }
  }

  clientProjectFields() {
    if (this.custom_form.value.id) {
      this._customFields.putClientProjectTemplateFields(this.custom_form.value).subscribe((resp) => {
        this.onSuccess();
      });
    } else {
      this._customFields.postClientProjectTemplateFields(this.custom_form.value).subscribe((resp) => {
        this.onSuccess();
      });
    }
  }
  pipelineFields() {
    if (this.custom_form.value.id) {
      this.crmService.customFieldUpdate({ template_card: this.pipelineModel.id, ...this.custom_form.value }).subscribe(() => {
        this.onSuccess();
      });
    } else {
      this.crmService.customFieldCreation({ template_card: this.pipelineModel.id, ...this.custom_form.value }).subscribe(() => {
        this.onSuccess();
      });
    }
  }

  deleteCustomField(id: string) {
    this.confirmationMsz.customDeleteView({ title: 'Are you sure?', text: 'You want to delete!', button: 'Yes Delete!' })
      .then((data) => {
        if (data.value) {
          if (this.activeLink === 'Contact') {
            this._customFields.deleteContactTemplateFields(id).subscribe((resp: any) => {
              this.onTabChange();
            });
          } else if (this.activeLink === 'Company') {
            this._customFields.deleteCompanyTemplateFields(id).subscribe((resp: any) => {
              this.onTabChange();
            });
          } else if (this.activeLink === 'Leads') {
            this._customFields.deleteEnquiryTemplateFields(id).subscribe((resp: any) => {
              this.onTabChange();
            });
          } else if (this.activeLink === 'Pipeline') {
            this.crmService.deleteCustomField(id).subscribe((resp: any) => {
              this.onTabChange();
            });
          } else if (this.activeLink === 'Clients') {
            this._customFields.deleteClientTemplateFields(id).subscribe((resp: any) => {
              this.onTabChange();
            });
          } else if (this.activeLink === 'Client Projects') {
            this._customFields.deleteClientProjectTemplateFields(id).subscribe((resp: any) => {
              this.onTabChange();
            });
          }
        }
      });
  }

  onSuccess() {
    this.closeModalEvent.emit('close');
    this.modalService.dismissAll();
    this.onTabChange();
  }

  getContactModelFields() {
    this.tableSkeletonLoader = true;
    this._customFields.getContactModelFields().subscribe((resp: any) => {
      this.tableSkeletonLoader = false;
      this.cotactModel = resp.data;
      this.activeId = resp.data.id;
      this.systemFields = Object.entries(this.cotactModel);
      this.customFieldsList = this.cotactModel?.custom_contact_templates.filter((item: any) => { return item.is_active; });
      this.initializeCustomField();
    });
  }

  getCompanyModelFields() {
    this.tableSkeletonLoader = true;
    this._customFields.getCompanyModelFields().subscribe((resp: any) => {
      this.tableSkeletonLoader = false;
      this.companyModel = resp.data;
      this.activeId = resp.data.id;
      this.systemFields = Object.entries(this.companyModel);
      this.customFieldsList = this.companyModel?.custom_company_templates?.filter((item: any) => { return item.is_active; });
      this.initializeCustomField();
    });
  }

  getEnquiryModelFields() {
    this.tableSkeletonLoader = true;
    this._customFields.getEnquiryModelFields().subscribe((resp: any) => {
      this.enquiryModel = resp.data;
      this.tableSkeletonLoader = false;
      this.activeId = resp.data.id;
      this.systemFields = Object.entries(this.enquiryModel);
      this.onCheckRequiredFieldStatus(this.enquiryModel);
      this.customFieldsList = this.enquiryModel?.custom_lead_templates?.filter((item: any) => { return item.is_active; });
      this._customFields.getEnquiryTemplateFields().subscribe((response: any) => {
        this.customFieldsList = response.data;
      });
      this.initializeCustomField();
    });

  }


  // Clients
  getClientModelFields() {
    this.tableSkeletonLoader = true;
    this._customFields.getClientModelFields().subscribe((resp: any) => {
      this.tableSkeletonLoader = false;
      this.clientModel = resp.data;
      this.activeId = resp.data.id;
      this.systemFields = Object.entries(this.clientModel);
      this.customFieldsList = this.clientModel?.custom_client_templates?.filter((item: any) => { return item.is_active; });
      this.initializeCustomField();
    });
  }

  // Client Projects
  getClientProjectModelFields() {
    this.tableSkeletonLoader = true;
    this._customFields.getClientProjectModelFields().subscribe((resp: any) => {
      this.tableSkeletonLoader = false;
      this.clientProjectModel = resp.data;
      this.activeId = resp.data.id;
      this.onCheckRequiredFieldStatus(this.clientProjectModel);
      this.systemFields = Object.entries(this.clientProjectModel);
      this.customFieldsList = this.clientProjectModel?.custom_project_templates?.filter((item: any) => { return item.is_active; });
      this.initializeCustomField();
    });
  }

  onCheckRequiredFieldStatus(allFieldData: any) {
    const returnedObj = onCheckRequiredFieldStatus(allFieldData, this.featuresData, this.showErrorMessage);
    this.revokedSysFieldsList = returnedObj.revokedSysFieldsList;
    this.showErrorMessage = returnedObj.showErrorMessage;
    // const requiredFieldLists = Object.entries(allFieldData)?.filter((elem: any) => (!(Array.isArray(elem[1]))));
    // requiredFieldLists.forEach((elem: any) => {
    //   Object.keys(this.featuresData).find((ele: any) => {
    //     const elementName = elem[0]?.split("_")[0];
    //     if (ele.startsWith(elementName.slice(0, -1))) {
    //       if (!this.featuresData[ele]) {
    //         if(elem[1].is_mandatory){
    //           this.showErrorMessage = true;
    //         }
    //         Object.assign(this.revokedSysFieldsList, {
    //           [elem[0]]: {
    //             is_visible: elem[1].is_mandatory
    //           }
    //         });
    //       } 
    //     }
    //   });
    // });
  }

  // getObjectStatus(obj:any){
  //   return Object.keys(obj).length > 0 && Object.entries(obj).find((elem:any) => elem[1].is_visible);
  // }

  getPipelines() {
    this.tableSkeletonLoader = true;
    this.crmService.getPipelines().subscribe((resp: any) => {
      this.tableSkeletonLoader = false;
      this.pipeline_list = resp.data;
      if (!this.selected_pipeline) this.selected_pipeline = resp.data[0].id;
      this.getPipelineModelFields();
    });
  }

  onPipelineChange(ev: any) {
    this.selected_pipeline = ev.value;
    this.getPipelineModelFields();
  }

  getPipelineModelFields() {
    this.crmService.getTemplateCard(this.selected_pipeline).subscribe((resp: any) => {
      this.pipelineModel = resp.data[0];
      this.activeId = this.pipelineModel.id;
      this.showErrorMessage = false;
      this.onCheckRequiredFieldStatus(this.pipelineModel);
      this.systemFields = Object.entries(this.pipelineModel);
      console.log(this.systemFields);
      this.customFieldsList = this.pipelineModel?.pipeline_template_field.filter((item: any) => { return item.is_active; });
    });
  }

  openModel(model: any, data: any = {}, type?: string) {
    this.modelRef = this.modalService.open(model, { size: 'md' });
    this.edditedSysField = data;
    this.openmodelType = type;
    if (data) {
      if (type === 'systemfield') {
        this.initializeSystemField(data);
      } else {
        this.initializeCustomField(data);
      }
    }
  }

  openCustomFieldModal(data?: any) {
    this.activeLink = data.type;
    this.custom_form.controls['status'].setValue(data.status || false);//Directly enabling the table view While creating the field
    if (this.activeLink === 'Pipeline') {
      this.selected_pipeline = data.pipeline_id; 
      this.getPipelineModelFields();
    }
    this.modalService.open(this.customFieldModal);
  }

  convertToLowerCaseWithUnderscore(str: string) {
    const noSpacesStr = str.replace(/\s+/g, '_');
    const lowerCaseStr = noSpacesStr.toLowerCase();
    const result = lowerCaseStr.replace(/\s+/g, '_');
    return result;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      const order: any[] = [];
      moveItemInArray(this.customFieldsList, event.previousIndex, event.currentIndex);
      this.customFieldsList.forEach((element: any) => {
        if (element.id) order.push(element.id);
      });
      if (this.activeLink === 'Contact') {
        this._customFields.putContactModelFields({ id: this.activeId, contact_fields_order: order }).subscribe(() => {
          this.onTabChange();
        });
      } else if (this.activeLink === 'Company') {
        this._customFields.putCompanyModelFields({ id: this.activeId, company_fields_order: order }).subscribe(() => {
          this.onTabChange();
        });
      } else if (this.activeLink === 'Leads') {
        this._customFields.putEnquiryModelFields({ id: this.activeId, lead_fields_order: order }).subscribe(() => {
          this.onTabChange();
        });
      } else if (this.activeLink === 'Pipeline') {
        this.crmService.PutTemplateCard({ id: this.activeId, card_fields_order: order }).subscribe(() => {
          this.onTabChange();
        });
      } else if (this.activeLink === 'Clients') {
        this._customFields.putClientModelFields({ id: this.activeId, client_fields_order: order }).subscribe(() => {
          this.onTabChange();
        });
      } else if (this.activeLink === 'Client Projects') {
        this._customFields.putClientProjectModelFields({ id: this.activeId, project_fields_order: order }).subscribe(() => {
          this.onTabChange();
        });
      }
    }
  }
  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
      this.links = this.links.filter((elem: any) => {
        if (elem.label === 'Client Projects') {
          return this.featuresData['client'] && this.featuresData[elem.launchDlKeys];
        } else {
          return this.featuresData[elem.launchDlKeys];
        }
      });
      this.activeLink = this.links[0].label;
    });
  }
}


// For reference
//{
//   ...this.custom_form.value,
//   original_label: this.convertToLowerCaseWithUnderscore(this.custom_form.value.label)
// }